<div class="wrapper" #wrapper>
  <app-general-header [isMobile]="isMobile$ | async"> </app-general-header>

  <!-- [Note] When both steps are completed, below success animation plays and direct a user to Dashboard -->
  <app-sms-success-animation
    *ngIf="isPassVerification"
    [isMobile]="isMobile$ | async"
  ></app-sms-success-animation>

  <section class="anonymous" aria-label="main" role="main" id="main-content">
    <article
      class="flex flex-col mt-6 w-full px-4 m-auto max-w-auth sm:max-w-sm-auth"
    >
      <div
        *ngIf="!isNewSignupQuestions"
        class="overflow-hidden text-xs flex rounded bg-input"
      >
        <div
          [ngStyle]="{ width: user.progressStatus + '%' }"
          class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-dark"
        ></div>
      </div>
      <div class="center-column">
        <h1
          class="mt-6 mb-8 text-4xl font-bold text-center text-light-black dark:text-white sm:text-3xl"
        >
          {{ 'getVerified.title' | translate }}
        </h1>
        <p
          *ngIf="!isSubmittedPhone"
          class="subtitle mt-6 mb-8 text-center text-light-black dark:text-white"
        >
          {{ 'getVerified.description' | translate }}
        </p>
        <p
          *ngIf="isSubmittedPhone"
          class="subheading mt-6 mb-8 text-center text-light-black dark:text-white"
        >
          {{ 'getVerifiedCode.description' | translate }}
        </p>

        <!-- START: Step 1 - Sned Phone  -->
        <section *ngIf="!isSubmittedPhone">
          <form [formGroup]="sendPhoneForm">
            <div class="mb-6 form-row">
              <label class="text-light-black dark:text-white text-sm">
                {{ 'getVerified.mobileNumber' | translate }} *
              </label>

              <div class="us-phone-number" #mobileNumber>
                <input
                  appPhoneMask
                  type="tel"
                  formControlName="phone"
                  autocomplete="off"
                  data-track="member-sign-up-phone-verification"
                  class="form-control input-edge block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                />
                <span
                  class="us-phone-country-prefix text-black dark:text-white"
                ></span>
              </div>
              <div class="flex justify-between">
                <small class="form-text text-muted danger">
                  <span
                    *ngIf="fieldErrors('phone')?.server && isPhoneTouched()"
                    class="server-error"
                  >
                    {{ fieldErrors('phone')?.server }}
                  </span>
                  <span
                    *ngIf="
                      fieldErrors('phone')?.invalidPhone && isPhoneTouched()
                    "
                    class="invalid-input"
                  >
                    *{{ 'auth.pleaseEnterAValidUSMobileNumber' | translate }}
                  </span>
                </small>
              </div>
            </div>
            <div class="mb-8 mt-10">
              <input
                type="checkbox"
                class="styled-checkbox"
                name=""
                id="check-2"
                [checked]="isChecked"
                (change)="checkValue($event)"
              />
              <label
                class="flex relative text-xs font-semibold text-left w-full custom-checkbox text-light-black dark:text-white"
                for="check-2"
              >
                <span class="pl-2">
                  {{ 'getVerified.smsAlert' | translate }}
                </span>
              </label>
            </div>
            <section class="text-center mb-8">
              <button
                type="submit"
                data-track="member-sign-up-get-code"
                [disabled]="!isValidPhone || isLoading"
                (click)="onSubmitPhone()"
                class="relative mb-4 sign-up-btn text-white rounded-full pl-4 pr-4 bg-blue-dark h-14 font-bold action-btn"
              >
                {{ 'auth.getCode' | translate }}

                <div class="absolute left-8 button-spinner top-7">
                  <suzy-spinner
                    *ngIf="isLoading"
                    size="small"
                    button="true"
                    [round]="isNewSignupQuestions ? '8' : '10'"
                    [color]="'#ffffff'"
                    [overlay]="false"
                  >
                  </suzy-spinner>
                </div>
              </button>
              <div class="text-center text-lg">
                <small
                  *ngIf="!isFirst"
                  class="text-blue-dark cursor-pointer text-sm font-semibold"
                  (click)="isSubmittedPhone = true"
                >
                  {{ 'auth.alreadyHaveCode' | translate }}
                </small>
              </div>
            </section>

            <section>
              <div class="flex mt-2">
                <span
                  class="crowdtap-shield-check text-xl text-active-link-dark dark:text-white"
                ></span>
                <span
                  class="pl-2 text-xs font-semibold text-left w-full dark:text-white"
                >
                  {{ 'getVerified.dataRateMessage' | translate }}
                  <a
                    [href]="privacyPolicyLink"
                    class="text-blue-dark"
                    target="_blank"
                  >
                    {{ 'getVerified.privacyPolicy' | translate }}
                  </a>
                </span>
              </div>
            </section>
          </form>
          <div class="hidden w-full text-center text-blue-dark subtitle">
            <a
              target="_blank"
              href="https://support.crowdtap.com/hc/en-us/articles/360025098871-Verification-and-privacy"
              >{{ 'getVerified.whyDoWeAsk' | translate }}</a
            >
          </div>
        </section>
        <!-- END: Step 1 - Send Phone -->

        <!-- START: Step 2 - Send Code  -->
        <section *ngIf="isSubmittedPhone">
          <form [formGroup]="sendCodeForm">
            <div class="mb-6 form-row">
              <label class="text-light-black dark:text-white">
                {{ 'getVerifiedCode.code' | translate }}
              </label>

              <div #code>
                <input
                  type="tel"
                  class="block w-full p-2 mt-2 border rounded-sm text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
                  formControlName="code"
                  autocomplete="off"
                  placeholder="{{ 'getVerifiedCode.code' | translate }}"
                  data-track="member-sign-up-enter-code"
                />
              </div>
              <small
                *ngIf="fieldErrors('code')"
                class="form-text text-muted danger"
              >
                <span
                  *ngIf="fieldErrors('code').server && isCodeTouched()"
                  class="server-error"
                >
                  {{ fieldErrors('code').server }}
                </span>
                <span
                  *ngIf="fieldErrors('code').resend && isCodeTouched()"
                  class="invalid-input"
                >
                  {{ fieldErrors('code').resend }}
                </span>
                <span
                  *ngIf="fieldErrors('code').required && isCodeTouched()"
                  class="invalid-input"
                >
                  *{{ 'auth.confirmationCodeIsRequired' | translate }}
                </span>
              </small>
            </div>

            <section class="text-center">
              <button
                type="submit"
                data-track="member-sign-up-verify"
                class="relative mb-6 sign-up-btn text-white rounded-full pl-4 pr-4 bg-blue-dark h-14 font-bold action-btn"
                [disabled]="!isValidCode || isLoading"
                (click)="onSubmitCode()"
              >
                {{ 'general.submit' | translate }} Code
                <div class="absolute left-8 button-spinner top-7">
                  <suzy-spinner
                    *ngIf="isLoading"
                    size="small"
                    button="true"
                    [round]="isNewSignupQuestions ? '8' : '10'"
                    [color]="'#ffffff'"
                    [overlay]="false"
                  >
                  </suzy-spinner>
                </div>
              </button>
            </section>
            <section class="flex pt-6 text-center justify-between">
              <p class="pr-1 dark:text-white">
                {{ 'auth.didntGetCode' | translate }}
              </p>
              <p
                (click)="onResendCode()"
                class="text-light-black dark:text-blue-dark support-link text-blue-dark font-bold cursor-pointer"
              >
                {{ 'auth.sendANewCode' | translate }}
              </p>
            </section>
          </form>
        </section>
      </div>
    </article>
  </section>
</div>
<section class="footer-contianer pb-2.5 sm:pb-0">
  <app-general-footer id="main-footer"></app-general-footer>
</section>
