<div class="modal-header pt-4 px-4 flex justify-end">
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span *ngIf="!darkMode">
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="close">
          <mask
            id="mask0_12184_2872"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="25"
          >
            <rect
              id="Bounding box"
              y="0.185547"
              width="24"
              height="24"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_12184_2872)">
            <path
              id="close_2"
              d="M12 13.5856L7.10005 18.4856C6.91672 18.6689 6.68338 18.7606 6.40005 18.7606C6.11672 18.7606 5.88338 18.6689 5.70005 18.4856C5.51672 18.3023 5.42505 18.0689 5.42505 17.7856C5.42505 17.5023 5.51672 17.2689 5.70005 17.0856L10.6 12.1856L5.70005 7.2856C5.51672 7.10226 5.42505 6.86893 5.42505 6.5856C5.42505 6.30226 5.51672 6.06893 5.70005 5.8856C5.88338 5.70226 6.11672 5.6106 6.40005 5.6106C6.68338 5.6106 6.91672 5.70226 7.10005 5.8856L12 10.7856L16.9 5.8856C17.0834 5.70226 17.3167 5.6106 17.6 5.6106C17.8834 5.6106 18.1167 5.70226 18.3 5.8856C18.4834 6.06893 18.575 6.30226 18.575 6.5856C18.575 6.86893 18.4834 7.10226 18.3 7.2856L13.4 12.1856L18.3 17.0856C18.4834 17.2689 18.575 17.5023 18.575 17.7856C18.575 18.0689 18.4834 18.3023 18.3 18.4856C18.1167 18.6689 17.8834 18.7606 17.6 18.7606C17.3167 18.7606 17.0834 18.6689 16.9 18.4856L12 13.5856Z"
              fill="#1A152E"
            />
          </g>
        </g>
      </svg>
    </span>
    <span *ngIf="darkMode">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="close">
          <mask
            id="mask0_12225_77"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_12225_77)">
            <path
              id="close_2"
              d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z"
              fill="white"
            />
          </g>
        </g>
      </svg>
    </span>
  </button>
</div>
<div class="modal-body text-center mt-1">
  <div class="flex flex-col items-center px-8">
    <img
      src="assets/img/svg/icons/show-prompt-1.svg"
      alt="show prompt image"
      class="block mb-4 lg:mb-2"
    />
    <h2 class="text-xl leading-9 lg:text-2xl font-bold dark:text-white mb-2">
      {{ 'showSmsPrompt.title' | translate }}
    </h2>
    <p class="text-sm leading-5 font-semibold dark:text-white lg:px-10">
      {{ 'showSmsPrompt.description' | translate }}
    </p>
  </div>
</div>
<div class="modal-footer flex justify-center pt-8 pb-6">
  <button
    type="button"
    class="d-block bg-blue-500 text-white mx-auto text-sm lg:text-base leading-5 lg:leading-6 capitalize font-bold px-4 py-2 lg:px-6 lg:py-3 rounded-full btn-confirm"
    aria-label="Close"
    (click)="onGetNotifiedClicked()"
  >
    {{ 'showSmsPrompt.buttonTxt' | translate }}
  </button>
</div>
