import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  MissionKind,
  PreRollKind,
  SuzySdkService,
  WellKnownErrorCodes
} from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { isArray } from 'util';
import { MetaData } from '../../../core/models/meta-data.model';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { MissionExpiredService } from '../../../core/services/mission-expired/mission-expired.service';
import { MetaDataService } from '../../../core/services/meta-data.service';
import { Mission } from '../../../views/actions/mission.model';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { PreviewService } from '../../preview/preview.service';
import { take } from 'rxjs/operators';
import { GlobalService } from '../../global/global.service';
import { SettingsService } from '../../../core/services/settings.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { SkipModalService } from '../../../core/services/skip-modal-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { beforeModalDismiss } from '../actions.service';
import { SpecialTileService } from '@suzy/shared/special-tile';

export interface TurfAction {
  helpText: string;
  noneText: string;
  minChoices: number;
  maxChoices: number;
  options: Array<any>;
  question: string;
  variants: Array<any>;
  has_monadic: boolean;
}

@Component({
  selector: 'app-turf',
  templateUrl: './turf.component.html',
  styleUrls: ['./turf.component.scss']
})
export class TurfComponent implements OnDestroy, OnInit {
  action: any = {};
  mission: Mission;
  turf: TurfAction;
  subject: Subject<any> = new Subject<any>();
  selectedCheckboxes: Array<any> = [];
  minChoices: number;
  maxChoices: number;
  isDisabled: boolean;
  errorMessage = '';
  isLoading = false;
  hasPreroll = false;
  prerollViewed = false;
  isSuccess = false;
  isSubmitting = false;
  preroll_image: number = PreRollKind.image;
  preroll_link: number = PreRollKind.link;
  isNoneSelected: boolean;
  imageModalIsActive: boolean;
  activeAnswerChoiceText: string;
  currentChoiceIndex: number;
  turfOptionsExcludingNone = [];
  variantKey = 0;
  question = '';
  preRollUrl = '';
  preRollCaption = '';
  preRollKind = PreRollKind.none;
  showPreroll = true;
  metaData: MetaData;
  startDate: number;
  timeToAction: number;
  clientX: number;
  clientY: number;
  timeToActionIsUpdated = false;
  optionPositions = [];
  isTrapQuestion: boolean;
  disableSkip: boolean;
  radioButtonIndexSelected: number;
  startTime: number;
  isSkipping: boolean;
  selectedRadio: any;
  isPreview = false;
  showSurveyProgress = false;
  isBannedOrDisabled = false;
  canFlagAction?: boolean;

  private trackActionName = true;
  specialTileName: any;

  constructor(
    private suzySDK: SuzySdkService,
    private alerts: AppAlertService,
    private missionExpiredService: MissionExpiredService,
    private missionService: MissionService,
    private skipModalService: SkipModalService,
    private modals: NgbModal,
    private metaDataService: MetaDataService,
    private segmentService: SegmentService,
    private auth: AuthenticationService,
    private hostElement: ElementRef,
    private previewService: PreviewService,
    private globalService: GlobalService,
    private translate: TranslateService,
    private settingsService: SettingsService,
    private launchDarklyService: LaunchDarklyService,
    private specialTileService: SpecialTileService
  ) {}

  ngOnInit(): void {
    this.trackActionName = this.launchDarklyService.getGLB723Flag();
    this.showSurveyProgress =
      this.launchDarklyService.getCWDT21752418SurveyProgress() &&
      this.missionService.isSurveyInProgress(this.mission);
    this.isPreview = this.settingsService.isPreviewApp();
    this.question = this.action.turf.question;
    this.startDate = Date.now();
    this.startTime = new Date().getTime();

    this.init();
    this.initPreroll();
    this.initMetaData();
    this.trackActionStarted();

    if (this.mission.first_action?.turf) {
      this.isTrapQuestion =
        this.mission.first_action.turf.expected_answers_enabled;
    }

    if (
      this.mission.first_action?.prevent_skip ||
      this.mission.mission_kind !== MissionKind.standard
    ) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }

    this.action.turf.options.forEach(option => {
      option.isSelected = false;
      if (!option.is_none || !option.piped_is_none) {
        this.turfOptionsExcludingNone.push(option);
      }
    });
    this.skipModalService.isSkip.pipe(take(1)).subscribe(data => {
      if (data === 'turf') {
        this.skipAction();
      }
    });

    this.canFlagAction =
      this.launchDarklyService.getCWDT2642And2646AbilityToFlagMissions();

    this.specialTileName = this.specialTileService.getSpecialTileName(
      this.mission.external_json
    );
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject = undefined;
    window['noPrint'] = false;
    window['noCopy'] = false;
    window['noScreenshot'] = false;
  }

  init(): void {
    const action = this.action.turf;
    this.initAction(action);
  }

  initPreroll(): void {
    if (this.monadicEnabled()) {
      this.setupMonadic();
    } else {
      this.preRollKind = this.action.preroll_kind;

      if (
        this.action.preroll_kind !== 'undefined' &&
        this.action.preroll_kind !== PreRollKind.none
      ) {
        this.preRollCaption = this.action.preroll_instruction;
        if (
          this.action.preroll_kind === PreRollKind.image &&
          this.action.preroll
        ) {
          this.hasPreroll = true;
          this.preRollUrl = this.action.preroll.thumbs.full.url;
        } else if (
          this.action.preroll_kind === PreRollKind.link &&
          this.action.preroll_body
        ) {
          this.hasPreroll = true;
          this.preRollUrl = this.action.preroll_body;
        }
      }
    }
  }

  initAction(action: any): void {
    this.turf = {
      helpText: action.help_text,
      noneText: action.none_prompt,
      minChoices: action.min_choices,
      maxChoices: action.max_choices,
      options: [].concat(action.options),
      variants: [].concat(action.variants),
      question: action.question,
      has_monadic: action.has_monadic
    };

    if (this.turf?.maxChoices > this.turf?.options.length) {
      this.turf.maxChoices = this.turf.options.length;
    }
  }

  initMetaData() {
    this.metaData = {
      userId: '',
      type: 'turf',
      actionId: undefined,
      missionId: undefined,
      text: this.question,
      response: undefined,
      attempt: this.startDate,
      startDateTime: this.startDate,
      actionStartDateTime: this.startDate,
      completedDateTime: undefined,
      dwellingTime: undefined,
      completionTime: undefined,
      interactionDateTime: undefined,
      closedDateTime: undefined,
      skipDateTime: undefined,
      skipFlag: false,
      mousePosition: undefined,
      deviceInfo: undefined,
      validationCount: 0,
      questionCounts: undefined,
      responseCount: undefined,
      numberOfOptions: this.turf.options.length,
      optionPositions: undefined
    };
  }

  trackActionStarted(): void {
    this.segmentService.trackActionStarted({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName ? this.question : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      image_answers: this.action.turf.image_answers ?? false,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      preroll_kind: this.action.preroll_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      points_available:
        this.mission.action_count === 1
          ? this.mission.points
          : this.action.points_extra,
      tag: this.specialTileName
    });
  }

  setupMonadic(): void {
    this.variantKey =
      this.mission.monadic_variants.length <
      this.mission.monadic_variant_key + 1
        ? 0
        : this.mission.monadic_variant_key;

    if (!this.variantKey) {
      this.variantKey = 0;
    }
    const variant = this.mission.monadic_variants[this.variantKey];

    this.globalService.setMonadicVariantId(variant.monadic_variant_id); //Storing variant ID

    this.hasPreroll = true;
    this.showPreroll = !this.action.passive_preroll_enabled;

    if (!this.showPreroll) {
      this.prerollViewed = true;
    }

    this.preRollCaption = variant.preroll_instruction;
    this.preRollKind = variant.preroll_kind;
    if (this.preRollKind === PreRollKind.image) {
      this.preRollUrl = variant.preroll.thumbs.full.url;
    } else if (this.preRollKind === PreRollKind.link) {
      this.preRollUrl = variant.preroll_body;
    }
  }

  monadicEnabled(): boolean {
    return this.action.has_monadic;
  }

  chooseExactlyOneChoice(): boolean {
    return this.turf.maxChoices === 1;
  }

  chooseExactlyXChoices(): boolean {
    return (
      !this.chooseExactlyOneChoice() &&
      this.turf.minChoices === this.turf.maxChoices
    );
  }

  chooseAtLeastXChoices(): boolean {
    return (
      this.turf.minChoices > 1 &&
      this.turf.maxChoices === this.turf.options.length &&
      this.turf.minChoices < this.turf.options.length
    );
  }

  chooseUpToXChoices(): boolean {
    return this.turf.minChoices === 1 && this.turf.maxChoices > 1;
  }

  chooseBetweenXandYChoices(): boolean {
    return (
      this.turf.minChoices > 1 &&
      this.turf.maxChoices < this.turf.options.length &&
      this.turf.minChoices !== this.turf.maxChoices
    );
  }

  onPrerollViewedEvent(): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: false
    });
    this.prerollViewed = true;
  }

  onViewPrerollImage(): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true
    });
  }

  onViewPrerollLink(): void {
    this.segmentService.trackMediaOpen({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      asset_id: this.action.preroll ? this.action.preroll.asset_id : undefined,
      asset_url: this.preRollUrl,
      preroll_kind: this.preRollKind,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      is_on_question: true
    });
  }

  onCheckBoxChange({ option, event }): void {
    this.errorMessage = '';
    this.isBannedOrDisabled = false;
    this.updateTimeToAction();
    const isChecked = event.target.checked;
    const totalSelected = this.selectedCheckboxes.length;
    const index = this.selectedCheckboxes.indexOf(option.answer_id);

    if (option.is_none === true || option.piped_is_none === true) {
      this.isNoneSelected = true;
      if (isChecked) {
        this.selectedCheckboxes = [];
        this.turf.options.forEach(item => {
          item.isSelected = false;
        });
        option.isSelected = true;
        this.selectedCheckboxes.push(option.answer_id);
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
        option.isSelected = false;
        this.selectedCheckboxes = [];
      }
    } else if (isChecked && index < 0) {
      if (this.isDisabled === true) {
        const selectedOption = this.turf.options.find(
          item => item.answer_id === this.selectedCheckboxes[0]
        );
        selectedOption.isSelected = false;
        this.isDisabled = false;
        this.selectedCheckboxes = [];
      }
      if (totalSelected + 1 > this.turf.maxChoices) {
        for (let x of this.turf.options) {
          if (this.selectedCheckboxes[0] === x.answer_id) {
            x.isSelected = false;
            break;
          }
        }
        this.selectedCheckboxes.splice(0, 1);
        this.selectedCheckboxes.push(option.answer_id);
        option.isSelected = true;
      } else {
        this.selectedCheckboxes.push(option.answer_id);
        option.isSelected = true;
      }
    } else {
      this.selectedCheckboxes.splice(index, 1);
      option.isSelected = false;
    }
  }

  submitMultipleOption(input): any {
    if (this.settingsService.isGlobalApp()) {
      const variantId = this.globalService.getMonadicVariantId() ?? null;
      if (variantId) {
        input.monadic_variant_id = variantId;
      }
    }
    this.isSubmitting = true;
    this.isLoading = true;
    if (
      this.mission.isGlobal ||
      this.settingsService.isPreviewApp() ||
      this.settingsService.isGlobalApp() ||
      this.settingsService.isExternalApp()
    ) {
      this.globalResponse(input);
    } else {
      this.crowdtapResponse(input);
    }
  }

  crowdtapResponse(input): void {
    input = {
      ...input,
      correlation_data: this.action.correlation_data
        ? this.action.correlation_data
        : undefined
    };

    this.suzySDK.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.isLoading = false;
          this.isSuccess = true;
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);

          if (
            data.error_code &&
            data.error_code === WellKnownErrorCodes.onboarding_trap_warning
          ) {
            this.isSuccess = false;
            this.segmentService.trackQuestionFailedModalViewed({
              action_id: this.action.action_id,
              brand_id: this.mission.brand_id,
              mission_id: this.mission.mission_id
            });
            swal.fire({
              customClass: 'general-modal attention-modal',
              html:
                'Your response was flagged as going against one of our ' +
                '<a href="https://support.crowdtap.com/hc/en-us/articles/360030907972-Community-guidelines" target="_blank">Community Guidelines.</a>' +
                ' Make sure to read thoroughly, answer honestly, be respectful and follow our guidelines to remain in good standing.' +
                '</br>' +
                '</br>' +
                'Your account will be disabled after the 3rd warning.',
              title: 'Attention!',
              imageUrl: '../../../../assets/img/svg/icons/ic-warning-blue.svg',
              imageWidth: 80,
              imageHeight: 80,
              confirmButtonText: 'I Understand'
            });
          } else if (
            data.error_code &&
            data.error_code === WellKnownErrorCodes.onboarding_trap_banned
          ) {
            this.isSuccess = false;
            this.segmentService.trackQuestionFailedModalViewed({
              action_id: this.action.action_id,
              brand_id: this.mission.brand_id,
              mission_id: this.mission.mission_id
            });
            swal
              .fire({
                customClass: 'general-modal',
                title: `Account Suspended`,
                html:
                  'You didn’t meet our attention check requirements. If this was done in error, please ' +
                  '<a href="https://support.crowdtap.com/hc/en-us?_ga=2.32338235.443825342.1658849519-400642095.1658179860" target="_blank">contact support</a>',
                imageUrl: '../../../../assets/img/svg/icons/ic-suspension.svg',
                imageWidth: 94,
                imageHeight: 94,
                imageAlt: 'Temporary suspension',
                confirmButtonText: 'Got it'
              })
              .then(result => {
                if (result) {
                  this.auth.logout(false, false, '/');
                }
              });
          } else {
            let message = data.message;
            if (data.errors) {
              for (const error of Object.keys(data.errors)) {
                message += `\n - ${data.errors[error]}`;
              }
              this.segmentService.trackActionError({
                action_id: this.action.action_id,
                action_kind: this.action.action_kind,
                brand_id: this.mission.brand_id,
                error_code:
                  data.error_code && data.error_code in WellKnownErrorCodes
                    ? WellKnownErrorCodes[data.error_code]
                    : data.error_code,
                error_source: 'backend',
                error_message: message,
                error_ui_message: undefined,
                mission_id: this.mission.mission_id,
                mission_kind: this.mission.mission_kind,
                monadic_variant_id:
                  this.action.has_monadic &&
                  this.action.monadic_variants.length === 1
                    ? this.action.monadic_variants[0].monadic_variant_id
                    : undefined,
                tag: this.mission.specialType
              });
            }
          }
        } else {
          this.isBannedOrDisabled = ['user.Banned', 'user.Disabled'].some(
            x => x === data.message_token
          );

          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }

          this.isLoading = false;
          this.isSubmitting = false;
          let errorCode = [404, 409, 401];
          if (errorCode.includes(data.error_code)) {
            this.openMissionExpiredModal(message);
          } else {
            this.errorMessage = message;
          }

          this.segmentService.trackActionError({
            action_id: this.action.action_id,
            action_kind: this.action.action_kind,
            brand_id: this.mission.brand_id,
            error_code: data.error_code,
            error_source: 'backend',
            error_message: message,
            error_ui_message: message,
            mission_id: this.mission.mission_id,
            mission_kind: this.mission.mission_kind,
            monadic_variant_id:
              this.action.has_monadic &&
              this.action.monadic_variants.length === 1
                ? this.action.monadic_variants[0].monadic_variant_id
                : undefined,
            tag: this.mission.specialType
          });
        }
      },
      error => {
        this.isLoading = false;
        let errorCode = [404, 409, 401];
        if (errorCode.includes(error.error_code)) {
          this.openMissionExpiredModal(error.message);
        } else {
          this.isBannedOrDisabled = false;
          this.errorMessage = error;
        }
      }
    );
  }

  globalResponse(input): void {
    input = {
      payload: {
        ...input,
        correlation_data: this.action.correlation_data
          ? this.action.correlation_data
          : undefined
      }
    };
    this.globalService.respondToGlobalActionAsync(input).subscribe(
      data => {
        if (data.success) {
          this.isLoading = false;
          this.isSuccess = true;
          setTimeout(() => {
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
            this.subject.next(data);
          }, 800);
        } else {
          this.isLoading = false;
          this.isSubmitting = false;
          if (
            document.body.classList.contains('isPreview') &&
            data.message_token == 'mission.ScreenedOut'
          ) {
            this.subject.next(data);
          } else {
            this.isBannedOrDisabled = ['user.Banned', 'user.Disabled'].some(
              x => x === data.message_token
            );
            let message = '';
            if (data.errors) {
              for (const error of Object.keys(data.errors)) {
                message += `\n - ${data.errors[error]}`;
              }
              this.errorMessage = message;
            }
          }
        }
      },
      error => {
        this.isLoading = false;
        this.isSubmitting = false;
      }
    );
  }

  skipAction(): void {
    if (this.isSubmitting) {
      return;
    }
    this.sendMetaData('skip');
    this.isSkipping = true;

    this.hostElement.nativeElement
      .closest('.modal')
      ?.classList?.add('is-skipping');
    const input = {
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      action_id: this.action.action_id
    };
    this.suzySDK.ProtocolRespond.skip(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.skipModalService.isSuccess.next(true);
          setTimeout(() => {
            this.subject.next('skip');
            this.isSkipping = false;
            if (this.mission.action_count === 1) {
              this.segmentService.trackMission('Mission Skipped', {
                action_count: this.mission.action_count,
                brand_id: this.mission.brand_id,
                mission_id: this.mission.mission_id,
                points_available: this.mission.points,
                mission_kind: this.mission.mission_kind,
                has_cover: false,
                monadic_variant_quantity: this.mission.monadic_variants
                  ? this.mission.monadic_variants.length
                  : undefined,
                tag: this.specialTileName,
                weight: this.mission.weight
              });
            }
            this.missionService.onMissionAnswered(
              this.mission.mission_id,
              data.meta
            );
          }, 1000);
        } else {
          this.skipModalService.isSuccess.next(false);
          this.isSkipping = false;
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.skipModalService.isSuccess.next(false);
        this.isSkipping = false;
        this.alerts.notify({ title: 'Error', message: error });
      }
    );
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, 'skip');
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = null;
    instance.actionType = this.metaData?.type;
  }

  onSkip($event): void {
    $event.preventDefault();
    this.openSkipModal();
  }

  onMouseMoveEvent(event: MouseEvent): void {
    this.clientX = event.clientX;
    this.clientY = event.clientY;
  }

  updateTimeToAction(): void {
    if (!this.timeToActionIsUpdated) {
      this.timeToAction = Date.now();
    }
    this.timeToActionIsUpdated = true;
  }

  sendMetaData(actionType: string): void {
    let completedDateTime: number;
    let skipDateTime: number;
    let skipFlag: boolean;
    switch (actionType) {
      case 'submit':
        completedDateTime = Date.now();
        break;
      case 'skip':
        skipDateTime = Date.now();
        skipFlag = false;
        break;
      default:
        break;
    }

    const meta = {
      ...this.metaData,
      actionId: this.action.action_id,
      missionId: this.mission.mission_id,
      text: this.question,
      completedDateTime,
      interactionDateTime: this.timeToAction,
      skipDateTime,
      skipFlag,
      mousePosition: { x: this.clientX, y: this.clientY },
      numberOfOptions: this.turf.options.length,
      optionPositions: this.optionPositions
    };

    this.metaDataService.createMetaData(meta);
  }

  onSubmit(): void {
    if (this.isSubmitting) {
      return;
    }
    let input: any;
    if (this.isValidInput(this.selectedCheckboxes)) {
      this.turf.options.forEach((option, index) => {
        this.selectedCheckboxes.forEach(selectedId => {
          if (option.answer_id === selectedId) {
            this.optionPositions.push(index);
          }
        });
      });

      this.sendMetaData('submit');
      this.segmentService.trackAction('Action Completed', {
        brand_id: this.mission.brand_id,
        action_id: this.action.action_id,
        action_name: this.trackActionName ? this.question : undefined,
        mission_id: this.mission.mission_id,
        action_kind: this.action.action_kind,
        monadic_variant_id:
          this.action.has_monadic && this.action.monadic_variants.length === 1
            ? this.action.monadic_variants[0].monadic_variant_id
            : undefined,
        tag: this.specialTileName
      });

      input = {
        action_id: this.action.action_id,
        answer_ids: this.selectedCheckboxes
      };

      if (this.monadicEnabled()) {
        input.monadic_variant_id =
          this.mission.monadic_variants[this.variantKey].monadic_variant_id;
      }

      this.submitMultipleOption(input);
    }
  }

  onSubmitPrerequisite(): void {
    this.isLoading = true;
    if (this.isSubmitting) {
      return;
    }
    let input: any;
    if (this.isValidInput(this.selectedCheckboxes)) {
      this.turf.options.forEach((option, index) => {
        this.selectedCheckboxes.forEach(selectedId => {
          if (option.answer_id === selectedId) {
            this.optionPositions.push(index);
          }
        });
      });

      this.sendMetaData('submit');
      this.segmentService.trackAction('Action Completed', {
        brand_id: this.mission.brand_id,
        action_id: this.action.action_id,
        action_name: this.trackActionName ? this.question : undefined,
        mission_id: this.mission.mission_id,
        action_kind: this.action.action_kind,
        monadic_variant_id:
          this.action.has_monadic && this.action.monadic_variants.length === 1
            ? this.action.monadic_variants[0].monadic_variant_id
            : undefined,
        tag: this.specialTileName
      });

      input = {
        action_id: this.action.action_id,
        answer_ids: this.selectedCheckboxes
      };

      if (this.monadicEnabled()) {
        input.monadic_variant_id =
          this.mission.monadic_variants[this.variantKey].monadic_variant_id;
      }
      this.previewService
        .submitPrerequisite(input, this.mission)
        .subscribe(response => {
          this.isLoading = false;

          if (response.errors) {
            let message = response.message;

            for (const error of Object.keys(response.errors)) {
              message += response.errors[error];
            }
            this.errorMessage = message;
          } else {
            this.isSuccess = true;
          }
        });
    }
  }

  getSelectedCheckboxAnswerIndexArray(): Array<number> {
    const optionIndexArr = [];
    this.selectedCheckboxes.forEach(checkbox => {
      this.turf.options.forEach(option => {
        if (checkbox === option.answer_id) {
          optionIndexArr.push(option.priority);
        }
      });
    });

    return optionIndexArr;
  }

  isValidInput(input): boolean {
    if (this.isNoneSelected) {
      return true;
    }
    input = Array.isArray(input) ? input : [input];
    if (
      input.length < this.turf.minChoices ||
      input.length > this.turf.maxChoices
    ) {
      this.translate
        .get('general.mustSelect')
        .pipe(take(1))
        .subscribe(value => {
          this.errorMessage = value;
          this.segmentTrackValidationError(this.errorMessage);
        });

      this.metaData.validationCount++;

      return false;
    } else {
      return true;
    }
  }

  removeErrorPopover(): void {
    this.errorMessage = '';
    this.isBannedOrDisabled = false;
  }

  openMissionExpiredModal(message: any) {
    this.missionExpiredService.notify({
      mission: this.mission,
      title: 'Error',
      message: message
    });
  }

  segmentTrackValidationError(errorMessage: string): void {
    this.segmentService.trackValidationError({
      action_id: this.action.action_id,
      action_kind: this.action.action_kind,
      brand_id: this.mission.brand_id,
      error_code: undefined,
      error_source: 'frontend',
      error_message: errorMessage,
      error_ui_message: errorMessage,
      mission_id: this.mission.mission_id,
      mission_kind: this.mission.mission_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      tag: this.mission.specialType
    });
  }

  get calculateCompletion(): string {
    return (
      Math.round(
        (this.action.step_priority / this.mission.action_count) * 100
      ) + '%'
    );
  }
}
