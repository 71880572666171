<div class="detail-modal relative">
  <div>
    <button
      type="button"
      class="close-btn absolute -top-4 right-0"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div
    class="text-left font-medium mb-6 text-box-dark dark:text-box-light pt-4"
  >
    {{ 'survey.shareFeedback' | translate }}
  </div>

  <div class="mt-6" *ngFor="let flagOption of flagOptions">
    <label
      for="flag_option_{{ flagOption.value }}"
      class="flex items-center"
      tabindex="0"
    >
      <input
        class="sr-only"
        type="checkbox"
        id="flag_option_{{ flagOption.value }}"
        [checked]="flagOption.checked"
        (change)="onCheckBoxChange(flagOption.value)"
      />
      <div
        *ngIf="!flagOption.checked"
        class="w-6 h-6 border-2 min-w-6 dark:border-white-line border-black-line rounded-md mr-4 ltr:mr-4 rtl:ml-4"
      ></div>
      <div
        *ngIf="flagOption.checked"
        class="flex items-center justify-center w-6 h-6 min-w-6 bg-blue-dark rounded-md mr-4 ltr:mr-4 rtl:ml-4"
      >
        <span class="text-xs text-white fa fa-check"></span>
      </div>
      <div class="text-base font-semibold text-box-dark dark:text-box-light">
        {{ flagOption.label }}
      </div>
    </label>
  </div>
  <div
    *ngIf="noSelection"
    class="flex items-center justify-start mt-5 gap-2 h-12"
  >
    <div class="w-5 h-5">
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.47012 17.5036C0.930121 17.5036 -0.0298788 15.8336 0.740121 14.5036L8.27012 1.49359C9.04012 0.163594 10.9601 0.163594 11.7301 1.49359L19.2601 14.5036C20.0301 15.8336 19.0701 17.5036 17.5301 17.5036H2.47012ZM17.5301 15.5036L10.0001 2.49359L2.47012 15.5036H17.5301ZM9.00012 7.50359V9.50359C9.00012 10.0536 9.45012 10.5036 10.0001 10.5036C10.5501 10.5036 11.0001 10.0536 11.0001 9.50359V7.50359C11.0001 6.95359 10.5501 6.50359 10.0001 6.50359C9.45012 6.50359 9.00012 6.95359 9.00012 7.50359ZM11.0001 14.5036V12.5036H9.00012V14.5036H11.0001Z"
          fill="#FF5F57"
        />
      </svg>
    </div>
    <span class="text-sm font-semibold text-error">
      {{ 'survey.feedbackSelectWarn' | translate }}
    </span>
  </div>
  <div
    class="flex items-center justify-start gap-4 h-12 mt-5"
    *ngIf="showMessage"
  >
    <div class="h-6 w-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 7V9H13V7H11ZM13 16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
          fill="#FF8840"
        />
      </svg>
    </div>
    <span class="text-left text-sm font-semibold text-orange">
      {{ 'survey.endSurveyFeedback' | translate }}
    </span>
  </div>

  <div class="relative flex justify-center space-x-4 mt-8">
    <button
      class="border-2 border-blue-dark text-blue-dark px-6 h-12 text-sm font-bold rounded-full"
      (click)="closeModal()"
    >
      <span class="btn-label"> {{ 'survey.returnToSurvey' | translate }} </span>
    </button>

    <button
      *ngIf="showSubmitButton()"
      class="bg-blue-dark text-white px-6 h-12 text-sm font-bold rounded-full"
      (click)="onSubmitClick()"
    >
      <span class="btn-label"> {{ 'general.submit' | translate }} </span>
    </button>
  </div>
</div>
