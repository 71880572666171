export const Controls = [
  {
    control: 'Account Settings',
    availableTo: 'Members',
    details:
      'You can control your Personal Data directly within your account settings. You can view, edit, correct, update, delete, or download some of your data directly in your Crowdtap profile (by navigating to the information entered on your profile pages), or you can contact our support team at https://support.crowdtap.com/hc/en-us to help do so.'
  },
  {
    control: 'Terminating Membership',
    availableTo: 'Members',
    details:
      'You may terminate your membership by contacting us at https://support.crowdtap.com/hc/en-us/articles/360025098951-Deleting-your-account or by using the “terminate membership” function if available. Upon terminating your membership, we will remove the following Personal Data from Crowdtap’s active database: password, member profile, full name, address (except for zip code), email address, other contact information, Facebook account information, Twitter account information, and profile photo.'
  },
  {
    control: 'Deleting the Crowdtap app from your device',
    availableTo: 'Members',
    details:
      'If you no longer wish to use the Crowdtap app or to receive notifications to complete Actions, you can delete the Crowdtap app from your device. However, if you wish to terminate your membership, please see “Terminating Membership”, above.'
  },
  {
    control: 'Voluntary Action Participation',
    availableTo: 'Members',
    details:
      'You can always refuse to answer an Action. Your participation in any survey is entirely voluntary, and you may opt out of responding at any time by skipping the question, declining the invitation, or selecting “prefer not to say”, where applicable.'
  },
  {
    control: 'Advertising Controls',
    availableTo: 'Members; Visitors',
    details:
      'Some of the business partners that may collect information about your activities on our Sites may be members of organizations or programs that provide choices to individuals regarding the use of their browsing behavior for purposes of targeted advertising. For example, you may opt out of receiving targeted advertising through members of the Network Advertising Initiative by clicking here (http://optout.networkadvertising.org/) or the Digital Advertising Alliance by clicking here (http://www.aboutads.info/choices). European users may opt out of receiving targeted advertising through members of the European Interactive Digital Advertising Alliance by clicking here (http://www.youronlinechoices.eu/), selecting your country, and then clicking “Choices” (or similarly-titled link). Mobile app users may opt out of receiving targeted advertising in mobile apps through members of the Digital Advertising Alliance by installing the AppChoices mobile app, available here (https://www.youradchoices.com/appchoices), and selecting the user’s choices. Please note that we may also work with companies that offer their own opt-out mechanisms and may not participate in the opt-out mechanisms that we linked to above.'
  },
  {
    control: 'Marketing Emails and Service Announcements Controls',
    availableTo: 'Members; Visitors',
    details:
      "You may unsubscribe from receiving marketing or other commercial emails from us by following the instructions included in the email (e.g., by clicking the “Unsubscribe” link at the bottom of an email). You will, however, still receive non-marketing or transactional emails from us as permitted by applicable law (e.g., emailing you to let you know we’ve updated our Terms of Service, to let you know there’s been a change to your account, to correct account errors, etc.). You are not able to unsubscribe or 'opt out' of these communications because they may contain important information about your account."
  },
  {
    control: 'Do Not Track signals',
    availableTo: 'Members; Visitors',
    details:
      "'Do Not Track' is a feature enabled on some browsers that sends a signal to request that a website disable its tracking or cross-website user tracking. We treat any user-enabled global privacy controls, such as a browser plug-in or privacy setting, device setting, or other mechanism, that communicate or signal your choice to opt-out of the sale of your Personal Data as a valid request submitted pursuant to applicable privacy laws for that browser or device, or, if known, for the individual."
  }
];
