import { Routes } from '@angular/router';
import { StatesResolver } from '../../core/resolvers/states.resolver';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { SignoutComponent } from './signout/signout.component';
import { AuthGuard } from '../../core/guards/auth.guard';
import { SMSAuthenticationComponent } from './sms-authentication/sms-authentication.component';
import { GenericSharingMissionComponent } from './generic-sharing-mission/generic-sharing-mission.component';
import { AccessDeniedComponent } from './unauthorized/access-denied/access-denied.component';
import { CountryNotSupportedComponent } from './unauthorized/country-not-supported/country-not-supported';
import { InvalidBrowserComponent } from './unauthorized/invalid-browser/invalid-browser.component';
import { BlockedIPComponent } from './unauthorized/blocked-ip/blocked-ip.component';
import { BlockedIPGuard } from '../../core/guards/blocked-ip.guard';
import { AutoLoginComponent } from './auto-login/auto-login.component';
import { AccountCreateEmailComponent } from './signup/account-create-email/account-create-email.component';
import { GetToKnowYouComponent } from './signup/get-to-know-you/get-to-know-you.component';
import { AboutYourSelfComponent } from './signup/about-your-self/about-your-self.component';
import { AlmostThereComponent } from './signup/almost-there/almost-there.component';
import { GetVerifiedComponent } from './signup/get-verified/get-verified.component';
import { RedirectGuard } from '../../core/guards/redirect.guard';
import { SetRewardGoalComponent } from './signup/set-reward-goal/set-reward-goal.component';
import {
  EducationOptionsResolver,
  EmploymentOptionsResolver,
  EthnicitiesResolver,
  HouseholdOptionsResolver,
  IncomeLevelOptionsResolver,
  ParentingOptionsResolver,
  RelationshipOptionsResolver
} from '../../core/resolvers';
import { SendEmailInfoComponent } from './signup/send-email-info/send-email-info.component';

export const AnonymousRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'account-create-email',
        component: AccountCreateEmailComponent,
        data: { title: 'Create your account' }
      },
      {
        path: 'get-to-know-you',
        component: GetToKnowYouComponent,
        data: { title: 'Get To Know You' },
        resolve: {
          states: StatesResolver,
          ethnicities: EthnicitiesResolver,
          educationOptions: EducationOptionsResolver
        }
      },
      {
        path: 'about-yourself',
        component: AboutYourSelfComponent,
        data: { title: 'About yourself' },
        resolve: {
          states: StatesResolver,
          ethnicities: EthnicitiesResolver,
          employmentOptions: EmploymentOptionsResolver,
          householdOptions: HouseholdOptionsResolver,
          parentingOptions: ParentingOptionsResolver,
          relationshipOptions: RelationshipOptionsResolver
        }
      },
      {
        path: 'almost-there',
        component: AlmostThereComponent,
        data: { title: 'Almost there' },
        resolve: {
          states: StatesResolver,
          incomeLevelOptions: IncomeLevelOptionsResolver
        }
      },
      {
        path: 'set-reward-goal',
        component: SetRewardGoalComponent,
        canActivate: [AuthGuard, BlockedIPGuard],
        data: { title: 'Set Reward Goal' }
      },
      {
        path: 'email-sent',
        component: SendEmailInfoComponent,
        canActivate: [AuthGuard, BlockedIPGuard],
        data: { title: 'Check your email', mobile: false }
      },
      {
        path: 'get-verified',
        component: GetVerifiedComponent,
        canActivate: [AuthGuard, BlockedIPGuard],
        data: { title: 'Get verified', mobile: false }
      },
      {
        path: 'account-creation-email',
        component: AccountCreateEmailComponent,
        data: { title: 'Create your account' }
      },
      {
        path: 'getting-to-know-you',
        // component: GettingToKnowYouComponent,
        // data: { title: 'Getting To Know You' },
        // resolve: { states: StatesResolver }
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          url: '/auth/get-to-know-you'
        }
      },
      {
        canActivate: [AuthGuard, BlockedIPGuard],
        path: 'sms-authentication',
        component: SMSAuthenticationComponent,
        data: { title: 'SMS Authentication', mobile: false }
      },
      {
        canActivate: [AuthGuard, BlockedIPGuard],
        path: 'sms-authentication/mobile',
        component: SMSAuthenticationComponent,
        data: { title: 'SMS Authentication Mobile', mobile: true }
      },
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login', mobile: false },
        canActivate: [BlockedIPGuard]
      },
      {
        path: 'login/mobile',
        component: LoginComponent,
        data: { title: 'Login Mobile', mobile: true },
        canActivate: [BlockedIPGuard]
      },
      {
        path: 'auto-login/mobile',
        component: AutoLoginComponent,
        data: { title: 'Login Mobile', mobile: true },
        canActivate: [BlockedIPGuard]
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' },
        canActivate: [BlockedIPGuard]
      },
      {
        canActivate: [AuthGuard, BlockedIPGuard],
        path: 'impersonate',
        component: ImpersonateComponent,
        data: { title: 'Signin' }
      },
      {
        path: 'signout',
        component: SignoutComponent,
        data: { title: 'Signout' }
      },
      {
        path: 'lost-password',
        component: LostPasswordComponent,
        data: { title: 'Lost password' },
        canActivate: [BlockedIPGuard]
      },
      {
        path: 'terms-of-service',
        redirectTo: '/terms-of-service',
        pathMatch: 'full',
        data: { title: 'Terms of Service' }
      },
      {
        path: 'privacy-policy',
        redirectTo: '/privacy-policy',
        pathMatch: 'full',
        data: { title: 'Privacy Policy' }
      },
      {
        path: 'cookie-policy',
        redirectTo: '/cookie-policy',
        pathMatch: 'full',
        data: { title: 'Cookies Policy' }
      },
      {
        path: 'refer-a-friend',
        redirectTo: '/refer-a-friend',
        pathMatch: 'full',
        data: { title: 'Refer A Friend' }
      },
      {
        path: 'sharing-mission',
        component: GenericSharingMissionComponent,
        data: { title: 'Sharing Mission' }
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
        data: { title: 'Access Denied' }
      },
      {
        path: 'invalid-browser',
        component: InvalidBrowserComponent,
        data: { title: 'Invalid Browser' }
      },
      {
        path: 'country-not-supported',
        component: CountryNotSupportedComponent,
        data: { title: 'Country Not Supported' }
      },
      {
        path: 'blocked-ip',
        component: BlockedIPComponent,
        data: { title: 'Blocked IP' }
      },
      {
        path: 'redirect',
        redirectTo: '/redirect',
        pathMatch: 'full'
      },
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
      }
    ]
  }
];
