import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit
} from '@angular/core';
import { ContactInfo } from './ContactInfo';
import { initFlowbite } from 'flowbite';
import { TableOfContent } from './TableOfContent';
import { Rights } from './Rights';
import { Controls } from './Controls';

@Component({
  selector: 'privacy-policy-current',
  templateUrl: './privacy-policy-current.component.html',
  styleUrls: ['./privacy-policy-current.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyPolicyCurrentComponent implements OnInit {
  section3 = false;
  exampleOfTargeting = false;
  activeSection: string = '';
  selectedId: string = '';
  @HostListener('window:scroll', [])
  contactInfo = ContactInfo;
  tableOfContents = TableOfContent;
  rights = Rights;
  controls = Controls;
  isOpen: any;

  ngOnInit() {
    initFlowbite();
    this.isOpen = new Array(this.contactInfo.length).fill(false);
  }

  @HostListener('window:scroll', [])
  onScroll() {
    const scrollPosition = window.scrollY + 100; // Adjust for highlighting

    for (const item of this.tableOfContents) {
      const section = document.getElementById(item.id);
      if (section) {
        const offsetTop = section.offsetTop;
        const offsetHeight = section.offsetHeight;

        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          this.activeSection = item.id;
          break;
        }
      }
    }
  }

  scrollToSection(id: string) {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }

  toggle(property: string) {
    this[property] = !this[property];
  }

  toggleAccordion(index: any) {
    this.isOpen[index] = !this.isOpen[index];
  }
}
