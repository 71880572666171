import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthGuard } from './core/guards/auth.guard';
import { BlockedIPGuard } from './core/guards/blocked-ip.guard';
import { DashboardLoginGuard } from './core/guards/dashboard-login.guard';
import { LocalizeGuard } from './core/guards/localize.guard';
import { RedirectGuard } from './core/guards/redirect.guard';
import { RAFEligibleGuard } from './core/guards/raf-eligible.guard';
import { VerifyGuard } from './core/guards/verify.guard';
import { AnonymousLayoutComponent } from './core/layouts/anonymous/anonymous-layout.component';
import { DefaultLayoutComponent } from './core/layouts/default/default-layout.component';
import { HomepageComponent } from './views/anonymous/homepage/homepage.component';
import { LegalComponent } from './views/anonymous/legal/legal.component';
import { LoadingMobileComponent } from './views/anonymous/loading-mobile/loading-mobile.component';
import { RedirectComponent } from './views/anonymous/redirect/redirect.component';
import { SuccessAnimationComponent } from './views/success-animation/success-animation.component';
import { EmailVerifiedComponent } from './views/anonymous/signup/email-verified/email-verified.component';
import { LinkExpiredComponent } from './views/anonymous/signup/link-expired/link-expired.component'; // *Added Temp for success animation

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [LocalizeGuard],
    children: [
      {
        path: '',
        component: HomepageComponent,
        pathMatch: 'full',
        canActivate: [DashboardLoginGuard, BlockedIPGuard],
        data: { title: 'Homepage' }
      },
      {
        path: 'verify',
        component: EmailVerifiedComponent,
        data: { title: 'Email Verified', mobile: false }
      },
      {
        path: 'link-expired',
        component: LinkExpiredComponent,
        canActivate: [AuthGuard],
        data: { title: 'Link Expired', mobile: false }
      },
      {
        path: 'terms-of-service',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Terms of service',
          policy: 'terms-of-service-policy'
        }
      },
      {
        path: 'terms-of-service/:version',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Terms of service',
          policy: 'terms-of-service-policy'
        }
      },
      {
        path: 'privacy-policy',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Privacy Policy',
          policy: 'privacy-policy'
        }
      },
      {
        path: 'privacy-policy/:version',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Privacy Policy',
          policy: 'privacy-policy'
        }
      },
      {
        path: 'notice-of-financial-incentive',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Notice of Financial Incentive',
          policy: 'notice-of-financial-incentive-policy'
        }
      },
      {
        path: 'notice-of-financial-incentive/:version',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Notice of Financial Incentive',
          policy: 'notice-of-financial-incentive-policy'
        }
      },
      {
        path: 'cookies-policy',
        redirectTo: '/cookie-policy'
      },
      {
        path: 'cookie-policy',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Cookie Policy',
          policy: 'cookie-policy'
        }
      },
      {
        path: 'cookie-policy/:version',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Cookie Policy',
          policy: 'cookie-policy'
        }
      },
      {
        path: 'biometric-data-policy',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Biometric Data Policy',
          policy: 'biometric-policy'
        }
      },
      {
        path: 'biometric-data-policy/:version',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Biometric Data Policy',
          policy: 'biometric-policy'
        }
      },
      {
        path: 'accessibility-statement',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Accessibility Statement',
          policy: 'accessibility-statement'
        }
      },
      {
        path: 'accessibility-statement/:version',
        component: LegalComponent,
        data: {
          title: 'Crowdtap® - Accessibility Statement',
          policy: 'accessibility-statement'
        }
      },
      {
        path: '',
        component: AnonymousLayoutComponent,
        children: [
          {
            path: 'auth',
            loadChildren: () =>
              import('./views/anonymous/anonymous.module').then(
                m => m.AnonymousModule
              ),
            data: { title: '' }
          }
        ]
      },
      // *Added Temp for success animation : Temporary path for success animation demo
      {
        path: 'success-animation',
        component: SuccessAnimationComponent,
        data: { title: 'SuccessAnimation' }
      },
      {
        path: '',
        canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
        component: DefaultLayoutComponent,
        data: { title: 'Dashboard' },
        children: [
          {
            path: 'dashboard',
            data: { title: 'Dashboard' },
            loadChildren: () =>
              import('./views/dashboard/dashboard.module').then(
                m => m.DashboardModule
              )
          },
          {
            path: 'refer-a-friend',
            canActivate: [RAFEligibleGuard],
            data: { title: 'Refer a friend' },
            loadChildren: () =>
              import('./views/refer-friends/refer-friends.module').then(
                m => m.ReferFriendsModule
              )
          },
          {
            path: 'rewards',
            data: { title: 'Rewards' },
            loadChildren: () =>
              import('./views/rewards/rewards.module').then(
                m => m.RewardsModule
              )
          },
          {
            path: 'in-progress',
            data: { title: 'In Progress' },
            loadChildren: () =>
              import('./views/in-progress/in-progress.module').then(
                m => m.InProgressModule
              )
          },
          {
            path: 'profile',
            data: { title: 'Profile' },
            loadChildren: () =>
              import('./views/profile/profile.module').then(
                m => m.ProfileModule
              )
          },
          {
            path: 'settings',
            data: { title: 'Settings' },
            loadChildren: () =>
              import('./views/settings/settings.module').then(
                m => m.SettingsModule
              )
          },
          {
            path: 'history',
            data: { title: 'History' },
            loadChildren: () =>
              import('./views/recent-activity/recent-activity.module').then(
                m => m.RecentActivityModule
              )
          },
          {
            path: 'refer-friends',
            canActivate: [RAFEligibleGuard],
            data: { title: 'Refer Friends' },
            loadChildren: () =>
              import('./views/refer-friends/refer-friends.module').then(
                m => m.ReferFriendsModule
              )
          }
        ]
      },
      // {
      //   path: 'refer-a-friend',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'Refer a friend' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren: () =>
      //         import('./views/refer-friends/refer-friends.module').then(
      //           m => m.ReferFriendsModule
      //         )
      //     }
      //   ]
      // },
      // {
      //   path: 'rewards',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'Rewards' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren: () =>
      //         import('./views/rewards/rewards.module').then(
      //           m => m.RewardsModule
      //         )
      //     }
      //   ]
      // },
      // {
      //   path: 'in-progress',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'In Progress' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren: () =>
      //         import('./views/in-progress/in-progress.module').then(
      //           m => m.InProgressModule
      //         )
      //     }
      //   ]
      // },
      // {
      //   path: 'profile',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'Profile' },
      //   children: [
      //     {
      //       path: '',
      //       data: { title: 'Profile' },
      //       loadChildren: () =>
      //         import('./views/profile/profile.module').then(
      //           m => m.ProfileModule
      //         )
      //     }
      //   ]
      // },
      // {
      //   path: 'settings',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'Settings' },
      //   children: [
      //     {
      //       path: '',
      //       data: { title: 'Settings' },
      //       loadChildren: () =>
      //         import('./views/settings/settings.module').then(
      //           m => m.SettingsModule
      //         )
      //     }
      //   ]
      // },
      // {
      //   path: 'focusgroup',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'Focus Group' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren: () =>
      //         import('./views/focus-group/focus-group.module').then(
      //           m => m.FocusGroupModule
      //         )
      //     }
      //   ]
      // },
      // {
      //   path: 'sampling',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'Sampling' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren: () =>
      //         import('./views/sampling/sampling.module').then(
      //           m => m.SamplingModule
      //         )
      //     }
      //   ]
      // },
      // {
      //   path: 'history',
      //   canActivate: [AuthGuard, VerifyGuard, BlockedIPGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'History' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren: () =>
      //         import('./views/recent-activity/recent-activity.module').then(
      //           m => m.RecentActivityModule
      //         )
      //     }
      //   ]
      // },
      {
        path: 'iheart',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          url: 'https://community.crowdtap.com/iheart'
        }
      },
      {
        path: 'spotify',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          url: 'https://community.crowdtap.com/spotify/'
        }
      },
      {
        path: 'redirect',
        component: RedirectComponent
      },
      // {
      //   path: 'refer-friends',
      //   canActivate: [AuthGuard, VerifyGuard, UserStatusGuard],
      //   component: DefaultLayoutComponent,
      //   data: { title: 'Refer Friends' },
      //   children: [
      //     {
      //       path: '',
      //       loadChildren:
      //         './views/refer-friends/refer-friends.module#ReferFriendsModule'
      //     }
      //   ]
      // },
      {
        path: 'close-browser/mobile',
        component: LoadingMobileComponent,
        data: { title: 'Close', mobile: true }
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

const globalRoutes: Routes = [
  {
    path: ':mission_id/:cint_id',
    data: { title: 'Global', isGlobal: true },
    loadChildren: () =>
      import('./views/global/global.module').then(m => m.GlobalModule)
  },
  {
    path: ':brand_id/:mission_id/:cint_id',
    data: { title: 'Global', isGlobal: true },
    loadChildren: () =>
      import('./views/global/global.module').then(m => m.GlobalModule)
  }
];

const previewRoutes: Routes = [
  {
    path: 'preview/:brand_id/:shared_link_id',
    data: { title: 'Preview' },
    loadChildren: () =>
      import('./views/preview/preview.module').then(m => m.PreviewModule)
  }
];

const externalLinkRoutes: Routes = [
  {
    path: ':brand_id/:shared_link_id',
    data: { title: 'External' },
    loadChildren: () =>
      import('./views/external-link/external-link.module').then(
        m => m.ExternalLinkModule
      )
  },
  {
    path: ':brand_id/:shared_link_id/:audience_id',
    data: { title: 'External' },
    loadChildren: () =>
      import('./views/external-link/external-link.module').then(
        m => m.ExternalLinkModule
      )
  }
];

let routes = [];

// tslint:disable-next-line:prefer-conditional-expression
if (environment?.preview) {
  routes = previewRoutes;
} else if (environment?.global) {
  routes = globalRoutes;
} else if (environment?.externalLink) {
  routes = externalLinkRoutes;
} else {
  routes = appRoutes;
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
