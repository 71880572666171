import { CrowdtapEnvironment } from './definition';

export const environment: CrowdtapEnvironment = {
  production: false,
  preview: false,
  global: false,
  emergency: false,
  local: false,
  externalLink: false,
  apiUrl: 'https://dev-api.asksuzy.com/dev-member',
  globalUrl: 'https://isolate-qa.asksuzy.io/api',
  signalRUrl: 'https://ws-dev.asksuzy.io',
  facebookAppId: '295218810545442',
  grecaptchaToken: '6LcaClsaAAAAAJWoziDMD8F3PhE9XyQFRTzMj1zK',
  grecaptchaV2Token: '6LfkEVsaAAAAAP4uRsbnzOilVnPTAPKaR6PK7P2i',
  languagePlatform: 'crowdtap.v1',
  segmentToken: 'fF4yo8WBiZmgv96UNAzfTRRkE7mKw4B1',
  sharedLinkRoot: '',
  launchDarkly: '60fec13ec07b0d23c5cab89f',
  legalId: '1248700B-3E07-48DF-9427-1885E1FE8213',
  verisoulBundleUri: 'https://js.verisoul.ai/sandbox/bundle.js',
  verisoulProjectId: '157e88f9-2629-4938-acd6-2a8c26affc10'
};
