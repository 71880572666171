<div class="wrapper" #wrapper>
  <app-general-header [isMobile]="isMobile$ | async"> </app-general-header>

  <section
    *ngIf="!isLoading"
    class="anonymous"
    aria-label="main"
    role="main"
    id="main-content"
  >
    <div class="flex flex-col px-6 gap-6 items-center justify-center mt-6">
      <div class="w-20 h-20 text-black dark:text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
        >
          <mask
            id="mask0_5352_12320"
            style="mask-type: alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="80"
            height="80"
          >
            <rect width="80" height="80" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_5352_12320)">
            <path
              d="M35.3334 45.9998L28.1667 38.8332C27.5556 38.2221 26.7779 37.9165 25.8334 37.9165C24.889 37.9165 24.1112 38.2221 23.5001 38.8332C22.889 39.4443 22.5834 40.2221 22.5834 41.1665C22.5834 42.111 22.889 42.8887 23.5001 43.4998L33.0001 52.9998C33.6667 53.6665 34.4445 53.9998 35.3334 53.9998C36.2223 53.9998 37.0001 53.6665 37.6667 52.9998L56.5001 34.1665C57.1112 33.5554 57.4168 32.7776 57.4168 31.8332C57.4168 30.8887 57.1112 30.1109 56.5001 29.4998C55.889 28.8887 55.1112 28.5832 54.1668 28.5832C53.2223 28.5832 52.4445 28.8887 51.8334 29.4998L35.3334 45.9998ZM40.0001 73.3332C35.389 73.3332 31.0556 72.4582 27.0001 70.7082C22.9445 68.9582 19.4167 66.5832 16.4167 63.5832C13.4167 60.5832 11.0417 57.0554 9.29175 52.9998C7.54175 48.9443 6.66675 44.611 6.66675 39.9998C6.66675 35.3887 7.54175 31.0554 9.29175 26.9998C11.0417 22.9443 13.4167 19.4165 16.4167 16.4165C19.4167 13.4165 22.9445 11.0415 27.0001 9.2915C31.0556 7.5415 35.389 6.6665 40.0001 6.6665C44.6112 6.6665 48.9445 7.5415 53.0001 9.2915C57.0556 11.0415 60.5834 13.4165 63.5834 16.4165C66.5834 19.4165 68.9584 22.9443 70.7084 26.9998C72.4584 31.0554 73.3334 35.3887 73.3334 39.9998C73.3334 44.611 72.4584 48.9443 70.7084 52.9998C68.9584 57.0554 66.5834 60.5832 63.5834 63.5832C60.5834 66.5832 57.0556 68.9582 53.0001 70.7082C48.9445 72.4582 44.6112 73.3332 40.0001 73.3332ZM40.0001 66.6665C47.4445 66.6665 53.7501 64.0832 58.9168 58.9165C64.0834 53.7498 66.6668 47.4443 66.6668 39.9998C66.6668 32.5554 64.0834 26.2498 58.9168 21.0832C53.7501 15.9165 47.4445 13.3332 40.0001 13.3332C32.5556 13.3332 26.2501 15.9165 21.0834 21.0832C15.9167 26.2498 13.3334 32.5554 13.3334 39.9998C13.3334 47.4443 15.9167 53.7498 21.0834 58.9165C26.2501 64.0832 32.5556 66.6665 40.0001 66.6665Z"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>
      <h1
        class="text-2xl lg:text-3.5xl text-black dark:text-white font-bold text-center"
      >
        {{ 'emailVerified.title' | translate }}
      </h1>
      <div class="flex gap-2 items-center flex-center py-2">
        <span class="w-6 h-6 text-black dark:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 7V9H13V7H11ZM13 16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <p class="text-black dark:text-white">
          {{ 'emailVerified.youWillRedirected' | translate }}
          <a routerlink="/auth/get-verified" class="text-blue-500">{{
            'emailVerified.clickHere' | translate
          }}</a>
          {{ 'emailVerified.toContinue' | translate }}
        </p>
      </div>
    </div>
  </section>
</div>
<section class="footer-container pb-2.5 sm:pb-0">
  <app-general-footer id="main-footer"></app-general-footer>
</section>
