export const Rights = [
  {
    right: 'Right of Access',
    details:
      'Find out what kind of Personal Data we process about you and request details of this information, including categories of recipients to whom the Personal Data have been or will be disclosed and purposes of processing.'
  },
  {
    right: 'Right to Know',
    details:
      'Ask us for a notice identifying the categories of Personal Data that we collect (and from whom), disclose, or share (and to whom we disclose or share), as well as our business or commercial purposes for collecting, disclosing, or selling that Personal Data. In most respects, this Policy serves as such notice.'
  },
  {
    right: 'Right to Rectify, also known as Right to Correct',
    details:
      'Ask for your Personal Data to be rectified, updated or, corrected. We may need to verify the accuracy of the new information you provide to us.'
  },
  {
    right: 'Right to Transfer, also known as Right to Data Portability',
    details:
      'Ask us to package up your Personal Data in a structured, commonly used and machine-readable format, so you can move, copy, or transfer it to another organization / data controller in a secure manner.'
  },
  {
    right: 'Right to Restrict or Object to Processing',
    details:
      'Object to certain types of processing of your Personal Data, including profiling, targeted advertising, direct marketing, and statistical, scientific, or historical research purposes.'
  },
  {
    right: 'Right to not be Subject to Fully Automated Decisions',
    details:
      'Ask to not be subject to decisions with a legal or similarly significant effect (including profiling) that are based solely on the automated processing of your Personal Data, unless you have given us your explicit consent or where necessary for the performance of a contract with us.'
  },
  {
    right: 'Right to Limit Use of Sensitive Information',
    details: 'Tell us to limit or stop processing your Sensitive Personal Data.'
  },
  {
    right: 'Right to Withdraw Consent at Any Time',
    details: 'Withdraw any consent you may have previously given us.'
  },
  {
    right: 'Right to Delete, also known as the Right to be Forgotten',
    details:
      'Request that your Personal Data be erased. Where required, we will delete your Personal Data. We will decline your request for deletion if processing of your Personal Data is necessary: (i) for us to comply with our legal obligations; (ii) for the establishment, exercise, or defense of legal claims; (iii) for the performance of a task in the public interest, or (iv) for us to perform certain actions in accordance with applicable laws, such as detecting security incidents and protecting against fraudulent activity.'
  },
  {
    right: 'Right to Opt-Out of the Sale or Sharing of your Personal Data',
    details:
      'Direct us not to sell your Personal Data to third parties. California residents: You have the right to tell us not to sell or share your Personal Data to third parties. This right is referred to as the “right to opt-out of sale or sharing.”'
  }
];
