import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ActionKind,
  ActionStructureMultipleChoiceKind,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import {
  segment_special_tile,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { ActionsService, beforeModalDismiss } from '../actions.service';
import { DeviceService } from '@suzy/shared/tools/device';
import { Mission } from '../mission.model';
import { TranslateService } from '@ngx-translate/core';
import { MissionService } from '@suzy/shared/tools/mission';
import { PreviewService } from '../../preview/preview.service';
import { SettingsService } from '../../../core/services/settings.service';
import { SkipModalComponent } from '../skip-modal/skip-modal.component';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { MissionKind } from '@asksuzy/typescript-sdk';
import { FlagMissionMoadlComponent } from '../flag-mission-modal/flag-mission-modal.component';
import { SpecialTileService } from '@suzy/shared/special-tile';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})
export class CoverComponent implements OnInit {
  @Input() title: string;
  description: string;
  mission: any;
  action: any;
  kind: number;
  disableSkip: boolean;
  missionKind = MissionKind;
  actionType: string;
  isLoading = false;
  isSkipping = false;
  actionService: ActionsService;
  isGlobal: boolean;
  isExternal: boolean;
  activeColor: any;
  actionKindString: string;
  roundValue: number;
  modalSettings: any;
  isPreview: boolean;
  isDarkMode: boolean;
  readonly SpecialTileType = segment_special_tile;
  isShowTimeOnTileFlagOn = false;
  showFlagIcon?: boolean;
  specialTileName: string;

  constructor(
    private renderer: Renderer2,
    private modals: NgbModal,
    public activeModal: NgbActiveModal,
    private sdk: SuzySdkService,
    private router: Router,
    private segmentService: SegmentService,
    private deviceService: DeviceService,
    private translate: TranslateService,
    private hostElement: ElementRef,
    private missionService: MissionService,
    private previewService: PreviewService,
    private settingsService: SettingsService,
    private launchDarklyService: LaunchDarklyService,
    private modalService: NgbModal,
    private specialTileService: SpecialTileService
  ) {
    this.isGlobal = this.settingsService.isGlobalApp();
    this.isExternal = this.settingsService.isExternalApp();
  }

  ngOnInit(): void {
    this.previewService.disablePreviewControls = false;
    this.actionKindString =
      this.mission.mission_kind === MissionKind.standard
        ? this.getTitleByActionType(this.mission)
        : this.getTitleByMissionType(this.mission);
    let screenName = this.deviceService.getDevice();
    this.roundValue = 10;
    this.activeColor = this.mission.color ? this.mission.color : 1;
    this.renderer.addClass(document.body, 'isActionModal');

    if (this.mission.prevent_skip) {
      this.disableSkip = this.mission.prevent_skip;
    }

    switch (this.kind) {
      case this.missionKind.survey:
      case this.missionKind.splittesting:
        this.actionType = 'survey';
        break;
      case this.missionKind.sharing:
        this.actionType = 'sharing';
        break;
      case this.missionKind.screening:
        this.actionType = 'screener';
        break;
      case this.missionKind.maxdiff:
        this.actionType = 'maxdiff';
        break;
      default:
        this.actionType = '';
    }
    if (this.isGlobal) {
      this.getStarted();
    }
    this.isPreview = document.body.classList.contains('isPreview');
    this.settingsService.isDarkModeSelected$.subscribe(value => {
      this.isDarkMode = value;
    });

    this.isShowTimeOnTileFlagOn =
      this.launchDarklyService.getCWDT2108And2417ShowTimeOnTiles();

    this.showFlagIcon =
      !this.isPreview &&
      !this.isGlobal &&
      !this.isExternal &&
      this.launchDarklyService.getCWDT2642And2646AbilityToFlagMissions();

    this.specialTileName = this.specialTileService.getSpecialTileName(
      this.mission?.external_json
    );
  }

  openSkipModal(): void {
    const modalClass = `modal-${new Date().getTime()}`;
    let modal;
    modal = this.modals.open(SkipModalComponent, {
      windowClass: modalClass,
      beforeDismiss: () => {
        return beforeModalDismiss(modalClass, 'skip');
      }
    });
    const instance = modal.componentInstance as SkipModalComponent;
    instance.kind = this.mission.mission_kind;
    instance.mission = this.mission;
    instance.action = this.mission.first_action;
    instance.actionService = this.actionService;
  }

  getTitleByActionType(mission: Mission): string {
    switch (mission.first_action.action_kind) {
      case ActionKind.multiplechoice:
        if (
          mission.first_action.multiple_choice?.multiple_choice_kind ===
          ActionStructureMultipleChoiceKind.monadic
        ) {
          return 'Rating';
        } else {
          return this.translate.instant('multipleChoice.multipleChoice');
        }
      case ActionKind.openended:
        return this.translate.instant('openEnded.openEnded');
      case ActionKind.photoacquisition:
        return 'Photo';
      case ActionKind.grid:
        return 'Grid';
      case ActionKind.gridcustom:
        return this.translate.instant('grid.customGrid');
      case ActionKind.gridrankscale:
        return 'Scale/Rank';
      default:
        console.error(
          `Action doesn't not support action kind ${mission.first_action.action_kind}`
        );
        break;
    }
  }

  getTitleByMissionType(mission: Mission): string {
    switch (mission.mission_kind) {
      case MissionKind.splittesting:
      case MissionKind.survey:
        return this.translate.instant('survey.survey');
      case MissionKind.screening:
        return this.translate.instant('survey.survey');
      case MissionKind.maxdiff:
        return this.translate.instant('survey.survey');
      case MissionKind.wave:
        return this.translate.instant('survey.survey');
      default:
        console.error(
          `Action doesn't not support mission kind ${mission.mission_kind}`
        );
        break;
    }
  }

  getStarted(): any {
    // this only tracks missions with covers
    this.isLoading = true;
    const wrapper = this.hostElement.nativeElement.querySelector(
      '.action-modal-container'
    );
    wrapper.classList.add('is-hidden');
    if (this.isPreview) {
      this.isLoading = false;
      this.activeModal.dismiss();

      this.previewService.trackMissionStarted(
        'Preview Mission Started',
        this.mission,
        'preview_cover_button'
      );
      setTimeout(() => {
        this.actionService
          .doAction(this.mission, this.action, undefined, this.modalSettings)
          .subscribe(result => {
            this.isLoading = false;
            this.actionService.setModalClosing(this.mission.mission_id);
            this.activeModal.close(result);
          });
      }, 500);
    } else {
      setTimeout(() => {
        if (
          this.mission.first_action.action_kind === ActionKind.maxdiff ||
          this.mission.mission_kind === MissionKind.maxdiff
        ) {
          this.sdk.ProtocolRespond.getForPerforming({
            brand_id: this.mission.brand_id,
            mission_id: this.mission.mission_id
          }).subscribe(result => {
            if (result.success && result.item.pending_phases.length > 0) {
              this.action =
                result.item.pending_phases[0].pending_steps[0].pending_actions[0];
              this.action.ui = {
                title: this.mission.mission_caption,
                cssClass: 'card-action__survey'
              };
              this.actionService
                .doAction(this.mission, this.action)
                .subscribe(result => {
                  this.isLoading = false;
                  this.actionService.setModalClosing(this.mission.mission_id);
                  this.activeModal.close(result);
                });
            }
          });
        } else {
          this.actionService
            .doAction(this.mission, this.action)
            .subscribe(result => {
              this.isLoading = false;
              this.actionService.setModalClosing(this.mission.mission_id);
              this.activeModal.close(result);
            });
        }
      }, 500);
    }
  }

  closeModal() {
    this.missionService.setMissionOpen(false);
    this.activeModal.dismiss();
  }

  get timeToAnswer(): number {
    return this.mission.time_to_answer_secs / 60;
  }

  onFlagClick(): void {
    const flagModal = this.modalService.open(FlagMissionMoadlComponent, {
      centered: true,
      windowClass: 'general-modal',
      modalDialogClass: 'flag-mission-modal'
    });
    flagModal.componentInstance.mission = this.mission;

    flagModal.result.then(() => {
      this.actionService
        .skipAction(this.action, this.mission)
        .subscribe(result => {
          this.segmentService.trackMission('Mission Skipped', {
            action_count: this.mission.action_count,
            brand_id: this.mission.brand_id,
            mission_id: this.mission.mission_id,
            points_available: this.mission.points,
            mission_kind: this.kind,
            has_cover: true,
            monadic_variant_quantity: this.mission.monadic_variants
              ? this.mission.monadic_variants.length
              : undefined,
            tag: this.specialTileName,
            weight: this.mission.weight
          });
          if (result) {
            this.mission['status'] = true;
            this.mission['answer_status'] = 'skipped';
            this.activeModal.close();
          }
        });
    });
  }
}
