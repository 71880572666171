import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LegalServices } from '../../../core/services/legal.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalComponent implements OnInit, AfterViewInit {
  @ViewChild('backToTopLink', { static: true }) backToTopLink: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  el_backToTopLink: HTMLElement;
  el_wrapper: HTMLElement;
  legalList: Array<any>;
  legalData: any;
  title: string;
  policyLabel: string;
  policyVersion: string;
  skipLinkPathContent: string;
  skipLinkPathFooter: string;
  showMenu: boolean;
  activeTab: any;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private legalService: LegalServices,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setHeadTitle();
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;
    this.legalList = this.legalService.getLegalList();
    this.policyLabel = this.route.snapshot.data['policy'];
    this.policyVersion = this.route.snapshot.params['version'];
    this.title = this.route.snapshot.data['title'];
    window.scrollTo(0, 0);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateActiveTab();
      });

    // Set initial active tab
    this.updateActiveTab();
  }

  // Function to determine and save the active tab
  updateActiveTab() {
    const currentUrl = this.router.url.split('#')[0]; // Get URL without fragment
    const currentFragment = this.router.url.split('#')[1] || null; // Get fragment if exists

    const activeItem = this.legalList.find(item => {
      const matchesUrl = item.linkUrl === currentUrl;
      const matchesFragment = item.fragment === currentFragment;
      return matchesUrl && (!item.fragment || matchesFragment);
    });

    this.activeTab = activeItem ? activeItem.linkText : null; // Save the active tab's name
  }

  ngAfterViewInit(): void {
    this.el_backToTopLink = this.backToTopLink.nativeElement as HTMLElement;
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
    this.el_backToTopLink = this.backToTopLink.nativeElement as HTMLElement;
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
  }

  setHeadTitle(): any {
    this.titleService.setTitle(this.title);
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }
}
