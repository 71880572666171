import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  OnboardingStatus,
  SuzySdkService
} from '@suzy/shared/data-access/suzy-sdk';
import {
  AuthenticationService,
  UserVerificationStatus
} from '@suzy/crowdtap/data-access/user';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CampaignKeysName,
  PanelKeysName,
  PromoKeys,
  ReferralKeysName,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { Title } from '@angular/platform-browser';
import swal from 'sweetalert2';
import { GrecaptchaService } from '../../../core/services/grecaptcha.service';
import { SignupService } from '../signup/signup.service';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('passwordFields', { static: true }) passwordFields: ElementRef;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;

  el_passwordFields: HTMLElement;
  el_wrapper: HTMLElement;
  campaignKeysName: CampaignKeysName;
  eyeView: boolean;
  currentPanelKeys: any;
  currentPanelToken: any;
  currentSource: any;
  isChange = false;
  isLoading: boolean;
  isInvalid = false;
  isInvalidCredentials = false;
  loginForm: UntypedFormGroup;
  newPanelToken: any;
  panelKeysName: PanelKeysName;
  recaptchaToken: string;
  sourceUrl: string;
  subject: Subject<any>;
  submitted = false;
  currentCampaignKeys: any;
  currentReferralKeys: any;
  currentReferralToken: any;
  currentPromoKeys: any;
  currentPromoToken: any;
  grecaptchaToken: string;
  referralKeysName: ReferralKeysName;
  promoKeysName: PromoKeys;
  newReferralToken: any;
  newPromoToken: any;
  isMobile$ = new BehaviorSubject<boolean>(false);
  unsubscribe$ = new Subject<void>();
  skipLinkPathContent: string;
  skipLinkPathFooter: string;
  isLocked = false;
  lockExpires: Date | undefined;
  lockTimer: string | undefined;
  isSmsFirstFlowSignUp = false;
  isVerifyEmailFlow = false;

  constructor(
    private fb: UntypedFormBuilder,
    private sdk: SuzySdkService,
    private auth: AuthenticationService,
    private router: Router,
    private gtm: Angulartics2GoogleTagManager,
    private grecaptchaService: GrecaptchaService,
    private route: ActivatedRoute,
    private titleService: Title,
    private segmentService: SegmentService,
    private signupService: SignupService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  ngOnInit(): void {
    this.isSmsFirstFlowSignUp =
      this.launchDarklyService.getCWDT2328MoveSMSQuestionFlag();
    this.isVerifyEmailFlow =
      this.launchDarklyService.getRW74And76AbilityToSignUpWithAMagicLink();
    this.setHeadTitle();
    this.skipLinkPathContent = `${this.router.url}#main-content`;
    this.skipLinkPathFooter = `${this.router.url}#main-footer`;
    this.createForm();
    this.isMobile$.next(this.router.url.includes('mobile'));
    this.el_wrapper = this.wrapper.nativeElement as HTMLElement;
    this.grecaptchaService.execute('login', token => {
      this.recaptchaToken = token;
    });
    this.subject = new Subject<any>();
    this.checkPanelToken();
    this.checkReferralToken();
  }

  ngAfterViewInit(): void {
    this.el_passwordFields = this.passwordFields.nativeElement as HTMLElement;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setHeadTitle(): any {
    this.titleService.setTitle('Crowdtap® - Login');
  }

  createForm(): void {
    this.loginForm = this.fb.group({
      user: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  fieldErrors(name: string): any {
    const login_fields = this.loginForm.get(name);

    if (
      login_fields &&
      (login_fields.touched || this.submitted) &&
      login_fields.errors
    ) {
      return login_fields.errors;
    } else {
      this.isInvalid = false;

      return undefined;
    }
  }

  isFieldTouched(field): boolean {
    return this.loginForm.get(field).touched;
  }

  hasValue(field): boolean {
    return this.loginForm.get(field).value;
  }

  hideSelf(): void {
    this.isInvalid = false;
  }

  onChangeHandler(e): void {
    this.checkInput(e);
  }

  checkInput(e): void {
    if (e.target.value.length > 0) {
      this.el_passwordFields.classList.add('ng-changed');
      this.isChange = true;
    } else {
      this.el_passwordFields.classList.remove('ng-changed');
      this.isChange = false;
    }
  }

  toggleVisibility(e): void {
    if (e.target.nextElementSibling.type === 'password') {
      e.target.nextElementSibling.type = 'text';
      e.target.classList.add('isVisible');
    } else {
      e.target.nextElementSibling.type = 'password';
      e.target.classList.remove('isVisible');
    }
  }

  getReturnUrl(): string {
    return this.isMobile$.getValue()
      ? this.route.snapshot.queryParams['returnUrl'] ||
          this.auth.getMobileRouting()
      : this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  checkPanelToken(): void {
    this.panelKeysName = new PanelKeysName();
    this.currentPanelToken = this.auth.getLocalKey(
      this.panelKeysName.panelToken
    );

    if (!this.currentPanelToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.panelKeysName.panelToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.auth.setLocalKey(prop, params[prop]);
            }
          }
          this.currentPanelKeys = this.auth.getCurrentPanelKeys();
        }
      });
    } else {
      this.currentPanelKeys = this.auth.getCurrentPanelKeys();
    }
  }

  checkReferralToken(): void {
    this.referralKeysName = new ReferralKeysName();
    this.currentReferralToken = this.auth.getLocalKey(
      this.referralKeysName.referralToken
    );

    if (!this.currentReferralToken) {
      this.route.queryParams.subscribe(params => {
        if (params[this.referralKeysName.referralToken]) {
          for (const prop in params) {
            if (params[prop]) {
              this.auth.setLocalKey(prop, params[prop]);
            }
          }
          this.currentReferralKeys = this.auth.getCurrentReferralKeys();
        }
      });
    } else {
      this.currentReferralKeys = this.auth.getCurrentReferralKeys();
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    const user = this.loginForm.value;
    user.brands = false;
    user.recaptcha_token = this.recaptchaToken;
    user.panel_token = this.currentPanelKeys
      ? this.currentPanelKeys.panelToken
      : undefined;
    user.referral_token = this.currentReferralKeys
      ? this.currentReferralKeys.referralToken
      : undefined;
    user.promotion_token = this.currentPromoKeys
      ? this.currentPromoKeys.promoToken
      : undefined;

    this.isLoading = true;
    this.isInvalid = false;
    this.isInvalidCredentials = false;
    this.sdk.ProtocolAuthorize.login(user).subscribe(
      data => {
        if (data.success) {
          const loggedInUserInfo = data.item;
          const isOnboarding =
            data.item.onboarding_status === OnboardingStatus.onboarding;
          const isGrandfathered =
            data.item.onboarding_status === OnboardingStatus.grandfathered;
          this.auth.login(data.item);
          this.gtm.setUsername(data.item.user_id);
          const userInformation = {
            ...data.item,
            is_fb_registered: false,
            is_email_registered: true
          };
          delete userInformation.api_key;
          delete userInformation.api_secret;
          this.gtm.eventTrack('js-home-login-auth-success', {
            event: 'js-home-login-auth-success',
            gtmCustom: {
              userInformation
            }
          });

          if (this.isVerifyEmailFlow) {
            this.signupService.setUserFromLoggedInUser(data.item);
            const userVerificationStatus =
              this.auth.getUserVerificationStatus(loggedInUserInfo);
            if (
              userVerificationStatus === UserVerificationStatus.EMAIL_CONFIRMED
            ) {
              this.router.navigate(['/auth/email-sent']);
            } else if (
              userVerificationStatus ===
              UserVerificationStatus.PHONE_NOT_VERIFIED
            ) {
              this.router.navigate(['/auth/get-verified']);
            } else {
              this.redirectUser();
            }
          } else if (this.isSmsFirstFlowSignUp) {
            if (!loggedInUserInfo.phone_verified_utc) {
              this.signupService.setUserFromLoggedInUser(data.item);
              this.router.navigate(['/auth/get-verified']);
            } else if (
              (isOnboarding || isGrandfathered) &&
              this.isMissingSignupStep(
                'birthdate',
                loggedInUserInfo.profile.completion_missing
              )
            ) {
              this.signupService.setUserFromLoggedInUser(data.item);
              this.router.navigate(['/auth/get-to-know-you']);
            } else if (
              (isOnboarding || isGrandfathered) &&
              this.isMissingSignupStep(
                'employment',
                loggedInUserInfo.profile.completion_optional_missing
              )
            ) {
              this.signupService.setUserFromLoggedInUser(data.item);
              this.router.navigate(['/auth/about-yourself']);
            } else if (
              (isOnboarding || isGrandfathered) &&
              this.isMissingSignupStep(
                'city',
                loggedInUserInfo.profile.completion_missing
              )
            ) {
              this.signupService.setUserFromLoggedInUser(data.item);
              this.router.navigate(['/auth/almost-there']);
            } else {
              this.redirectUser();
            }
          } else {
            this.redirectUser();
          }
        } else {
          this.gtm.eventTrack('js-home-login-auth-failed', {
            event: 'js-home-login-auth-failed',
            gtmCustom: {
              error: data.message
            }
          });
          this.segmentService.trackAuthError('Sign In Error', {
            error_message: data.message,
            error_source: 'backend',
            error_ui_message: data.message,
            method: 'email'
          });

          if (data.item && data.item.is_locked_out) {
            this.isLocked = true;
            this.lockExpires = new Date(data.item.locked_out_expire_utc);

            this.lockTimer = this.calcLockTime();
            let lockInterval = setInterval(() => {
              this.lockTimer = this.calcLockTime();
              if (!this.isLocked) {
                clearInterval(lockInterval);
              }
            }, 1000);
          }

          if (
            data.message_token &&
            (data.message_token === 'Auth.Login.banned' ||
              data.message_token === 'Auth.Login.disabled')
          ) {
            this.router.navigate(['/auth/access-denied'], {
              queryParams: { reason: data.message_token }
            });
          } else {
            swal.fire({
              buttonsStyling: false,
              confirmButtonText: 'Continue',
              confirmButtonClass: 'btn-confirm',
              customClass: 'general-modal',
              showConfirmButton: true,
              text: data.message,
              title: 'Error',
              type: 'warning'
            });
          }
        }
        this.isLoading = false;
      },
      error => {
        const e_invalidEntry = 'Invalid password/user combination';
        const e_accessDenied = 'This account has been disabled.';
        const e_accessBanned = 'This account has been blocked.';

        if (error.trim() === e_invalidEntry) {
          this.isInvalidCredentials = true;
        } else if (
          error.trim() === e_accessDenied ||
          error.trim() === e_accessBanned
        ) {
          this.isInvalidCredentials = false;
          this.router.navigate(['/auth/access-denied']);
        }

        this.gtm.eventTrack('js-home-login-auth-failed', {
          event: 'js-home-login-auth-failed',
          gtmCustom: {
            error
          }
        });
        this.segmentService.trackAuthError('Sign In Error', {
          error_message: error,
          error_source: 'backend',
          error_ui_message: error,
          method: 'email'
        });
        this.isLoading = false;
      }
    );
  }

  isMissingSignupStep(step: string, array: any[]): boolean {
    return array.some(item => item === step);
  }

  calcLockTime(): string {
    if (!this.lockExpires || this.lockExpires.getTime() < Date.now()) {
      this.isLocked = false;

      return '0s';
    }

    const ms = this.lockExpires.getTime() - Date.now();
    const s = Math.floor(ms / 1000);
    const m = Math.floor(s / 60);
    const h = Math.floor(m / 60);
    const days = Math.floor(h / 24);
    const hours = h % 24;
    const mins = m % 60;
    const secs = s % 60;

    let remaining = '';
    if (days) {
      remaining += days.toString() + 'd ';
    }
    if (remaining || hours) {
      remaining += hours.toString() + 'h ';
    }
    if (remaining || mins) {
      remaining += mins.toString() + 'm ';
    }
    if (remaining || secs) {
      remaining += secs.toString() + 's';
    }

    return remaining;
  }

  onBackToTop(): void {
    this.el_wrapper.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  }

  onOpenSignUpModal(displayText: string): void {
    this.segmentService.track('Sign Up Methods Viewed');
    this.subject.next(displayText);
  }

  redirectUser(): void {
    this.router.navigateByUrl(this.getReturnUrl());

    const redirectUrl = this.getReturnUrl();

    if (redirectUrl === '/close-browser/mobile') {
      window.location.href = redirectUrl;
    } else {
      this.router.navigate([redirectUrl]);
    }
  }
}
