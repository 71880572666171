<div #wrapper>
  <a [href]="skipLinkPathContent" class="sr-only">Skip to main content</a>
  <app-general-header viewKind="legal"></app-general-header>
  <div class="legal dark:text-white">
    <!-- Local Nav Starts -->
    <div class="hidden lg:flex flex-col items-center py-4">
      <h1 class="text-3.5xl font-bold p-0">Crowdtap® {{ activeTab }}</h1>
      <div class="flex justify-center">
        <svg
          width="64"
          height="17"
          viewBox="0 0 64 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 2014">
            <rect
              id="Rectangle 1"
              x="24"
              y="0.209999"
              width="16"
              height="16"
              rx="2"
              fill="#AC6DFD"
            />
            <rect
              id="Rectangle 3"
              x="48"
              y="0.209999"
              width="16"
              height="16"
              rx="2"
              fill="#19A9A5"
            />
            <rect
              id="Rectangle 2"
              y="0.209999"
              width="16"
              height="16"
              rx="2"
              fill="#3589F9"
            />
          </g>
        </svg>
      </div>
    </div>
    <div class="legal-header flex items-center lg:hidden z-10 w-full pt-5">
      <p class="text-xl font-bold mb-0 leading-1.5">{{ activeTab }}</p>
      <a
        class="hamburger z-10 ml-auto"
        [ngClass]="{ 'is-active': showMenu }"
        (click)="showMenu = !showMenu"
      >
        <div class="bar--1 bg-darkBlack dark:bg-light-white"></div>
        <div class="bar--2 bg-darkBlack dark:bg-light-white"></div>
        <div class="bar--3 bg-darkBlack dark:bg-light-white"></div>
      </a>
      <div [ngClass]="{ active: showMenu }">
        <ul class="show-menu bg-body-light dark:bg-body-dark">
          <p class="text-lg font-bold mt-6 pl-6">Navigation</p>
          <li
            *ngFor="let listItem of legalList"
            class="dark:text-light-white text-light-black"
            [routerLinkActive]="listItem.fragment ? '' : 'active'"
          >
            <a
              [routerLink]="listItem.linkUrl"
              [fragment]="listItem.fragment"
              routerLinkActive="active"
              >{{ listItem.linkName | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="hidden lg:block">
      <nav>
        <ul class="flex">
          <li
            *ngFor="let listItem of legalList"
            class="flex items-end justify-center text-center w-full border-b-2 border-gre py-2"
            [routerLinkActive]="
              listItem.fragment
                ? ''
                : 'active border-b-4 border-active-link-dark border-blue-dark'
            "
          >
            <a
              [routerLink]="listItem.linkUrl"
              [fragment]="listItem.fragment"
              routerLinkActive="active"
              class="text-sm font-bold text-black dark:text-white hover:opacity-75"
              >{{ listItem.linkName | translate }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <!-- Local Nav Ends -->

    <!-- Main Content Starts -->
    <div
      class="main-content mt-1"
      aria-label="main"
      role="main"
      id="main-content"
    >
      <a [href]="skipLinkPathFooter" class="sr-only">Skip to footer</a>
      <section class="legal-copy">
        <!-- Copy Starts -->
        <ng-container *ngIf="policyLabel === 'privacy-policy'">
          <privacy-policy-v20230719
            *ngIf="policyVersion === '2023-07-19'"
          ></privacy-policy-v20230719>
          <privacy-policy-v20210223
            *ngIf="policyVersion === '2021-02-23'"
          ></privacy-policy-v20210223>
          <privacy-policy-v20220915
            *ngIf="policyVersion === '2021-09-15'"
          ></privacy-policy-v20220915>
          <privacy-policy-current
            *ngIf="policyVersion === undefined"
          ></privacy-policy-current>
        </ng-container>
        <ng-container
          *ngIf="policyLabel === 'notice-of-financial-incentive-policy'"
        >
          <notice-of-financial-incentive-policy-current></notice-of-financial-incentive-policy-current>
        </ng-container>
        <ng-container *ngIf="policyLabel === 'cookie-policy'">
          <cookie-policy-v20190119
            *ngIf="policyVersion === '2019-01-19'"
          ></cookie-policy-v20190119>
          <cookie-policy-v20220915
            *ngIf="policyVersion === '2022-09-15'"
          ></cookie-policy-v20220915>
          <cookie-policy-current
            *ngIf="policyVersion === undefined"
          ></cookie-policy-current>
        </ng-container>

        <ng-container *ngIf="policyLabel === 'biometric-policy'">
          <biometric-policy-v20220912
            *ngIf="policyVersion === '2022-09-12'"
          ></biometric-policy-v20220912>
          <biometric-policy-v20230719
            *ngIf="policyVersion === '2023-07-19'"
          ></biometric-policy-v20230719>
          <biometric-policy-current
            *ngIf="policyVersion === undefined"
          ></biometric-policy-current>
        </ng-container>
        <ng-container *ngIf="policyLabel === 'terms-of-service-policy'">
          <terms-of-service-policy-current></terms-of-service-policy-current>
        </ng-container>
        <ng-container *ngIf="policyLabel === 'accessibility-statement'">
          <accessibility-statement-policy-current></accessibility-statement-policy-current>
        </ng-container>

        <!-- Copy Ends -->
      </section>

      <section class="support-question">
        <div class="title">
          <svg
            width="20"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.29 13.29a1.575 1.575 0 0 0-.12.15.761.761 0 0 0-.09.18.64.64 0 0 0-.06.18 1.36 1.36 0 0 0 0 .2.84.84 0 0 0 .08.38.9.9 0 0 0 .54.54.94.94 0 0 0 .76 0 .9.9 0 0 0 .54-.54A1 1 0 0 0 11 14a1 1 0 0 0-1.71-.71ZM10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 10 0Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-13a3 3 0 0 0-2.6 1.5 1 1 0 1 0 1.73 1A1 1 0 0 1 10 7a1 1 0 1 1 0 2 1 1 0 0 0-1 1v1a1 1 0 0 0 2 0v-.18A3 3 0 0 0 10 5Z"
            />
          </svg>
          <h2>{{ 'support.supportAndQuestions' | translate }}</h2>
        </div>
        <p>
          {{ 'support.respondToQuestions' | translate }}
        </p>
        <p>
          {{ 'support.whatYoureLookingFor' | translate }}
          <a
            href="mailto:support@crowdtap.com?subject=Privacy%20Policy%20Inquiry"
            >{{ 'general.contactUs' | translate }}</a
          >.
        </p>
      </section>

      <section class="back-to-top-link">
        <a (click)="onBackToTop()" #backToTopLink>
          <svg
            width="16"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.71 4.29a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-4 4a1.004 1.004 0 1 0 1.42 1.42L7 7.41V19a1 1 0 1 0 2 0V7.41l2.29 2.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-4-4ZM15 0H1a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2Z"
            />
          </svg>
          {{ 'nav.backToTop' | translate }}
        </a>
      </section>
    </div>
    <!-- Main Content Ends -->
  </div>
  <app-general-footer
    id="main-footer"
    [footerPosition]="'relative'"
    (backToTop)="onBackToTop()"
  >
  </app-general-footer>
</div>
