export const TableOfContent = [
  { id: 'who-are-we', label: '1. Who are we?' },
  { id: 'who-are-you', label: '2. Who are "you"?' },
  { id: 'what-is-personal-data', label: '3. What is Personal Data?' },
  {
    id: 'who-is-the-data-controller',
    label: '4. Who is the data controller?'
  },
  {
    id: 'how-is-personal-data-collected',
    label: '5. How is Personal Data collected?'
  },
  {
    id: 'what-personal-data-do-we-use',
    label: '6. What Personal Data do we use?'
  },
  {
    id: 'feature-specific-personal-data',
    label: '7. Feature-specific Personal Data'
  },
  {
    id: 'what-about-artificial-intelligence',
    label: '8. What about Artificial Intelligence?'
  },
  {
    id: 'who-do-we-disclose-sell-or-share-your-personal-data-to',
    label: '9. Who do we disclose, sell, or share your Personal Data to?'
  },
  {
    id: 'are-survey-responses-anonymous',
    label: '10. Are survey responses anonymous?'
  },
  {
    id: 'how-long-is-personal-data-retained',
    label: '11. How long is Personal Data retained?'
  },
  {
    id: 'where-is-personal-data-processed-and-stored',
    label: '12. Where is Personal Data processed and stored?'
  },
  { id: 'security', label: '13. Security' },
  { id: 'minors', label: '14. Minors' },
  { id: 'links-to-other-websites', label: '15. Links to other websites' },
  {
    id: 'bulletin-boards-and-chat-areas',
    label: '16. Bulletin boards and chat areas'
  },
  {
    id: 'notice-of-financial-incentive',
    label: '17. Notice of Financial Incentive'
  },
  { id: 'your-privacy-rights', label: '18. Your privacy rights' },
  {
    id: 'exercising-your-privacy-rights',
    label: '19. Exercising your privacy rights'
  },
  { id: 'your-privacy-controls', label: '20. Your privacy controls' },
  { id: 'how-can-you-contact-us', label: '21. How can you contact us?' },
  { id: 'changes-to-this-policy', label: '22. Changes to this Policy' },
  {
    id: 'prior-versions-of-this-policy',
    label: '23. Prior Versions of this Policy'
  }
];
