import { NgModule } from '@angular/core';
import { OpenEndedComponent } from './openended/open-ended.component';
import { ActionsService } from './actions.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultipleChoiceComponent } from './multiple-choice/multiple-choice.component';
import { PhotoAcquisitionComponent } from './photo-acquisition/photo-acquisition.component';
import { SharedModule } from '../../shared/shared.module';
import { GridOpenComponent } from './grid-open/grid-open.component';
import { GridScaleComponent } from './grid-scale/grid-scale.component';
import { MaxDiffComponent } from './maxDiff/maxDiff.component';
import { GridRankComponent } from './grid-rank/grid-rank.component';
import { ActionModalHeaderComponent } from './action-modal-header/action-modal-header.component';
import { TooltipInfoComponent } from './tooltip-info/tooltip-info.component';

import { ActionLoaderComponent } from './action-loader/action-loader.component';
import { ActionsResolverService } from './actions-resolver.service';
import { AddressComponent } from './address/address.component';
import { AcceptComponent } from './accept/accept.component';
import { CoverComponent } from './cover/cover.component';
import { SkipModalComponent } from './skip-modal/skip-modal.component';
import { EndingComponent } from './ending/ending.component';
import { PhotoShareComponent } from './photo-share/photo-share.component';
import { ShareSocialMediaComponent } from './share-social-media/share-social-media.component';
import { BazaarvoiceReviewComponent } from './bazaarvoice-review/bazaarvoice-review.component';
import { TextShareComponent } from '../actions/text-share/text-share.component';
import { LinkShareComponent } from '../actions/link-share/link-share.component';
import { DragulaModule } from 'ng2-dragula';
import { NgDragDropModule } from 'ng-drag-drop';
import { RouterModule } from '@angular/router';
import { TranslatePipe, TranslateModule } from '@ngx-translate/core';
import { SharedActionsFeaturePrerollModule } from '@suzy/shared/actions/feature-preroll';
import { SharedActionsUiActionFormsModule } from '@suzy/shared/actions/ui-action-forms';
import { EndingLoaderComponent } from './ending-loader/ending-loader.component';
import { GlobalEndingComponent } from './global-ending/global-ending.component';
import { PreviewService } from '../preview/preview.service';
import { WarningInfoComponent } from './shared/warning-info/warning-info';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { TurfComponent } from './turf/turf.component';
import { ArabicNumberPipe } from 'libs/shared/tools/url/src/lib/convert-to-eastern-arabic-number.pipe';
import { FlagMissionMoadlComponent } from './flag-mission-modal/flag-mission-modal.component';

export function playerFactory() {
  return player;
}
@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    NgbModule,
    DragulaModule,
    SharedActionsFeaturePrerollModule,
    SharedActionsUiActionFormsModule,
    NgDragDropModule.forRoot(),
    TranslateModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [
    OpenEndedComponent,
    MultipleChoiceComponent,
    PhotoAcquisitionComponent,
    GridOpenComponent,
    GridScaleComponent,
    MaxDiffComponent,
    GridRankComponent,
    GlobalEndingComponent,
    EndingLoaderComponent,
    FlagMissionMoadlComponent
  ],
  declarations: [
    OpenEndedComponent,
    MultipleChoiceComponent,
    PhotoAcquisitionComponent,
    GridOpenComponent,
    GridScaleComponent,
    MaxDiffComponent,
    GridRankComponent,
    ActionModalHeaderComponent,
    TooltipInfoComponent,
    ActionLoaderComponent,
    AddressComponent,
    AcceptComponent,
    CoverComponent,
    SkipModalComponent,
    EndingComponent,
    PhotoShareComponent,
    TextShareComponent,
    ShareSocialMediaComponent,
    LinkShareComponent,
    BazaarvoiceReviewComponent,
    EndingLoaderComponent,
    GlobalEndingComponent,
    WarningInfoComponent,
    TurfComponent,
    FlagMissionMoadlComponent
  ],
  providers: [
    ActionsService,
    ActionsResolverService,
    TranslatePipe,
    PreviewService,
    ArabicNumberPipe
  ]
})
export class ActionsModule {}
