import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';

@Injectable()
export class CurrentUserResolver implements Resolve<Observable<any>> {
  constructor(private auth: AuthenticationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.auth.ensureUser(true);
  }
}
