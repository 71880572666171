import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { environment } from '../../../../environments/environment';
import { CountUpdateService } from '../../../core/services/count-update.service';
import { StoreService } from '../../../core/services/store.service';
import { VerificationStatus } from '@asksuzy/typescript-sdk';

@Component({
  selector: 'recaptcha-modal',
  templateUrl: './recaptcha-modal.component.html',
  styleUrls: ['./recaptcha-modal.component.scss']
})
export class RecaptchaModalComponent implements OnInit {
  @Input() isModal = true;
  @Input() isMobile: boolean;
  @Input() currentView: string = 'redeemPoints';

  verificationError: boolean;
  verificationStep: number;
  counter = 300;
  minutes = 5;
  seconds = 0;
  siteKey = environment.grecaptchaV2Token;

  constructor(
    private modal: NgbActiveModal,
    private suzySDK: SuzySdkService,
    private router: Router,
    private countUpdate: CountUpdateService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.verificationStep = 1;
  }

  resolved(captchaResponse: string, res): void {
    this.verificationStep = 2;
    this.suzySDK.ProtocolRegister.requestVerificationCaptcha({
      token: captchaResponse
    }).subscribe(
      res => {
        if (res.item?.verification_status === VerificationStatus.approved) {
          this.storeService.reCaptchaAction$.next(true);
          this.countUpdate.updateCount({
            last_reward_utc: '',
            total_points: res.detail.user_total_points
          });
          this.modal.close(res.item.verification_status);
        } else {
          this.verificationStep = 3;
        }
      },
      err => {
        this.verificationError = true;
        this.verificationCountdown();
      }
    );
  }

  verificationCountdown(): void {
    let intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      this.minutes = Math.floor(this.counter / 60);
      this.seconds = this.counter - this.minutes * 60;
      if (this.counter === 0) {
        clearInterval(intervalId);
        this.verificationStep = 1;
        this.verificationError = false;
      }
    }, 1000);
  }

  close(route: string): void {
    if (this.isModal) {
      this.modal.dismiss(route);
      if (route === 'profile') {
        this.router.navigateByUrl('profile/update');
      } else if (route === 'dashboard' || route === 'redeem') {
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
      }
    } else {
      if (this.isMobile && route === 'profile') {
        window.location.href = 'profile/update/mobile';
      } else if (this.isMobile && route === 'home') {
        window.location.href = 'close-browser/mobile';
      } else {
        if (route === 'profile') {
          this.router.navigateByUrl('profile/demographic');
        } else {
          this.router.navigateByUrl(route);
        }
      }
    }
  }

  closeModal(flag?: string): void {
    this.close('');
    if (flag === 'dashboard' || flag === 'redeem') {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }
  }
}
