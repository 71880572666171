<div class="lg:flex flex-row lg:gap-8 relative h-full">
  <div class="lg:hidden my-6 mx-auto">
    <select
      id="countries_disabled"
      [(ngModel)]="selectedId"
      (ngModelChange)="scrollToSection($event)"
      class="bg-gray-50 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black-light dark:placeholder-gray-400 dark:text-white"
    >
      <option value="">Table of Content</option>
      <option *ngFor="let item of tableOfContents" [value]="item.id">
        {{ item.label }}
      </option>
    </select>
  </div>
  <div class="hidden lg:block lg:w-1/4 h-screen sticky top-0 bottom-5">
    <div class="toc">
      <h2 class="">Table of Contents</h2>
      <ul class="table-of-content">
        <li *ngFor="let item of tableOfContents">
          <a
            [fragment]="item.id"
            routerLink="./"
            [class.text-blue-500]="activeSection === item.id"
            class="toc-link dark:text-white hover:text-blue-400"
            (click)="scrollToSection(item.id)"
          >
            {{ item.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="w-full lg:w-3/4 legal-content">
    <div id="who-are-we">
      <h2>1. Who are we?</h2>
      <p>
        The Sites are operated by Suzy, Inc., a company headquartered in New
        York, NY, USA.
      </p>
      <p>
        Suzy combines advanced research tools with an on-demand network of
        consumers to help companies make data-driven decisions with confidence.
        We are an end-to-end market research company. On the one end, we
        <a href="https://suzy.com/">license our software</a> and services to
        companies conducting market research (our <strong>“Clients”</strong>, a
        term that includes prospects and potential clients). On the other end,
        we run Crowdtap® for consumers like you.
        <a href="http://www.crowdtap.com/">Crowdtap</a>
        is a survey platform that rewards people for their opinions.
      </p>
    </div>
    <div id="who-are-you">
      <h2>2. Who are "you"?</h2>
      <p>
        Such a deep question! “You” are someone really important to us. Here is
        how we think of “you” with respect to this Policy:
      </p>
      <ul>
        <li>
          <strong>“Members”</strong>: The Sites are used by people who are
          interested in responding to surveys, interviews, focus groups, and the
          like (collectively, “Actions”) in exchange for financial incentives.
          (See
          <a href="https://crowdtap.com/notice-of-financial-incentive"
            >Notice of Financial Incentives</a
          >). You are an individual who has either (a) signed up for Crowdtap
          and agreed to respond to an Action, or (b) accepted an email
          invitation from Crowdtap to participate in an Action. The Crowdtap
          platform is only intended for residents of the United States.
        </li>
        <li>
          <strong>“Visitors”</strong>: You are just visiting our Sites because
          you are curious, or you have heard about us from our marketing or
          sales channels.
        </li>
      </ul>
    </div>
    <div id="what-is-personal-data">
      <h2>3. What is Personal Data?</h2>
      <p>
        <strong> Personal Data: </strong>
        When we use the term “Personal Data” in this Policy, we use it as a
        catchall term to mean any information that is linked or reasonably
        linkable to an identified or identifiable natural person. This includes
        information that relates to, describes, is reasonably capable of being
        associated with, or could reasonably be linked, directly or indirectly,
        with a particular person or household. Some states and countries refer
        to this as “personal information” or “personally identifiable
        information.”
      </p>
      <p>
        <strong>Sensitive Personal Data</strong>: Some Personal Data is so
        sensitive that it requires heightened care and protection. Different
        laws consider different types of personal data sensitive but generally
        include:
      </p>
      <ul>
        <li>Financial information</li>
        <li>
          Health information (physical health, mental health, and/or substance
          use information)
        </li>
        <li>Citizenship / immigration status</li>
        <li>Sexual orientation, sexual preferences, or sexual practices</li>
        <li>Driver’s license information</li>
        <li>
          Passport information or other government-issued identification
          information
        </li>
        <li>Zip code</li>
        <li>Genetic information</li>
        <li>Biometric information</li>
        <li>Data of children under the age of 13</li>
        <li>Racial or ethnic origin</li>
        <li>Religious or philosophical beliefs</li>
        <li>Political opinions or affiliations</li>
        <li>Trade union membership</li>
        <li>Criminal history / record information</li>
        <li>Precise geolocation information</li>
      </ul>
    </div>
    <div id="who-is-the-data-controller">
      <h2>4. Who is the data controller?</h2>
      <p>
        Suzy is the data controller of your Personal Data and will be
        responsible for holding and keeping it in compliance with privacy laws.
        In some instances, we act as joint controller or processor with the
        particular Client that initiated an Action. In those situations, Suzy
        still will be responsible for holding and keeping your Personal Data in
        compliance with privacy laws until onward transfer to the Client. Our
        “Suzy’s Roles” sheet – available on our
        <a href="https://suzy.com/trust-center">Trust Center</a> – explains when
        we act in what capacity.
      </p>
    </div>
    <div id="how-is-personal-data-collected">
      <h2>5. How is Personal Data collected?</h2>
      <p>
        We use different methods to collect Personal Data from Members and
        Visitors, including through:
      </p>
      <ul>
        <li>
          <strong>Direct interactions</strong>. Often, we receive Personal Data
          directly from you. For example, you may give us your Personal Data by
          creating a Crowdtap account, filling in forms, following us on social
          media, or by corresponding with us.
        </li>
        <li>
          <strong>Automated technologies or interactions</strong>. As you
          interact with our Sites, we will automatically collect certain
          technical data about your equipment, browsing actions, and patterns.
          This includes collecting Personal Data by using cookies, web beacons,
          and other similar technologies. Read our Cookie Policy
          <a href="https://crowdtap.com/cookie-policy">here</a>.
        </li>
        <li>
          <strong>Third parties</strong>. We also receive Personal Data from
          third parties, such as technical or behavioral information from
          analytics providers or advertising networks and contact information
          from social media networks.
        </li>
      </ul>
    </div>
    <div id="what-personal-data-do-we-use">
      <h2>6. What Personal Data do we use?</h2>
      <p>
        We’re in the business of data, and transparency is important to us.
        Under “View Details” is a chart of the categories of Personal Data we
        use, analyze, or otherwise process about Members and Visitors.
      </p>
      <p>A few things to keep in mind while reviewing the chart:</p>
      <ol>
        <li>
          <strong
            >What Personal Data we use depends on what features you choose to
            use.</strong
          >
          For example, if you choose to participate in live video interviews,
          additional nuances will apply. See
          <a routerLink="./" fragment="7-feature-specific-personal-data"
            >Section 7</a
          >
          of this Policy for details on those feature-specific nuances.
        </li>
        <li>
          <strong>Providing your Personal Data is voluntary</strong>. You can
          always choose not to provide the Personal Data that we ask you for.
        </li>
        <li>
          <strong
            >However, your ability to use the Sites may be limited if you do not
            give certain information that we need in order to provide you with
            our services.</strong
          >
          Where we need to collect Personal Data by law, or under the terms of a
          contract we have with our Clients or with you, and you fail to provide
          that data when requested, we may not be able to perform the services
          required. For example:

          <ul class="inner-list">
            <li>
              If you do not provide enhanced profile information, we may be
              unable to service you with Actions or your chances of being chosen
              for an Action may be reduced.
            </li>
            <li>
              If you do not enable location tracking on your device at the
              device level, we cannot service you with location-based Actions.
            </li>
          </ul>
        </li>
      </ol>
      <div class="w-full mx-auto contact-accordion">
        <div
          *ngFor="let item of contactInfo; let i = index"
          class="border-b border-b-gray-300 py-1"
        >
          <!-- Accordion Header -->
          <button
            (click)="toggleAccordion(i)"
            class="accordion-button flex items-center justify-between gap-4 w-full py-2 text-left text-gray-800 transition-colors duration-200"
            [ngClass]="{ 'rounded-b-none border-b-0': isOpen[i] }"
          >
            <span class="text-base font-bold leading-1.5">
              {{ item.title }}
            </span>
            <span class="w-5">
              <svg
                class="w-5 h-5 transition-transform duration-300"
                [ngClass]="{ 'rotate-arrow': isOpen[i] }"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </span>
          </button>

          <!-- Accordion Content -->
          <div
            *ngIf="isOpen[i]"
            class="text-gray-600 bg-gray-50 transition-all duration-300"
          >
            <div class="flex flex-row py-2 gap-4">
              <div class="flex-2">
                <span
                  class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                  >Category of Personal Data</span
                >
                <span class="block text-sm font-medium leading-1.5 mb-0">
                  {{ item.title }}
                </span>
              </div>
              <div class="flex-1">
                <span
                  class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                  >Whose Data</span
                >
                <span class="block text-sm font-medium leading-1.5 mb-0">
                  {{ item.whoseData }}
                </span>
              </div>
              <div class="flex-2">
                <span
                  class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                  >Lawful Basis, in addition to Consent</span
                >
                <span class="block text-sm font-medium leading-1.5 mb-0">
                  {{ item.law }}</span
                >
              </div>
            </div>
            <div class="accordion-para pb-1">
              <span
                class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                >Use</span
              >
              <p class="block text-sm font-medium leading-1.5 mb-0">
                {{ item.use }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="feature-specific-personal-data">
      <h2>7. Feature-specific Personal Data</h2>
      <p>
        In addition to the categories identified above, additional Personal Data
        or use nuances will apply in the following situations:
      </p>
      <p>
        <strong id="7a-creating-a-crowdtap-account"
          >(a) Creating a Crowdtap account</strong
        >
      </p>
      <p>
        We will corroborate the information you provided when creating an
        account in order to prevent fraud and abuse. We accomplish this by
        partnering with third-party applications that match your information
        with public records. Once you have earned a certain level of points, we
        may ask you to further verify your account by submitting additional
        information that our Know-Your-Customer (KYC) and ID verification
        third-party partners would use to ensure that no one else is using your
        identity to claim rewards.
      </p>
      <p>
        <strong id="7b-verifying-your-account"
          >(b) Verifying your account</strong
        >
      </p>
      <p>
        If you choose to use our mobile application, you will be asked to verify
        your account shortly after joining. You have the option to verify your
        account using ReCAPTCHA or by taking selfies using your mobile device
        (“Photo Verify”). Both are used by us to protect our Sites from fraud
        and abuse.
      </p>
      <ul>
        <li>
          If you choose Photo Verify, your biometric data will be collected,
          processed, and shared with Microsoft via
          <a
            href="https://azure.microsoft.com/en-in/services/cognitive-services/computer-vision/#overview"
          >
            Azure Computer Vision
          </a>
          . For more details, read our
          <a routerLink="/biometric-data-privacy-notice">
            Biometric Data Privacy Notice
          </a>
          .
        </li>
        <li>
          If you choose
          <a href="https://www.google.com/recaptcha/about/">ReCAPTCHA</a>, your
          personal information will be collected, processed, and used by Google
          to protect against bots. For more details, read
          <a href="https://policies.google.com/privacy?hl=en">
            Google's privacy policy
          </a>
          . Google LLC is organized under the laws of the State of Delaware, USA
          and operating under the laws of the USA.
        </li>
      </ul>

      <p>
        <strong id="7c-completing-actions">(c) Completing Actions</strong>
      </p>
      <p>
        Members have the ability to earn rewards by completing Actions initiated
        by us or our Clients. You control which Actions you respond to, if any.
      </p>
      <p>
        As part of initiating an Action, we or our Clients will use Member
        Profile Data to target or re-target Actions to Members based on certain
        demographics or attributes. We do this, for example, by using things
        like: saved audiences (custom audiences created based on Member Profile
        Data and previous Action activity); panels (niche audiences created
        based on Member Profile Data, social media data, etc.); segments
        (subgroups of markets or consumers using Member Profile Data and
        previous Action activity); dynamic segmentation (cluster analysis by
        machine learning algorithm to derive segments); and persona development
        (machine learning algorithm that identifies attributes across segments
        predictive of specific behaviors, like product satisfaction).
      </p>
      <p>
        To help preserve your privacy, we typically require a Client’s targeting
        criteria to meet a certain minimum number of Members before we allow an
        Action to be conducted. That way, your responses can be “aggregated”
        with other Members' responses to reduce re-identification.
      </p>
      <div
        class="bordered-section"
        [class.expanded-panel-active]="exampleOfTargeting"
      >
        <div class="header-container">
          <img
            [class.rotate-arrow]="exampleOfTargeting"
            src="/assets/icons/svg/ic-arrow-down-grey.svg"
            alt="Arrow icon"
          />
          <span
            (click)="toggle('exampleOfTargeting')"
            class="expanded-panel-title"
            >Example of Targeting</span
          >
        </div>
        <ng-container *ngIf="exampleOfTargeting">
          <div class="expanded-panel-content">
            <p>
              Here's an example of how we used Member Profile Data to help a
              Brand Client get insights from potential customers:
            </p>
            <p>
              A major pet food brand was experiencing a decline in sales at
              Walmart and hypothesized that it was the result of a recent
              packaging change. We used Member Profile Data, including Inferred
              Data and responses to previous Actions, to identify 500 shoppers
              who met the following criteria:
            </p>
            <ul>
              <li>cat owners who frequently buy kitty litter at Walmart,</li>
              <li>
                had purchased the brand’s bag of kitty litter in the last year,
                and
              </li>
              <li>could recall the brand’s recent packaging change.</li>
            </ul>
            <p>
              Actions were targeted to those 500 shoppers to gauge their
              opinions and behaviors related to the packaging change. 22%
              confirmed that they purchased the brand’s product less often and
              5% stopped purchasing altogether. We shared these responses with
              the Client, who used the aggregated information to revise its
              product placement efforts.
            </p>
          </div>
        </ng-container>
      </div>

      <p>
        <strong id="7d-participating-in-live-interviews-or-focus-groups">
          (d) Participating in live interviews or focus groups
        </strong>
      </p>
      <p>
        You may be
        <a
          href="https://support.crowdtap.com/hc/en-us/articles/360050008911-Crowdtap-interviews"
        >
          invited to participate
        </a>
        in a Live In-Depth Interview (“IDI”) or Live Focus Group.
      </p>
      <ul>
        <li>IDIs are virtual 1-on-1 interviews</li>
        <li>
          Focus Groups are virtual interviews consisting of you and 3-4 other
          Crowdtap members
        </li>
      </ul>
      <p>
        If you choose to participate in either, your Personal Data – including
        Sensitive Personal Data – will be collected, processed, and shared with
        our Brand Clients. <em>Read our</em>
        <a routerLink="/biometric-data-policy">
          <em> Biometric Data Privacy Notice</em>
        </a>
        <em> for full details.</em>
      </p>
      <p>
        <strong id="7e-providing-open-ended-text-responses"
          >(e) Providing open-ended text responses</strong
        >
      </p>
      <p>
        From time to time, you will receive Actions that involve open-ended
        questions. An open-ended question is a question that cannot be answered
        with a "yes", "no", or static response. Instead, open-ended questions
        allow you to respond in an open format, so you can answer based on your
        complete knowledge, feeling, and understanding.
      </p>
      <ul>
        <li>
          An example of a close-ended question is:
          <em>Do you meditate weekly (yes or no)?</em>
        </li>
        <li>
          An example of an open-ended question is:
          <em>How do you typically deal with stress and anxiety?</em>
        </li>
      </ul>
      <p>
        If you choose to respond to an open-ended question, your responses will
        allow Clients to attain detailed and descriptive market research
        insights.
      </p>
      <p>
        What level of detail you provide about yourself in response to these
        questions determines whether or not Personal Data is involved. For
        example, let’s say you were asked the question, “How do you choose a
        restaurant to eat at?” If you answered, “By location! I eat at Happy
        Diner STL at the corner of Smith and Jones Street because I live in the
        apartment above it!”, your Personal Data would be implicated.
      </p>
      <p>
        <strong id="7f-providing-open-ended-video-responses"
          >(f) Providing open-ended video responses</strong
        >
      </p>
      <p>
        You may be asked to respond to open-ended questions with a video
        recording. We call this “Video Open End” or “VOE” for short. If you
        choose to participate in VOE, your Biometric Data will be collected,
        processed, and shared with our Brand Clients. <em>Read our</em>
        <a routerLink="/biometric-data-privacy-notice">
          <em> Biometric Data Privacy Notice</em>
        </a>
        <em> for full details.</em>
      </p>

      <p>
        <strong id="7g-testing-products">(g) Testing products</strong>
      </p>
      <p>
        You may be asked to participate in an in-home use test (“IHUT”), which
        is a way of allowing our Clients to collect and analyze feedback from
        consumers' hands-on product testing experiences in their own native
        environment (home, work, etc). Among the variety of reasons Clients use
        IHUTs, some of the more common ones include: so product development
        teams can validate how a product is used; to study product flaws in
        order to make improvements; or to collect real usage claims about a
        product, such as “9 in 10 women would recommend this product”.
      </p>
      <p>
        Just like other Actions, you’ll earn points or rewards for your
        participation. We’ll mail you a product or ask you to buy it in-store
        with a coupon and then you’ll answer questions about your experience
        with that product. In some cases, you may be asked to submit photos or
        videos using your device as part of the Action response. For example,
        you might be asked to video record yourself opening product packaging,
        to take a picture of yourself using the product, etc.
      </p>
      <p>
        Every IHUT opportunity has eligibility conditions based on Client needs.
        Members may be selected based on their answers to application questions
        (availability, familiarity with product or service, etc) and demographic
        information that you provided when you completed your profile, like date
        of birth, location, and gender.
      </p>
      <p>
        If you choose to participate in an IHUT, we will use your contact
        information to send you IHUT invitations, instructions, reminders, and
        and post-trial activation information. We will also use your name and
        mailing address to facilitate shipping and to send tracking information
        to you. We collect information from you via a designated Crowdtap email
        or via the Crowdtap platform.
      </p>
      <p>
        We will share your name and mailing address with third-party fulfillment
        centers for purposes of sending you the product. The fulfillment centers
        we partner with are located in the U.S.
      </p>
    </div>
    <div id="what-about-artificial-intelligence">
      <h2>8. What about Artificial Intelligence?</h2>
      <p>
        We use techniques like machine learning (“ML”) or artificial
        intelligence (“AI”) on Personal Data internally operations to help
        ensure compliance with legal and regulatory requirements (data mapping)
        and to detect quality to maintain reliable survey respondents (bot and
        fraud prevention).
      </p>
      <p>
        We also use ML, AI, and generative AI for our market research and to
        provide market research services to our Clients. Things like:
      </p>
      <ul>
        <li>
          analyzing Response Data, which in turn helps us to identify trends,
          build product features that optimize responses, make product
          recommendations, and provide guidance on which products and services
          work best in different scenarios
        </li>
        <li>
          extracting and analyzing usage patterns, which in turn helps us to
          improve our services and ease of use (for example, we might identify
          when respondents prefer multiple choice versus open text questions and
          make predictive response suggestions when certain question types are
          selected)
        </li>
        <li>
          undertaking personalizations for Clients and you (for example by
          customizing the page on our website which you see at the end of a
          survey)
        </li>
        <li>
          improving the user experience for you (for example, by collecting and
          using device and browser information to improve how our service
          operates on those devices and in those browsers)
        </li>
        <li>
          building products and improving the user experience for our Clients
          (for example, we leverage generative AI to quickly produce summaries
          identifying key themes and patterns from Suzy Live interviews, video
          open ends, and text open ends)
        </li>
        <li>
          identifying insightful data trends via Aggregate Data (for example, we
          might use Aggregate Data to figure out that 44% of consumers use
          reusable products instead of single-use plastics and publish that
          finding in various outlets)
        </li>
        <li>
          providing services to our Clients that leverage machine learning, such
          as products like: heatmapping (creates a visual representation of how
          visitors interact with a webpage or design); dynamic segmentation
          (segments Clients' customers into groupings by characteristics), and
          data explorer (advanced data analytics for Clients, so they can
          discover things like the relationship between customer satisfaction
          and their willingness to try new products or if their messaging has
          broader appeal among Gen Z versus Millennials)
        </li>
        <li>
          building synthetic audiences to simulate human responses in surveys,
          interviews, focus groups, and the like
        </li>
      </ul>
      <p>
        You can choose to opt out of the above – see the “Your Privacy Rights”
        section below.
      </p>
      <p>
        Also know that our Clients can choose to opt out of the above; if they
        do, your Response Data to that particular Client’s survey won’t be used
        in AI. They tell us whether they to turn on or off our AI features or
        whether or not they want to purchase our machine learning features.
      </p>
    </div>
    <div id="who-do-we-disclose-sell-or-share-your-personal-data-to">
      <h2>9. Who do we disclose, sell, or share your Personal Data to?</h2>
      <p>
        We recognize that because you give us and allow us to use your Personal
        Data, we are entrusted with safeguarding the privacy of that
        information. Your continuing trust is very important to us, so we
        generally only disclose, sell, or share that data with third parties
        where we have given you prior notice (<em>e.g.</em>, in this Policy);
        obtained your consent ( <em>e.g.</em>, based on your Member account
        settings); or anonymized or de-identified the information in such a way
        that you or your household cannot reasonably be linked, directly or
        indirectly, by it.
      </p>
      <p>
        In addition to the sharing of your Personal Data noted elsewhere in this
        Policy, we disclose, share, or sell Personal Data to:
      </p>
      <p>
        <strong id="8a-suzy">(a) Suzy</strong>
      </p>
      <p>
        We will use your Personal Data for our brand research and insights and
        may share it with our subsidiaries and affiliates, including Suzy Home,
        LLC. We may also disclose aggregated, de-identified, or anonymized
        statistics about our Members. For example, we may post on our blog or
        include in our marketing materials statistics like the average response
        rate for Actions or what percentage of Members are male.
      </p>

      <p>
        <strong id="clients">(b) Clients</strong>
      </p>
      <p>
        We share your Action responses, Standard Attributes, and any other
        Personal Data you have voluntarily provided with the Client who
        purchased license(s) to Suzy’s insights platform and initiated the
        Action to which you responded, or to prospects or potential clients that
        Suzy wishes to engage. We share this information with the Client in our
        Suzy platform (i.e., it is viewable by any team members in the Client’s
        company with access to the Client’s dashboard). We also provide the
        Client with options to download the raw data with an excel spreadsheet
        or, in some instances, to download cluster, persona, or segment analysis
        in a PowerPoint deck. That Client will use the information for their
        brand research and insights.
      </p>
      <p>
        We may also use the Personal Data you provide to comply with regulatory
        monitoring and reporting obligations imposed by Clients in connection
        with adverse events. In such instances, we may require further
        information from you in addition to Personal Data to comply with such
        regulatory monitoring and reporting obligations. Such information may be
        provided to the relevant Client.
      </p>
      <p>
        <strong id="8c-reward-partners">(c) Reward partners</strong>
      </p>
      <p>
        We share Personal Data with third party partners who help us facilitate
        the redemption of your points for gift cards, digital subscriptions, or
        charity. For example, we may share your Personal Data with a vendor to
        verify your identity in connection with a reward redemption or to
        fulfill any incentive or reward which you have elected to receive, and
        that vendor may forward your email address to a third party to redeem
        any such rewards.
      </p>

      <p>
        <strong id="8d-software-providers">(d) Software providers</strong>
      </p>
      <p>
        We utilize several software-as-a-service providers to help facilitate
        our services. For example, we may:
      </p>
      <ul>
        <li>
          share your Contact Information, Account Creation Information, and
          Profile Information with third parties to facilitate sending Crowdtap
          Actions by email or text to Members. For example, we use Google Forms
          to gather Contact Information and to help schedule IDIs and Focus
          Groups.
        </li>
        <li>
          share your Contact Information, Account Creation Information, and Data
          Quality Information with third parties to help us facilitate fraud
          prevention.
        </li>
        <li>
          share your Log Data Information, Cookie Information, Usage
          Information, Device and Browser Data, and Inferred Data with third
          parties to deliver and help us track our marketing and advertising
          content
        </li>
        <li>
          share your Referral Information, Log Data Information, Cookie
          Information, Usage Information, Device and Browser Data, and Response
          Rate Information with third parties to help us track website
          conversion success metrics
        </li>
        <li>
          share your Contact Information, Profile Information, and Cookie
          Information with a third party support platform to help us manage our
          customer support services to you
        </li>
      </ul>

      <p>
        <strong id="8e-service-providers-and-contractors"
          >(e) Service providers and contractors</strong
        >
      </p>
      <p>
        We may provide your Personal Data to other companies or individuals
        we’ve hired to perform one or more services for us (such as quality
        assurance services, moderation services, translation services, targeting
        audiences, analyzing responses, matching data, etc.). Those companies or
        individuals are located both in the U.S. and abroad. These companies or
        individuals are authorized to use your Personal Data only as necessary
        to provide these services to us.
      </p>
      <p>
        Internally, our Managed Services team may process your Personal Data as
        part of the work they perform for Brand Clients (<em>i.e.</em>, building
        segments, targeting audiences, analyzing responses, etc).
      </p>

      <p>
        <strong id="8f-technical-consultants-and-third-party-auditors">
          (f) Technical consultants and third-party auditors
        </strong>
      </p>
      <p>
        We share Personal Data with technical consultants and third party
        auditors to ensure we meet our security, legal, and regulatory
        compliance requirements.
      </p>

      <p>
        <strong id="8g-investors-successor-entities-purchasers"
          >(g) Investors, successor entities, or purchasers</strong
        >
      </p>
      <p>
        We reserve the right to transfer your Personal Data, including profile
        information, responses to Actions, or other information to a successor
        entity upon a merger, consolidation, or other corporate reorganization
        in which Suzy participates or to a purchaser of all or substantially all
        of Suzy’s assets related to Crowdtap.
      </p>

      <p>
        <strong id="8h-law-enforcement">(h) Law enforcement</strong>
      </p>
      <p>
        We may disclose your Personal Data as required by law, including for
        purposes of law enforcement, or when we believe in good faith that
        disclosure is necessary to protect our rights, protect your safety or
        the safety of others, investigate fraud, and/or to comply with a
        judicial proceeding, court order, subpoena, or other legal process
        served on us. We may also disclose your Personal Data to investigate
        suspected violations of the Site rules or policies.
      </p>

      <p>
        <strong id="8i-anonymized-or-de-identified-information"
          >(i) Anonymized or de-identified information</strong
        >
      </p>
      <p>
        We may publish or disclose anonymized or de-identified information about
        our services, including responses to Actions. For example, we may post
        on our blog or otherwise include in our marketing emails interesting
        statistics like how many Actions were completed in a month.
      </p>

      <p>
        <strong id="8j-aggregated-information"
          >(j) Aggregated information</strong
        >
      </p>
      <p>
        We use Aggregate Data to analyze aggregate trends both amongst Members
        and External Audiences or other survey respondents. We may use this
        information for our own business purposes or sell this aggregate,
        de-identified information to our Clients or other third parties for
        advertising, promotional, or other commercial purposes. Where data has
        been de-identified, we take appropriate technical and organizational
        measures to prevent future re-identification.
      </p>
      <p>
        <strong id="8k-vital-interests">(k) Vital interests</strong>
      </p>
      <p>
        In rare events, we may disclose your Personal Data where we believe in
        good faith that access or disclosure is necessary to protect the
        personal safety or vital interests of Suzy employees, customers, or the
        public.
      </p>
      <p>
        <strong id="8l-other">(l) Other</strong>
      </p>
      <p>
        Information obtained in connection with the Sites may be intermingled
        with and used by us in conjunction with information obtained through
        sources other than the Sites, including both offline and online sources.
      </p>
      <p>
        We may also disclose or share information about you with other third
        parties as part of provisioning and improving our services. We will not
        share or sell your Personal Data to any third parties for reasons
        outside of provisioning and improving our services and for the other
        purposes described in this Policy unless we have:
      </p>
      <ul>
        <li>obtained your consent; or</li>
        <li>
          anonymized or de-identified the information such that the information
          does not contain your Personal Data.
        </li>
      </ul>
    </div>
    <div id="are-survey-responses-anonymous">
      <h2>10. Are survey responses anonymous?</h2>
      <p>
        <strong>To Suzy/us, the answer is no.</strong>
        For example, we link your survey responses with your Member account
        information, but never fear, we do not abuse or exploit this. We do this
        to provide Clients with additional targeting criteria and to otherwise
        provide the services you have asked us to provide.
      </p>
      <p>
        <strong>
          To Clients and other third parties we share Personal Data with, the
          answer is maybe.
        </strong>
        It depends on what types of Actions you choose to participate in and
        what information you provide in your responses. For example, if you
        disclose your name in a response, you won’t be anonymous. But even if
        you don’t, the information you do provide may be identifying, such as
        when combined with other information (like Standard Attributes). Also,
        if you post your Personal Data in a section of the Sites where it was
        not explicitly requested, or outside of the Sites where it may be
        viewable and/or accessible by Clients (e.g., contact information listed
        within feedback you provide, information on your Facebook profile linked
        to your account with us, or an email address you provided directly to a
        Client), we cannot control how third parties (such as Clients) may use
        that information.
      </p>
    </div>
    <div id="how-long-is-personal-data-retained">
      <h2>11. How long is Personal Data retained?</h2>
      <p>
        Except as otherwise specified in this Policy, we retain each category of
        Personal Data for no longer than necessary to provide you or our Clients
        the services requested, to comply with our legal obligations, or to
        resolve disputes and enforce our agreements. Member Personal Data is
        anonymized / deleted upon request or upon a reasonable period after
        account abandonment
      </p>
    </div>
    <div id="where-is-personal-data-processed-and-stored">
      <h2>12. Where is Personal Data processed and stored?</h2>
      <p>
        Suzy is based in the United States, our servers and systems are located
        in the United States, and many of our service providers are based in the
        United States.
      </p>
      <p>
        <strong>
          <em>
            By using the Sites, you freely and specifically give us your consent
            to export, process, and/or store your Personal Data in the United
            States. You understand that the United States may not have the same
            data protection / privacy laws as your country and that data stored
            in the United States may be subject to lawful requests by the courts
            or law enforcement authorities in the United States.
          </em></strong
        >
      </p>
      <p>
        Sometimes, we transfer your Personal Data overseas to our Clients,
        service providers, and subcontractors in places with different laws and
        protections. We’ll use appropriate technical and organizational measures
        and safeguards to protect your data during international transfers and
        at all other times it is in our care
      </p>
      <p>
        If you are resident in or a visitor of the EEA, United Kingdom, or
        Switzerland, we will protect your Personal Data when it is transferred
        outside of such locations by processing it in a territory which the
        European Commission has determined provides an adequate level of
        protection for personal information or otherwise implementing
        appropriate safeguards to protect your Personal Data, including through
        the use of Standard Contractual Clauses or another lawful transfer
        mechanism approved by the European Commission.
      </p>
    </div>
    <div id="security">
      <h2>13. What about security</h2>
      <p>
        The security of your Personal Data is very important to us. We put in
        place reasonable and appropriate technical and organizational measures
        to ensure your Personal Data is kept secure and protected from
        unauthorized access, use, disclosure, alteration or destruction, in
        accordance with applicable laws and regulations. When you enter
        sensitive information (such as login credentials), we encrypt the
        transmission of that information using Transport Layer Security (TLS).
        When we share your Personal Data with service providers, subcontractors,
        or other third parties, we base our selection on said parties having
        adequate safeguards in place that meet our data protection standards. We
        perform risk assessments or otherwise audit their compliance with such
        standards and incorporate applicable contractual provisions ensuring
        compliance with (i) such standards and (ii) applicable data privacy laws
        and regulations. For more information, visit our
        <a href="https://suzy.com/trust-center">Trust Center</a>.
      </p>
      <p>
        However, please know that transmissions over the Internet or a mobile
        network are never one hundred percent (100%) secure, so Suzy can not
        (and does not) guarantee the security of transmissions. Suzy is not
        responsible for any errors by you in submitting Personal Data to us.
      </p>
    </div>
    <div id="minors">
      <h2>14. What about minors</h2>
      <p>
        Our Sites are not intended for and may not be used by minors.
        <strong>"Minors"</strong> are individuals under the age of 13 (or under
        a higher age if required by the laws of their residence). We do not
        knowingly collect nor intend to collect Personal Data from Minors or
        allow them to register for the Sites without verifiable parental
        consent. Where appropriate, we take reasonable measures to inform Minors
        not to submit such information to our Sites. If we learn that a Minor
        has provided us with Personal Data without verifiable parental consent,
        we will use all reasonable efforts to delete such information from our
        systems.
      </p>
    </div>
    <div id="links-to-other-websites">
      <h2>15. Links to other websites</h2>
      <p>
        We may provide links to websites and other third-party content that we
        do not own or operate. The websites and third-party content to which we
        link will have separate privacy policies. We are not responsible for the
        privacy practices of any entity that we do not own or control. To ensure
        the protection of your privacy, always review the privacy notice(s) of
        the websites you visit.
      </p>
    </div>
    <div id="bulletin-boards-and-chat-areas">
      <h2>16. Bulletin boards and chat areas</h2>
      <p>
        You should be aware that Personal Data which you voluntarily include and
        transmit online may be viewed and used by others. Users of the Site are
        solely responsible for the content of messages they post online,
        including on public forums. These forums include but are not limited to
        chat rooms on our Site or other websites, bulletin boards, blogs, social
        media web pages, or other publicly accessible forums which may be viewed
        and used by anyone with access to such forums. You should be aware that
        when you voluntarily disclose Personal Data within a public forum, your
        information may be collected and used by others. The Site is unable to
        control such uses of your Personal Data, and by using such services, you
        assume the risk that the Personal Data provided by you may be viewed and
        used by third parties.
      </p>
    </div>
    <div id="notice-of-financial-incentive">
      <h2>17. Notice of Financial Incentive</h2>
      <p>
        Please read our Notice of Financial Incentive here:
        <a>https://crowdtap.com/notice-of-financial-incentive</a>
      </p>
    </div>
    <div id="your-privacy-rights">
      <h2>18. Your privacy rights</h2>
      <p>
        You have certain rights regarding your Personal Data. Your rights and
        choices may vary depending on the laws applicable to your Personal Data.
        Such laws may extend additional rights and choices to you or may limit
        or except the rights listed below.
      </p>
      <div class="w-full mx-auto contact-accordion">
        <div
          *ngFor="let item of rights; let i = index"
          class="border-b border-b-gray-300 py-1"
        >
          <!-- Accordion Header -->
          <button
            (click)="toggleAccordion(i)"
            class="accordion-button flex items-center justify-between gap-4 w-full py-2 text-left text-gray-800 transition-colors duration-200"
            [ngClass]="{ 'rounded-b-none border-b-0': isOpen[i] }"
          >
            <span class="text-base font-bold leading-1.5">
              {{ item.right }}
            </span>
            <span class="w-5">
              <svg
                class="w-5 h-5 transition-transform duration-300"
                [ngClass]="{ 'rotate-arrow': isOpen[i] }"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </span>
          </button>

          <!-- Accordion Content -->
          <div
            *ngIf="isOpen[i]"
            class="text-gray-600 bg-gray-50 transition-all duration-300"
          >
            <div class="accordion-para pb-1">
              <span
                class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                >Details</span
              >
              <p class="block text-sm font-medium leading-1.5 mb-0">
                {{ item.details }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="exercising-your-privacy-rights">
      <h2>19. Exercising your privacy rights</h2>
      <p>You can exercise your rights by:</p>
      <ul>
        <li>
          submitting a
          <a href="https://crowdtap.formcrafts.com/privacyrequest"
            >Privacy Request</a
          >,
        </li>
        <li><a href="mailto:trust@suzy.com">e-mailing us</a>,</li>
        <li>
          contacting
          <a href="https://support.crowdtap.com/hc/en-us/requests/new"
            >Support</a
          >, or
        </li>
        <li>
          writing to: Suzy, Inc. Attn: Legal, 228 Park Avenue South, PMB 85529
          Broadway, New York, NY 10003.
        </li>
        <li>
          If you are in the European Union, European Economic Area, or UK, you
          can
          <a
            href="https://suzy.com/s/SUZY01-Representative-contact-summary-2023.pdf"
            >contact our Article 27 Data Protection Representative</a
          >.
        </li>
      </ul>

      <p>
        We will not charge you fees in connection with the exercise of your
        rights, unless the request is manifestly unfounded or excessive (for
        example, because of its repetitive character). We will not discriminate
        against you for exercising your privacy rights. However, please note
        that honoring some of your rights may mean that we are unable to perform
        the actions necessary for you to use or take full advantage of the
        Sites.
      </p>
      <p class="text-bold">Response timing and format</p>

      <p>
        We will respond to your request in a reasonably timely manner and
        typically within 30-60 days, depending on the laws applicable to you.
        We'll either fulfill your request or explain why we're not taking
        action. If we don't take action, and if the applicable laws so require,
        we'll also provide instructions on any rights to appeal our decision.
      </p>

      <p>
        In order to protect the security of your Personal Data, we will not
        honor a request if we cannot verify your identity or authority to make
        the request and confirm the Personal Data relates to you. The method
        used to verify your identity will depend on the type, sensitivity, and
        value of the information, including the risk of harm to you posed by any
        authorized access or deletion. Generally speaking, verification will be
        performed by matching the identifying information provided by you to the
        Personal Data that we already have.
      </p>

      <p>
        If you are in the European Union and you are not satisfied with our
        response, you have the right to complain or seek advice from your local
        data protection supervisory authority and/or bring a claim against us in
        any court of competent jurisdiction.
      </p>

      <p>
        Where necessary, we will take reasonable steps to pass along your
        request to our Clients and any other third parties to whom we have
        disclosed your Personal Data.
      </p>
    </div>
    <div id="your-privacy-controls">
      <h2>20. Your privacy controls</h2>
      <p>
        In addition to your Privacy Rights, there are mechanisms you can use to
        control your Personal Data.
      </p>
      <div class="w-full mx-auto contact-accordion">
        <div
          *ngFor="let item of controls; let i = index"
          class="border-b border-b-gray-300 py-1"
        >
          <!-- Accordion Header -->
          <button
            (click)="toggleAccordion(i)"
            class="accordion-button flex items-center justify-between gap-4 w-full py-2 text-left text-gray-800 transition-colors duration-200"
            [ngClass]="{ 'rounded-b-none border-b-0': isOpen[i] }"
          >
            <span class="text-base font-bold leading-1.5">
              {{ item.control }}
            </span>
            <span class="w-5">
              <svg
                class="w-5 h-5 transition-transform duration-300"
                [ngClass]="{ 'rotate-arrow': isOpen[i] }"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </span>
          </button>

          <!-- Accordion Content -->
          <div
            *ngIf="isOpen[i]"
            class="text-gray-600 bg-gray-50 transition-all duration-300"
          >
            <div class="flex flex-row py-2 gap-4">
              <div class="flex-1">
                <span
                  class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                  >Controls</span
                >
                <span class="block text-sm font-medium leading-1.5 mb-0">
                  {{ item.control }}
                </span>
              </div>
              <div class="flex-1">
                <span
                  class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                  >Available To</span
                >
                <span class="block text-sm font-medium leading-1.5 mb-0">
                  {{ item.availableTo }}
                </span>
              </div>
            </div>
            <div class="accordion-para pb-1">
              <span
                class="block text-xs font-semibold text-blue-dark leading-1.5 mb-0"
                >Details</span
              >
              <p class="block text-sm font-medium leading-1.5 mb-0">
                {{ item.details }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="how-can-you-contact-us">
      <h2>21. Contact us</h2>
      <p>You can contact us by:</p>

      <ul>
        <li>
          <a href="mailto:trust@suzy.com">e-mailing us,</a>
        </li>
        <li>
          <a href="https://support.crowdtap.com/hc/en-us/requests/new"
            >contacting Customer Support, or</a
          >
        </li>
        <li>
          writing to: Suzy, Inc., Attn: Legal, 228 Park Avenue South, PMB 85529
          Broadway, New York, NY 10003.
        </li>
        <li>
          If you are in the European Union, European Economic Area or UK, you
          can
          <a
            href="https://suzy.com/s/SUZY01-Representative-contact-summary-2023.pdf"
            >contact our Article 27 Data Protection Representative</a
          >.
        </li>
      </ul>
    </div>
    <div id="changes-to-this-policy">
      <h2>22. Changes to this Policy</h2>
      <p>
        We may change, add, or update this Policy at any time. If a change is
        deemed material under applicable legal requirements, we will send you
        notification as required by applicable law (such as via email or using
        other contact information provided to us). For all other changes, we
        will update the date of this Policy. Please review this page from time
        to time to stay informed of how we are processing your data.
      </p>
      <em>
        <strong>
          By using the Sites following any Policy change, you freely and
          specifically give us your consent to collect, use, transfer, and
          disclose your Personal Data in the manner specified.
        </strong>
      </em>
    </div>
    <div id="prior-versions-of-this-policy">
      <h2>23. Prior Versions of this Notice</h2>
      <ul>
        <li>
          <a routerLink="/privacy-policy/2021-02-23">February 23, 2021</a>
        </li>
        <li>
          <a routerLink="/privacy-policy/2021-09-15">September 15, 2022</a>
        </li>
        <li><a routerLink="/privacy-policy/2023-07-19">July 19, 2023</a></li>
      </ul>
    </div>
  </div>
</div>

<div>
  <p>Updated: March 13, 2024</p>

  <p>
    Suzy, Inc., the owner of Crowdtap®, respects your privacy. This Privacy
    Policy describes how Suzy, Inc. and its subsidiaries and affiliates
    (collectively, “Suzy”, “we”, “our”, or “us”) collects, uses, stores, and
    shares personal data in connection with (1) the
    <a href="http://www.crowdtap.com/">www.crowdtap.com</a> website, the
    Crowdtap desktop platform, the Crowdtap mobile application, and any related
    Crowdtap services; and (2) any other sites that display this Policy
    (collectively, the “Sites,” and any, a “Site”). It is important that you
    read this Policy together with any other privacy notices we may provide on
    specific occasions when we are collecting or processing personal data about
    you, so that you are fully informed of how and why we are using your data.
    This Policy supplements our other notices and is not intended to override
    them, except where specifically stated.
  </p>
  <p>
    This Policy applies solely to information collected via the Sites and does
    not apply to our platform for brand clients (<a href="http://www.suzy.com/"
      >Suzy.com</a
    >
    and related applications and sites), which has a
    <a href="https://suzy.com/external-audiences-privacy-notice"
      >separate privacy policy</a
    >, nor our services for External Audiences, which have a separate privacy
    policy.
  </p>

  <div class="warning-message">
    <img src="/assets/icons/svg/error.svg" alt="" />
    <p class="extra-details">
      <strong>
        By using or accessing the Sites, you accept and unambiguously consent to
        this Policy and to our use of your Personal Data as described in this
        Policy. IF YOU DO NOT AGREE TO THE TERMS OF THIS POLICY, DO NOT USE THE
        SITES. For details on your privacy rights, see the “Your Privacy Rights”
        section below.
      </strong>
    </p>
  </div>
</div>
