import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { SettingsService } from '../../../core/services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ISegmentMissionFlagged,
  SegmentService
} from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { MissionKind, TutorialMapKind } from '@asksuzy/typescript-sdk';
import { SpecialTileService } from '@suzy/shared/special-tile';

@Component({
  selector: 'app-flag-mission-modal',
  templateUrl: './flag-mission-modal.component.html'
})
export class FlagMissionMoadlComponent implements OnInit {
  darkMode$!: Observable<boolean>;
  noSelection?: boolean;
  flagOptions: Array<{ label: string; value: number; checked: boolean }> = [];

  @Input() mission: any;
  @Input() action?: any;
  showMessage: boolean;

  constructor(
    public modal: NgbActiveModal,
    private settingsService: SettingsService,
    private segmentService: SegmentService,
    private missionService: MissionService,
    private translate: TranslateService,
    private specialTileService: SpecialTileService
  ) {
    this.darkMode$ = this.settingsService.isDarkModeSelected$;
  }

  ngOnInit(): void {
    this.prepareOptions();
  }

  closeModal(): void {
    this.modal.dismiss('closed');
  }

  onSubmitClick(): void {
    const hasSelectedItems = this.flagOptions.some(x => x.checked);
    if (!hasSelectedItems) {
      this.noSelection = true;
      return;
    }

    const trackProperties: ISegmentMissionFlagged = {
      brand_id: this.mission.brand_id,
      mission_id: this.mission.mission_id,
      mission_kind: this.mission.mission_kind,
      flag_reason: this.flagOptions
        .filter(x => x.checked)
        .map(x => x.label)
        .join(', '),
      points_available: this.mission.points,
      weight: this.mission.weight,
      tag: this.specialTileService.getSpecialTileName(
        this.mission.external_json
      )
    };
    if (this.action) {
      const item = this.missionService.getActionByKind(this.action);
      trackProperties.action_id = this.action.action_id;
      trackProperties.action_kind = this.action.action_kind;
      trackProperties.action_name = item?.question;
    }

    this.segmentService.trackMissionFlagged(trackProperties);
    setTimeout(() => {
      this.modal.close({
        isSkippable: this.showSubmitButton()
      });
    }, 100);
  }

  onCheckBoxChange(value: number): void {
    const item = this.flagOptions.find(x => x.value === value);
    if (!item) {
      return;
    }
    item.checked = !item.checked;
    const checkedCount = this.flagOptions.filter(
      option => option.checked
    ).length;
    this.showMessage = checkedCount > 0;
    if (item.checked) {
      this.noSelection = false;
    }
  }

  private prepareOptions(): void {
    const flagOptions = [1, 2, 3, 4];
    const translations$: Observable<string>[] = [];
    flagOptions.forEach(ix => {
      const translate$ = this.translate.get(`missionFeedback.${ix}`);
      translations$.push(translate$);
    });

    combineLatest(translations$).subscribe(translations => {
      flagOptions.forEach((value, ix) => {
        this.flagOptions.push({
          value,
          checked: false,
          label: translations[ix]
        });
      });
    });
  }

  showSubmitButton(): boolean {
    const isActionSkippable = this.action && !this.action.prevent_skip;
    const isProfileQuestion =
      (this.mission?.tutorial_map_kind &&
        this.mission.tutorial_map_kind !== TutorialMapKind.no_map) ||
      this.mission.is_onboarding ||
      this.mission.is_onboarding_completed;
    const isMissionSkippable =
      !this.mission.prevent_skip &&
      this.mission.mission_kind !== MissionKind.standard;
    const isMissionInProgress = this.mission?.is_mission_in_progress;

    return (
      (isActionSkippable || !isProfileQuestion || isMissionSkippable) &&
      !isMissionInProgress
    );
  }
}
