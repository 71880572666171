<div class="wrapper">
  <app-general-header [isMobile]="isMobile$ | async"></app-general-header>

  <section
    class="anonymous"
    aria-label="main"
    role="main"
    id="main-content"
    *ngIf="!isLoading"
  >
    <article class="flex flex-col mt-6 w-full px-4 m-auto sm:max-w-128">
      <h1
        class="text-2xl lg:text-3.5xl text-black dark:text-white font-bold text-center my-6"
      >
        {{ 'emailSent.title' | translate }}
      </h1>
      <div class="flex justify-center items-center gap-2 py-2 mb-6">
        <div class="w-6 h-6">
          <svg
            class="text-black dark:text-white"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.75 5.75L11.375 4.5L12.625 3.875L11.375 3.25L10.75 2L10.125 3.25L8.875 3.875L10.125 4.5L10.75 5.75ZM5.125 8.25L6.16641 6.1668L8.25 5.125L6.16641 4.0832L5.125 2L4.08359 4.0832L2 5.125L4.08359 6.1668L5.125 8.25ZM18.875 13.25L17.8336 15.3332L15.75 16.375L17.8336 17.4168L18.875 19.5L19.9164 17.4168L22 16.375L19.9164 15.3332L18.875 13.25ZM21.6336 5.68086L18.3191 2.36641C18.0754 2.12187 17.7555 2 17.4355 2C17.1156 2 16.7957 2.12187 16.5516 2.36641L2.36641 16.5516C1.87813 17.0398 1.87813 17.8312 2.36641 18.3191L5.68086 21.6336C5.925 21.8777 6.24492 21.9996 6.56445 21.9996C6.88438 21.9996 7.2043 21.8777 7.44844 21.6336L21.6336 7.44805C22.1219 6.96055 22.1219 6.16875 21.6336 5.68086ZM16.041 9.94766L14.0523 7.95898L17.4352 4.57617L19.4238 6.56484L16.041 9.94766Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <p class="text-black dark:text-white text-sm font-medium">
          {{
            'emailSent.linkSentToEmail'
              | translate : { email: endUser?.email, time: formatTime() }
          }}
        </p>
      </div>
      <div class="border-t border-active-link-dark py-6">
        <p class="text-base text-black dark:text-white font-bold mb-4">
          {{ 'emailSent.notGetEmail' | translate }}
        </p>
        <p class="flex flex-row gap-4">
          <span class="text-black dark:text-white w-6 h-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.78 4.05L13.5 5.5H18.5C19.05 5.5 19.5 5.95 19.5 6.5V14.5C19.5 15.05 19.05 15.5 18.5 15.5H13.11C12.74 15.5 12.39 15.29 12.22 14.95L11.5 13.5H6.5V19.5C6.5 20.05 6.05 20.5 5.5 20.5C4.95 20.5 4.5 20.05 4.5 19.5V4.5C4.5 3.95 4.95 3.5 5.5 3.5H11.88C12.26 3.5 12.61 3.71 12.78 4.05ZM13.5 13.5H17.5V7.5H12.5L11.5 5.5H6.5V11.5H12.5L13.5 13.5Z"
                fill="currentColor"
              />
            </svg>
          </span>
          <span class="text-sm font-medium text-black dark:text-white">
            {{ 'emailSent.checkSpam' | translate }}
          </span>
        </p>
      </div>
      <div class="flex flex-col gap-4">
        <p class="text-base text-black dark:text-white font-bold">
          {{ 'emailSent.whatCanIDo' | translate }}
        </p>
        <div class="flex justify-start items-center gap-2">
          <div class="w-6 h-6">
            <svg
              [ngClass]="timeLeft !== 0 ? 'text-grey-bb' : 'text-black'"
              class="dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10.75 5.75L11.375 4.5L12.625 3.875L11.375 3.25L10.75 2L10.125 3.25L8.875 3.875L10.125 4.5L10.75 5.75ZM5.125 8.25L6.16641 6.1668L8.25 5.125L6.16641 4.0832L5.125 2L4.08359 4.0832L2 5.125L4.08359 6.1668L5.125 8.25ZM18.875 13.25L17.8336 15.3332L15.75 16.375L17.8336 17.4168L18.875 19.5L19.9164 17.4168L22 16.375L19.9164 15.3332L18.875 13.25ZM21.6336 5.68086L18.3191 2.36641C18.0754 2.12187 17.7555 2 17.4355 2C17.1156 2 16.7957 2.12187 16.5516 2.36641L2.36641 16.5516C1.87813 17.0398 1.87813 17.8312 2.36641 18.3191L5.68086 21.6336C5.925 21.8777 6.24492 21.9996 6.56445 21.9996C6.88438 21.9996 7.2043 21.8777 7.44844 21.6336L21.6336 7.44805C22.1219 6.96055 22.1219 6.16875 21.6336 5.68086ZM16.041 9.94766L14.0523 7.95898L17.4352 4.57617L19.4238 6.56484L16.041 9.94766Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <button
            [ngClass]="timeLeft !== 0 ? 'text-grey-bb' : 'text-blue-500'"
            class="text-sm font-semibold"
            [disabled]="timeLeft !== 0"
            (click)="onResendEmail()"
          >
            {{
              'emailSent.resendLink' | translate : { email: 'text@test.com' }
            }}
          </button>
        </div>
        <p class="error-popover-msg text-error text-sm" *ngIf="isError">
          {{ errorMsg }}
        </p>
        <div class="flex justify-start items-center gap-2">
          <div class="w-6 h-6">
            <svg
              class="text-black dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 2H20C21.1 2 22 2.9 22 4V16C22 17.1 21.1 18 20 18H6L2 22V4C2 2.9 2.9 2 4 2ZM6 16H19C19.55 16 20 15.55 20 15V5C20 4.45 19.55 4 19 4H5C4.45 4 4 4.45 4 5V18L6 16Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <a
            class="text-sm text-blue-500 font-semibold"
            href="https://support.crowdtap.com/hc/en-us"
          >
            {{ 'emailSent.contactSupport' | translate }}
          </a>
        </div>
      </div>
    </article>
  </section>
</div>
<section class="footer-contianer pb-2.5 sm:pb-0">
  <app-general-footer id="main-footer"></app-general-footer>
</section>
