import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../../../core/services/settings.service';
import { SmsPromptService } from '../../services/sms-prompt.service';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';

@Component({
  selector: 'sms-prompt-modal',
  templateUrl: './sms-prompt-modal.component.html',
  styleUrls: ['./sms-prompt-modal.component.scss']
})
export class SmsPromptModalComponent implements OnInit {
  darkMode: boolean = false;

  constructor(
    private modalService: NgbActiveModal,
    private settingsService: SettingsService,
    private smsPromptService: SmsPromptService,
    private sdk: SuzySdkService
  ) {}

  ngOnInit() {
    this.settingsService.isDarkModeSelected$.subscribe(
      darkMode => (this.darkMode = darkMode)
    );
  }

  closeModal() {
    this.modalService.close();
  }

  onGetNotifiedClicked() {
    this.smsPromptService.optIn().subscribe((res: any) => {
      this.modalService.close();
    });
  }
}
