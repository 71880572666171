<div class="flex flex-col h-full action-modal-container" *ngIf="!isGlobal">
  <div
    class="flex justify-between p-6 sm:px-20 md:px-24 md:py-8 dark:text-white bg-body-light dark:bg-body-dark modal-heading"
  >
    <div class="flex items-center">
      <span
        *ngIf="mission?.specialType !== SpecialTileType.double_points_tile"
        class="bg-card-{{ activeColor }} inline-block h-6 mr-2 rounded-md w-6 "
      ></span>
      <div
        *ngIf="mission?.specialType === SpecialTileType.double_points_tile"
        class="double-points-card double-points-card-{{
          activeColor
        }} inline-block h-5 mr-2.5 w-5"
      ></div>
      <h1
        *ngIf="mission?.specialType !== SpecialTileType.double_points_tile"
        class="text-xs font-semibold"
        [innerHTML]="
          actionKindString | translate | markdown2html : { secure: true }
        "
        [attr.data-track]="'member-' + actionType + '-beginning-title'"
      ></h1>
      <h1
        *ngIf="mission?.specialType === SpecialTileType.double_points_tile"
        class="text-xs font-semibold"
        [ngClass]="{ 'double-points-copy': isDarkMode }"
        [innerHTML]="
          ('specialTile.doublePoint' | translate) +
            ' ' +
            (actionKindString | translate) | markdown2html : { secure: true }
        "
        [attr.data-track]="'member-' + actionType + '-beginning-title'"
      ></h1>
    </div>
    <div class="modal-header">
      <div
        class="kind-icon"
        [ngClass]="{
          'icon-survey':
            kind === missionKind.survey ||
            kind === missionKind.splittesting ||
            kind === missionKind.wave,
          'icon-sharing': kind === missionKind.sharing
        }"
      ></div>

      <button
        *ngIf="showFlagIcon"
        (click)="onFlagClick()"
        [attr.data-track]="'member-' + actionType + '-flag-mission'"
        type="button"
        class="close-modal-btn w-8 h-8 relative ring-card-{{ activeColor }}"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.1318 5.12598C13.2126 5.12598 11.4871 3.87598 8.80707 3.87598C7.58437 3.87598 6.52961 4.12918 5.65391 4.46848C5.7407 4.20797 5.76926 3.93158 5.73758 3.65883C5.63543 2.76563 4.89715 2.06324 4.00004 2.00504C2.90789 1.93418 2 2.79906 2 3.87598C2 4.56988 2.37723 5.17528 2.9375 5.49949V21.376C2.9375 21.7212 3.2173 22.001 3.5625 22.001H4.1875C4.5327 22.001 4.8125 21.7212 4.8125 21.376V18.1165C6.29176 17.4416 7.59605 17.001 9.8057 17.001C11.7249 17.001 13.4504 18.251 16.1304 18.251C18.4148 18.251 20.1137 17.3675 21.1518 16.6892C21.6815 16.3432 22 15.753 22 15.1203V5.74852C22 4.40246 20.6225 3.49196 19.3866 4.0252C17.9841 4.63024 16.5172 5.12598 15.1318 5.12598ZM20.125 15.126C19.2741 15.728 17.7491 16.376 16.1304 16.376C13.7888 16.376 12.146 15.126 9.8057 15.126C8.11191 15.126 6.0409 15.4933 4.8125 16.0635V7.00098C5.66344 6.39895 7.18844 5.75098 8.80707 5.75098C11.1487 5.75098 12.7915 7.00098 15.1318 7.00098C16.8221 7.00098 18.8943 6.32262 20.125 5.75098V15.126Z"
            fill="white"
            class="text-black fill-current dark:text-white"
          />
        </svg>
      </button>

      <button
        *ngIf="mission?.isGlobal === 'true' ? false : true"
        (click)="closeModal()"
        [attr.data-track]="'member-' + actionType + '-beginning-close'"
        type="button"
        aria-label="Close"
        class="close-modal-btn w-8 h-8 relative ring-card-{{ activeColor }}"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.4099 12.5002L19.7099 6.21019C20.1021 5.81806 20.1021 5.18231 19.7099 4.79019C19.3178 4.39806 18.6821 4.39806 18.2899 4.79019L11.9999 11.0902L5.70994 4.79019C5.31782 4.39806 4.68206 4.39806 4.28994 4.79019C3.89782 5.18231 3.89782 5.81806 4.28994 6.21019L10.5899 12.5002L4.28994 18.7902C4.10063 18.978 3.99414 19.2335 3.99414 19.5002C3.99414 19.7668 4.10063 20.0224 4.28994 20.2102C4.47771 20.3995 4.7333 20.506 4.99994 20.506C5.26658 20.506 5.52217 20.3995 5.70994 20.2102L11.9999 13.9102L18.2899 20.2102C18.4777 20.3995 18.7333 20.506 18.9999 20.506C19.2666 20.506 19.5222 20.3995 19.7099 20.2102C19.8993 20.0224 20.0057 19.7668 20.0057 19.5002C20.0057 19.2335 19.8993 18.978 19.7099 18.7902L13.4099 12.5002Z"
            fill="white"
            class="text-black fill-current dark:text-white"
          />
        </svg>
      </button>
    </div>
  </div>
  <div
    class="items-center justify-center flex-1 p-6 text-center border-t-2 border-card-{{
      activeColor
    }} sm:px-20 md:px-24 md:py-8 dark:text-white bg-box-light dark:bg-box-dark"
  >
    <div class="modal-body action-modal-body h-full">
      <div class="modal-content">
        <h2
          class="text-sm font-normal md:text-lg lg:font-bold lg:text-xl mt-28"
          [innerHTML]="title | markdown2html : { secure: true }"
        ></h2>
        <p
          class="mt-12 text-sm font-normal"
          [innerHTML]="description | convertMarkdownToHtml"
          [attr.data-track]="'member-' + actionType + '-beginning-description'"
        ></p>
        <div
          class="time-to-answer-section dark:text-white bg-body-light-transparent dark:bg-body-dark"
          *ngIf="
            isShowTimeOnTileFlagOn &&
            mission.mission_kind != missionKind.standard &&
            timeToAnswer
          "
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id=" stopwatch">
              <path
                id="&#240;&#159;&#140;&#136; COLOR"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3 8.58811L19.21 7.68811C19.6021 7.29598 19.6021 6.66023 19.21 6.26811C18.8179 5.87598 18.1821 5.87598 17.79 6.26811L16.89 7.17811C14.0076 4.94829 9.98245 4.94829 7.10003 7.17811L6.19003 6.25811C5.79514 5.86598 5.15715 5.86822 4.76503 6.26311C4.3729 6.65799 4.37514 7.29598 4.77003 7.68811L5.69003 8.59811C4.59304 9.99565 3.99782 11.7215 4.00003 13.4981C3.99106 17.0016 6.26274 20.1033 9.60562 21.1519C12.9485 22.2005 16.5848 20.9519 18.5784 18.071C20.5721 15.1901 20.4593 11.3471 18.3 8.58811ZM12 19.4979C8.68632 19.4979 6.00003 16.8116 6.00003 13.4979C6.00003 10.1842 8.68632 7.49792 12 7.49792C15.3137 7.49792 18 10.1842 18 13.4979C18 15.0892 17.3679 16.6153 16.2427 17.7406C15.1174 18.8658 13.5913 19.4979 12 19.4979ZM10 4.49805H14C14.5523 4.49805 15 4.05033 15 3.49805C15 2.94576 14.5523 2.49805 14 2.49805H10C9.44774 2.49805 9.00003 2.94576 9.00003 3.49805C9.00003 4.05033 9.44774 4.49805 10 4.49805ZM13 10.4982C13 9.94588 12.5523 9.49817 12 9.49817C11.4477 9.49817 11 9.94588 11 10.4982V12.3882C10.5365 12.8028 10.3769 13.4605 10.5988 14.0414C10.8207 14.6224 11.3781 15.0062 12 15.0062C12.622 15.0062 13.1794 14.6224 13.4013 14.0414C13.6232 13.4605 13.4636 12.8028 13 12.3882V10.4982Z"
                fill="currentColor"
              />
            </g>
          </svg>
          <span class="text-sm font-medium"
            >{{ 'specialTile.averageSurveyTime' | translate }}:
            {{ timeToAnswer }}
            {{ timeToAnswer > 1 ? 'minutes' : ('minute' | translate) }}</span
          >
        </div>
      </div>
      <div class="relative flex justify-center space-x-2 md:space-x-16 actions">
        <button
          class="{{
            action?.prevent_skip
          }} text-sm font-bold w-36 md:text-base btn btn-secondary text-blue-dark skip"
          (click)="openSkipModal()"
          [disabled]="disableSkip || isSkipping"
          [attr.data-track]="'member-' + actionType + '-beginning-skip'"
          *ngIf="
            !mission?.prevent_skip &&
            (mission?.isGlobal === 'true' ? false : true) &&
            !isPreview
          "
          [ngClass]="{ loading: isSkipping }"
        >
          <span class="btn-label"> {{ 'general.skip' | translate }}</span>
          <span class="dot dot-1"></span>
          <span class="dot dot-2"></span>
          <span class="dot dot-3"></span>
        </button>
        <button
          class="animation-submit-btn"
          [ngClass]="{
            primarySection: !isLoading,
            loadingSection: isLoading
          }"
          (click)="getStarted()"
          [attr.data-track]="'member-' + actionType + '-beginning-get-started'"
          [disabled]="isSkipping || isLoading"
        >
          <div class="primaryTitle text" *ngIf="!isLoading">
            {{ 'general.getStarted' | translate }}
          </div>
          <div class="loadingTitle text" *ngIf="isLoading">Loading</div>
        </button>
      </div>
    </div>
  </div>
</div>
