import { Component, OnDestroy, OnInit } from '@angular/core';
import { finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  UserVerificationStatus
} from '@suzy/crowdtap/data-access/user';
import moment from 'moment/moment';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { Subject, Subscription, timer } from 'rxjs';

const EMAIL_EXPIRATION_TIME = 600;

@Component({
  selector: 'send-email-info',
  templateUrl: './send-email-info.component.html',
  styleUrls: ['./send-email-info.component.scss']
})
export class SendEmailInfoComponent implements OnInit, OnDestroy {
  isMobile$ = this.route.data.pipe(
    startWith({ mobile: false }),
    map(data => data.mobile)
  );
  timeLeft: number;
  timer$: Subscription;
  endUser: any;
  isLoading = true;
  isLinkResentEnabled = false;
  errorMsg: string;
  isError: boolean = false;
  unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private sdk: SuzySdkService,
    private router: Router
  ) {}

  ngOnInit() {
    this.auth
      .ensureUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(endUser => {
        this.endUser = endUser;
        this.findStepUserAt(endUser);
      });
  }

  findStepUserAt(endUser: any) {
    const userVerificationStatus = this.auth.getUserVerificationStatus(endUser);
    if (userVerificationStatus === UserVerificationStatus.EMAIL_CONFIRMED) {
      // if there is already active link, show the countdown
      const timeElapsed = this.calculatedTimeElapsed(endUser);
      if (timeElapsed >= 0 && timeElapsed < EMAIL_EXPIRATION_TIME) {
        this.timeLeft = EMAIL_EXPIRATION_TIME - timeElapsed;
        this.startCountdown();
        // If the time elapsed is greater than email expiration time, resend the email automatically
      } else if (timeElapsed > EMAIL_EXPIRATION_TIME) {
        this.isLinkResentEnabled = true;
        this.timeLeft = 0;
      }
    } else if (
      userVerificationStatus === UserVerificationStatus.PHONE_NOT_VERIFIED
    ) {
      this.router.navigate(['auth', 'get-verified']);
    } else {
      this.router.navigate(['/']);
    }
  }

  calculatedTimeElapsed(endUser: any): number {
    const emailConfirmationTime = moment(endUser.email_confirm_utc);
    return moment().diff(emailConfirmationTime, 'seconds');
  }

  startCountdown() {
    this.timer$ = timer(0, 1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.timer$.unsubscribe();
        }
      });
  }

  formatTime(): string {
    if (this.timeLeft === 0) {
      return '0 minutes';
    }
    const minutes = Math.ceil(this.timeLeft / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }

  verifyEmail(): void {
    this.isError = false;
    this.errorMsg = '';
    this.sdk.ProtocolRegister.verifyEmailStart({
      email: this.endUser.email
    })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (res.success) {
          this.isLinkResentEnabled = true;
          this.timeLeft = EMAIL_EXPIRATION_TIME;
          this.startCountdown();
        } else {
          this.isError = true;
          this.errorMsg = res.message;
        }
      });
  }

  onResendEmail(): void {
    this.verifyEmail();
  }

  ngOnDestroy() {
    this.timer$.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
