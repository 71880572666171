<header
  #navbar
  class="shadow-smBottom relative"
  [ngClass]="{
    'shadow-smDarkBottom': isDarkModeSelected$() | async
  }"
>
  <div class="container">
    <a routerLink="/" class="logo">
      <img
        class="block dark:hidden"
        data-track="member-anonymous-header-crowdtap-logo"
        src="../../../../../assets/img/crowdtap-logo-black.svg"
        alt="crowdtap"
      />
      <img
        class="hidden dark:block"
        data-track="member-anonymous-header-crowdtap-logo"
        src="../../../../../assets/crowdtap.svg"
        alt="crowdtap"
      />
    </a>

    <nav>
      <ul *ngIf="isAuth && !isAnonymousView && isShow">
        <li
          [ngClass]="{
            'z-99': isModalDisabled,
            'opacity-50': streak === undefined
          }"
          class="flex justify-center h-8 px-3 py-1 my-auto align-middle bg-box-light dark:bg-box-dark mt-px mr-2 sm:mr-4 rounded-2xl"
          *ngIf="enableStreak"
        >
          <img
            src="/assets/img/streak/fire.svg"
            alt="Streak"
            width="14"
            height="18"
          />
          <span
            id="current_streak"
            [ngClass]="{ scroll: isStreakChange, 'pl-1': streak !== undefined }"
            class="pl-1 text-sm font-bold transition-all dark:text-white sm:text-base"
            >{{ streak }}</span
          >
        </li>
        <li
          [ngClass]="{ 'z-99': isModalDisabled }"
          *ngIf="showChallenge"
          class="{{
            activeColorChallenge
          }}  points rounded-3xl coin-item mr-4 sm:mr-6 min-w-15 relative"
        >
          <div
            #toggleButtonChallenge
            (click)="toggleChallenge($event)"
            class="yellow-tip cursor-pointer rounded-3xl absolute -top-1"
          >
            <img
              src="/assets/img/challenge/challenge-pill-completed.svg"
              width="52px"
              height="36px"
              alt="Challenge"
              class=""
            />
          </div>
          <div
            *ngIf="isModalChallenge"
            class="absolute z-99 left-0 right-0 -bottom-8 w-5 sm:w-7 m-auto"
          >
            <svg
              class="fill-current text-box-light dark:text-box-dark w-5 h-8 sm:w-7 sm:h-5"
              viewBox="0 0 29 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="arrow-box"
                d="M28.2612 18.906L14.0175 7.66855e-07L0.738281 18.906L28.2612 18.906Z"
              />
            </svg>
          </div>
          <div
            (click)="$event.stopPropagation()"
            *ngIf="isModalChallenge"
            class="coin-modal w-86 sm:w-105 mt-2 sm:-mt-5 cursor-auto bg-box-light dark:bg-box-dark rounded-2xl top-10 sm:top-20 shadow-coinBoxShadow absolute text-center z-50 p-4 sm:p-6 -right-24 sm:-right-50 md:-right-44"
            [class.scrollbar]="challengeInfo.length > 2"
            #menu
          >
            <div
              (click)="isModalChallenge = false"
              class="absolute right-8 top-3 z-20 cursor-pointer"
            >
              <span
                class="crowdtap-multiply text-body-dark dark:text-body-light text-xl"
              ></span>
            </div>
            <!-- container for Challenge -->
            <div class="sm:mt-0 overflow-x-auto challenge-pill-container">
              <div
                *ngFor="let inProgressChallenge of challengeInfo"
                class="image-container-challenge-modal px-4 pt-4 pb-3 flex gap-6 rounded-lg bg-body-light dark:bg-body-dark mb-2 text-left"
                [ngClass]="{
                  'gap-4': inProgressChallenge?.challengeStage === CS.awarded,
                  'gap-6': inProgressChallenge?.challengeStage !== CS.awarded
                }"
              >
                <ng-container
                  *ngIf="
                    inProgressChallenge.challenge_type ===
                      challengeType.mission &&
                    inProgressChallenge?.challengeStage === CS.inProgress
                  "
                >
                  <div class="mt-2 challenge-badge">
                    <img
                      src="/assets/img/challenge/challenge-mission-badge.svg"
                      alt="Mission Challenge"
                    />
                  </div>
                  <div class="w-47 sm:w-60">
                    <div>
                      <div class="flex justify-between pb-2">
                        <h4
                          class="font-medium sm:font-bold dark:text-white"
                          *ngIf="!inProgressChallenge.special_tile"
                        >
                          {{ 'challenge.surveyChallenge' | translate }}
                        </h4>
                        <h4
                          class="font-medium sm:font-bold dark:text-white"
                          *ngIf="inProgressChallenge.special_tile"
                        >
                          {{ 'challenge.specialTileChallenge' | translate }}
                        </h4>
                        <div class="flex challenge-coin">
                          <div
                            class="m-auto font-bold text-light-black text-3xs"
                          >
                            +{{ inProgressChallenge?.points }}
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center justify-between">
                        <div
                          class="progressBar h-2 pt-px pl-px rounded-full w-36 sm:w-52"
                          [ngClass]="{
                            'bg-gray': isDarkModeSelected$() | async,
                            'bg-ce-gray': !(isDarkModeSelected$() | async)
                          }"
                        >
                          <div
                            class="bg-progress-2 progressBar h-1.5 rounded-full"
                            [ngStyle]="{
                              width:
                                (inProgressChallenge?.progress /
                                  inProgressChallenge?.required) *
                                  100 +
                                '%'
                            }"
                          ></div>
                        </div>
                        <div
                          class="text-xxs mr-0.5 text-box-dark font-medium dark:text-white"
                        >
                          {{ inProgressChallenge?.progress }}/{{
                            inProgressChallenge?.required
                          }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="text-xs mt-2 text-box-dark font-medium dark:text-white"
                    >
                      <span *ngIf="!inProgressChallenge.special_tile">
                        {{
                          'challenge.completeSurveys'
                            | translate
                              : {
                                  surveys: inProgressChallenge?.required
                                }
                        }}
                      </span>
                      <span *ngIf="inProgressChallenge.special_tile">
                        {{
                          'challenge.completeSpecialTileSurveys'
                            | translate
                              : {
                                  surveys: inProgressChallenge?.required
                                }
                        }}
                      </span>
                    </div>
                    <div
                      *ngIf="inProgressChallenge.end_utc"
                      class="text-xxs mt-2 text-box-dark font-medium ce-gray dark:text-white"
                    >
                      <span>
                        {{
                          'challenge.hurry'
                            | translate
                              : {
                                  remaining:
                                    calcTimeRemaining(inProgressChallenge)
                                }
                        }}
                      </span>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    inProgressChallenge.challenge_type ===
                      challengeType.streak &&
                    inProgressChallenge?.challengeStage === CS.inProgress
                  "
                >
                  <div class="mt-2 challenge-badge">
                    <img
                      src="/assets/img/challenge/challenge-streak-badge.svg"
                      alt="Streak Challenge"
                    />
                  </div>
                  <div class="w-47 sm:w-60">
                    <div class="flex justify-between pb-2">
                      <h4 class="font-medium sm:font-bold dark:text-white">
                        {{ 'challenge.streakChallenge' | translate }}
                      </h4>
                      <div class="flex challenge-coin">
                        <div class="m-auto font-bold text-light-black text-3xs">
                          +{{ inProgressChallenge?.points }}
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="inProgressChallenge?.required <= 7"
                      class="flex gap-1.5 sm:gap-2"
                    >
                      <div
                        *ngFor="
                          let _ of [].constructor(
                            inProgressChallenge?.required
                          );
                          let f = index
                        "
                        class="streak-container flex rounded-md bg-box-light dark:bg-box-dark"
                      >
                        <div
                          class="full-streak"
                          [ngClass]="{
                            'empty-streak': f >= inProgressChallenge?.progress,
                            'empty-streak-dark':
                              f >= inProgressChallenge?.progress &&
                                isDarkModeSelected$() | async
                          }"
                        >
                          &nbsp;
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="inProgressChallenge?.required > 7"
                      class="flex items-center justify-between"
                    >
                      <div
                        class="progressBar h-2 pt-px pl-px rounded-full w-36 sm:w-52"
                        [ngClass]="{
                          'bg-gray': isDarkModeSelected$() | async,
                          'bg-ce-gray': !(isDarkModeSelected$() | async)
                        }"
                      >
                        <div
                          class="bg-progress-2 progressBar h-1.5 rounded-full"
                          [ngStyle]="{
                            width:
                              (inProgressChallenge?.progress /
                                inProgressChallenge?.required) *
                                100 +
                              '%'
                          }"
                        ></div>
                      </div>
                      <div
                        class="text-xxs mr-0.5 text-box-dark font-medium dark:text-white"
                      >
                        {{ inProgressChallenge?.progress }}/{{
                          inProgressChallenge?.required
                        }}
                      </div>
                    </div>
                    <div
                      class="text-xs mt-2 text-box-dark font-medium dark:text-white"
                    >
                      <span>
                        {{
                          'challenge.answerASurveyDays'
                            | translate
                              : {
                                  days: inProgressChallenge?.required
                                }
                        }}
                      </span>
                    </div>
                    <div
                      class="text-xxs mt-2 text-box-dark font-medium ce-gray dark:text-white"
                    >
                      <span>
                        {{
                          'challenge.dayEnds'
                            | translate
                              : {
                                  remaining:
                                    calcTimeRemaining(inProgressChallenge)
                                }
                        }}
                      </span>
                    </div>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="inProgressChallenge?.challengeStage === CS.awarded"
                >
                  <div
                    class="challenge-check"
                    [ngClass]="{
                      'challenge-check-dark': isDarkModeSelected$() | async,
                      'challenge-check-light': !(isDarkModeSelected$() | async)
                    }"
                  ></div>
                  <div class="w-47 sm:w-64 mt-0 sm:mt-2">
                    <h4 class="text-sm font-medium dark:text-white">
                      {{ 'challenge.youveCompletedAll' | translate }}
                    </h4>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </li>
        <li
          [ngClass]="{ 'z-99': isModalDisabled }"
          class="{{
            activeColor
          }}  points rounded-3xl coin-item sm:mr-6 relative"
        >
          <svg
            [ngClass]="{
              hidden: isCompleted && !isGoalSet,
              block: !isCompleted && isGoalSet
            }"
            class="pill data-container-goal"
            id="data-container"
            xmlns="http://www.w3.org/2000/svg"
            width="96"
            height="40"
            data-value="5"
          >
            <path
              style="transform: matrix(1.04878, 0, 0, 1, 6.5, 0)"
              class="bg opacity-20"
              stroke="#DBDADE"
              d="M41 2H62C72.5 2.5 79.5 9.5 80 19C80.2118 23.0256 80 36.5 62 38H45.5H19C13.1666 37.6667 1.59998 32.9 1.99998 18.5C1.99998 13 7.49998 2.5 19 2H42"
              fill="none"
            />
            <path
              style="transform: matrix(1.04878, 0, 0, 1, 6.5, 0)"
              class="meter {{ activeColor }}"
              d="M41 2H62C72.5 2.5 79.5 9.5 80 19C80.2118 23.0256 80 36.5 62 38H45.5H19C13.1666 37.6667 1.59998 32.9 1.99998 18.5C1.99998 13 7.49998 2.5 19 2H42"
              fill="none"
              stroke-dasharray="199"
              stroke-dashoffset="199"
            />
          </svg>
          <div *ngIf="isCompleted && !isGoalSet" class="ripples">&nbsp;</div>
          <svg
            *ngIf="isCompleted && !isGoalSet"
            xmlns="http://www.w3.org/2000/svg"
            class="pill"
            width="96"
            height="40"
            data-value="100"
          >
            <path
              style="transform: matrix(1.04878, 0, 0, 1, 6.5, 0)"
              class="bg {{ activeColor }}"
              d="M41 2H62C72.5 2.5 79.5 9.5 80 19C80.2118 23.0256 80 36.5 62 38H45.5H19C13.1666 37.6667 1.59998 32.9 1.99998 18.5C1.99998 13 7.49998 2.5 19 2H42"
              fill="none"
            />
            <path
              style="transform: matrix(1.04878, 0, 0, 1, 6.5, 0)"
              class="{{ activeColor }}"
              d="M41 2H62C72.5 2.5 79.5 9.5 80 19C80.2118 23.0256 80 36.5 62 38H45.5H19C13.1666 37.6667 1.59998 32.9 1.99998 18.5C1.99998 13 7.49998 2.5 19 2H42"
              fill="none"
              stroke-dasharray="199"
              stroke-dashoffset="199"
            />
          </svg>
          <div
            #toggleButton
            (click)="!isModalDisabled && $event.stopPropagation(); toggle()"
            class="yellow-tip cursor-pointer flex relative justify-center px-1 py-0.5 align-middle bg-yellow-light rounded-3xl"
          >
            <img
              src="/assets/img/svg/icons/ic-type-sidebar-coin.svg"
              width="18px"
              height="18px"
              alt="Coins"
            />

            <span
              [ngClass]="{ scroll: isChange }"
              class="min-w-12 pl-1 text-sm font-bold transition-all text-black sm:text-base"
              >{{ total_points == -1 ? '' : total_points }}</span
            >
          </div>

          <div class="tooltip-animated" *ngIf="isToolTip">
            <p
              class="tooltip transition-all duration-200 w-68 bg-blue-dark rounded-lg absolute text-center z-50 p-2 -right-12 text-xs text-body-light font-semibold"
              *ngIf="last_reward_utc != ''"
            >
              {{ 'general.seeYourProgress' | translate }}
            </p>

            <p
              class="tooltip transition-all duration-200 w-68 bg-blue-dark rounded-lg absolute text-center z-50 p-2 -right-12 text-xs text-body-light font-semibold"
              *ngIf="last_reward_utc === '' && showMilestoneTooltip"
            >
              {{ 'general.youReachedYourGoal' | translate }}
            </p>
          </div>
          <div
            *ngIf="isModal"
            class="absolute z-99 left-0 right-0 -bottom-8 w-5 sm:w-7 m-auto"
          >
            <svg
              class="fill-current text-body-light dark:text-box-dark w-5 h-8 sm:w-7 sm:h-5"
              viewBox="0 0 29 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="arrow-box"
                d="M28.2612 18.906L14.0175 7.66855e-07L0.738281 18.906L28.2612 18.906Z"
              />
            </svg>
          </div>
          <div
            (click)="$event.stopPropagation()"
            *ngIf="isModal"
            class="coin-modal w-81 sm:w-95 mt-2 sm:-mt-5 cursor-auto bg-body-light dark:bg-box-dark rounded-2xl top-10 sm:top-20 shadow-coinBoxShadow absolute text-center z-50 p-4 -right-6 sm:-right-12 md:-right-20"
            #menu
          >
            <div
              (click)="isModal = false"
              class="absolute right-3 top-2 z-20 cursor-pointer"
            >
              <span
                class="crowdtap-multiply text-body-dark dark:text-body-light"
              ></span>
            </div>
            <!-- container for setGoal -->
            <div *ngIf="isLoading; else empty">
              <p
                class="text-lg sm:text-xl text-body-dark dark:text-body-light font-semibold mb-5"
              >
                <img
                  *ngIf="!isGoalModal || !showRewardGoal"
                  class="inline-block"
                  src="/assets/img/svg/icons/ic-type-sidebar-coin.svg"
                  alt="Coins"
                />
                {{ 'popupModal.myPoint' | translate }}
              </p>
              <p
                *ngIf="!isGoalModal || !showRewardGoal"
                class="text-sm px-2 sm:px-4 text-body-dark dark:text-body-light font-semibold mb-4 mt-8"
              >
                {{
                  'popupModal.onlyPoint'
                    | translate
                      : {
                          earnPoint: activeStep - total_points
                        }
                }}
              </p>
              <!-- progress bar for setGoal -->

              <div
                [ngClass]="{
                  'rounded-lg bg-box-light dark:bg-black-light':
                    isGoalModal && showRewardGoal
                }"
                class="mb-4 p-3 sm:p-5 pt-4 sm:pt-4"
              >
                <div *ngIf="isGoalSet && showRewardGoal">
                  <div class="img-container">
                    <img
                      class="m-auto rounded-lg"
                      [src]="
                        goalInfo?.enabled
                          ? goalInfo?.photo?.thumbs?.admin?.url
                          : '/assets/img/na.png'
                      "
                      alt="Reward logo"
                    />
                  </div>
                  <div
                    class="text-body-dark dark:text-body-light text-xs font-semibold my-3"
                  >
                    {{ goalInfo?.reward_name }}

                    <button
                      [hidden]="!isEdit"
                      type="button"
                      (click)="openRewardModal(); segmentEditGoal()"
                      class="ml-2 text-sm font-semibold text-blue-dark"
                    >
                      {{ 'general.edit' | translate }}
                    </button>
                  </div>
                </div>

                <ng-container
                  *ngIf="!goalInfo || goalInfo.enabled; else giftInvalid"
                >
                  <div
                    class="flex space-x-6"
                    [ngClass]="{
                      'max-w-69 m-auto': isGoalSet && showRewardGoal
                    }"
                  >
                    <ng-container *ngIf="!isGoalSet || !showRewardGoal">
                      <div
                        class="w-full bg-light-gray mt-0.5 rounded-full h-2.5 relative"
                      >
                        <div
                          class="bg-{{ activeColor }} progressBar"
                          [ngClass]="{
                            'h-2.5 rounded-full':
                              !isGoalModal || !showRewardGoal,
                            'h-1.5 m-0.5 rounded-l-full rounded-r-none':
                              isGoalModal && showRewardGoal,
                            'rounded-r-full': progressBarValue === 93
                          }"
                          [ngStyle]="{ width: progressBarValue + '%' }"
                        ></div>

                        <p
                          *ngIf="total_points != -1"
                          class="text-box-dark dark:text-box-light text-left text-xs font-semibold mt-2"
                        >
                          {{ total_points }}/{{ activeStep }}
                        </p>
                      </div>
                      <div>
                        <p
                          class="relative bg-{{
                            activeColor
                          }}  text-box-dark -top-0.5 w-12 h-8  flex items-center justify-center rounded-sm font-semibold text-sm"
                        >
                          ${{ dollerValue }}
                        </p>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="isGoalSet && showRewardGoal">
                      <div
                        class="w-full bg-body-light rounded-full h-2.5 relative"
                      >
                        <ul
                          [ngClass]="
                            goalInfo?.reward_value > 5 ? 'visible' : 'invisible'
                          "
                          class="flex justify-evenly w-full relative z-10 last-circle"
                        >
                          <li class="step"></li>
                          <li class="step"></li>
                          <li class="step"></li>
                          <li class="step"></li>
                          <li class="step"></li>
                        </ul>
                        <div
                          class="bg-progress-2 absolute top-0 h-1.5 m-0.5 rounded-l-full"
                          [ngClass]="{
                            'rounded-full': goalProgressBarValue === 98
                          }"
                          [ngStyle]="{ width: goalProgressBarValue + '%' }"
                        ></div>
                        <p
                          *ngIf="
                            goalInfo?.points_required <= total_points;
                            else rewardPoints
                          "
                          class="text-box-dark dark:text-box-light text-left text-xs font-semibold mt-2"
                        >
                          {{ 'rewards.youReadyToRedeem' | translate }}
                        </p>

                        <ng-template #rewardPoints>
                          <p
                            class="text-box-dark dark:text-box-light text-left text-xs font-semibold mt-2"
                          >
                            {{ total_points }}/{{ goalInfo?.points_required }}
                          </p>
                        </ng-template>
                      </div>
                      <div class="doller-wrapper mt-0.5">
                        <p
                          class="bg-progress-2 relative text-box-dark flex items-center justify-center rounded-sm font-semibold text-sm"
                        >
                          ${{ goalInfo?.reward_value }}
                        </p>
                      </div>
                    </ng-container>
                  </div>
                  <button
                    *ngIf="
                      isGoalSet &&
                      goalInfo?.points_required <= total_points &&
                      showRewardGoal
                    "
                    type="button"
                    class="px-6 h-10 mt-10 bg-blue-dark text-white text-sm font-bold m-auto rounded-full"
                    aria-label="Close"
                    (click)="isModal = false; openClaimModal()"
                  >
                    {{ 'rewards.claminGiftCard' | translate }}
                  </button>
                </ng-container>
                <ng-template #giftInvalid>
                  <div
                    class="font-semibold align-middle text-error text-xs mb-5"
                  >
                    <i
                      class="crowdtap-exclamation-circle text-base align-sub leading-1.2"
                    ></i>
                    {{
                      'rewards.cardNoLonger'
                        | translate : { name: goalInfo?.reward_name }
                    }}
                  </div>

                  <button
                    type="button"
                    class="px-6 h-10 bg-blue-dark text-white text-sm font-bold m-auto rounded-full"
                    aria-label="Close"
                    (click)="openRewardModal(); segmentUpdateGoal()"
                  >
                    {{ 'rewards.updateReward' | translate }}
                  </button>
                </ng-template>
              </div>

              <div
                *ngIf="!isGoalSet && isGoalModal && showRewardGoal"
                class="sm:space-x-3 flex pt-3 pb-4 sm:pb-5 px-2 sm:px-4 items-center justify-between rounded-lg bg-blue-dark"
              >
                <div class="mt-2 text-left">
                  <p class="text-sm font-bold text-body-light">
                    {{ 'popupModal.WantToEarn' | translate }}
                  </p>
                  <p class="my-2 text-xs font-semibold text-body-light">
                    {{ 'popupModal.stayMotivated' | translate }}
                  </p>
                  <button
                    type="button"
                    (click)="openRewardModal(); segmentSetGoal()"
                    class="mt-1 text-xs bg-white text-blue-dark font-bold rounded-full h-9 w-24"
                  >
                    {{ 'popupModal.setGoal' | translate }}
                  </button>
                </div>
                <img src="/assets/img/rewards/goal.svg" class="w-14" />
              </div>

              <p
                class="text-xs text-body-dark px-2 sm:px-4 leading-normal dark:text-body-light font-semibold mt-3"
              >
                <span class="block" *ngIf="!isGoalModal || !showRewardGoal">
                  {{ 'popupModal.readyToRedeem' | translate }}</span
                >
                {{ 'popupModal.redeemYourPoint' | translate }}
                <a routerLink="/rewards" class="text-blue-dark">{{
                  'popupModal.here' | translate
                }}</a>
              </p>
            </div>
            <ng-template #empty>
              <p
                class="text-lg sm:text-xl text-body-dark dark:text-body-light font-semibold my-5"
              >
                {{ 'rewards.loading' | translate }}
              </p>
            </ng-template>
          </div>
        </li>
        <li
          class="relative hidden sm:flex items-center"
          *ngIf="viewKind !== 'signup'"
        >
          <button
            class="w-6 h-6 sm:w-8 sm:h-8 profile-menu-trigger"
            (click)="showProfileMenu($event)"
          >
            <img
              class="object-cover w-6 h-6 rounded-full sm:w-8 sm:h-8"
              [src]="
                userData?.profile_photo?.thumbs
                  ? userData?.profile_photo?.thumbs?.admin?.url
                  : '/assets/img/profile.jpeg'
              "
              alt="Profile"
            />
          </button>
          <app-profile-menu class="hidden sm:block"></app-profile-menu>
        </li>
      </ul>

      <ul class="anonymous-nav" *ngIf="isAnonymousView">
        <li *ngIf="viewKind !== 'login' && !isVerifiedView">
          <a
            routerLink="/auth/login"
            data-track="member-anonymous-header-login-link"
            >{{ 'auth.login' | translate }}</a
          >
        </li>
        <li *ngIf="viewKind !== 'signup' && !isVerifiedView">
          <button
            class="submit-btn"
            [routerLink]="['/auth/account-create-email']"
            data-track="member-anonymous-header-signup-btn"
            [title]="'auth.signUp' | translate"
          >
            {{ 'auth.signUp' | translate }}
          </button>
        </li>
        <li *ngIf="isVerifiedView">
          <button
            class="text-btn"
            (click)="logoutHandler()"
            data-track="member-anonymous-header-signup-btn"
          >
            {{ 'auth.logout' | translate }}
          </button>
        </li>
      </ul>
    </nav>
  </div>

  <ng-template #giftCardClaim let-c="close" let-d="dismiss">
    <div class="detail-modal relative">
      <div>
        <button
          type="button"
          class="close-btn absolute -top-6 right-0"
          aria-label="Close"
          (click)="d('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4>{{ 'rewards.claim' | translate }}</h4>
      </div>

      <div class="image-container-modal m-auto">
        <img
          class="my-3 m-auto rounded-lg object-cover border dark:border-white-line border-black-line"
          [src]="goalInfo?.photo?.thumbs?.admin?.url"
          alt="Reward logo"
        />
        <div
          class="text-xs my-6 text-box-dark dark:text-box-light font-semibold"
        >
          ${{ goalInfo?.reward_value }} {{ goalInfo?.reward_name }}
        </div>
      </div>
      <div *ngIf="isVerification; else notCode">
        <div
          class="pb-6 m-auto max-w-72 text-sm text-box-dark dark:text-box-light font-medium"
        >
          {{
            'rewards.weJustVerificationCode'
              | translate : { mobileNumber: mobileNumber.slice(-4) }
          }}
        </div>
        <div class="inline-block w-full mb-6 px-2">
          <div class="form-group text-left">
            <label
              [ngClass]="{ errorCode: errormessage }"
              class="text-light-black dark:text-white font-semibold text-xs"
            >
              {{ 'rewards.verificationCode' | translate }}:
            </label>

            <input
              type="text"
              class="font-bold block w-full p-2 mt-2 border rounded-md text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
              placeholder="{{ 'rewards.enterYourCode' | translate }}"
              maxlength="6"
              [(ngModel)]="verificationCode"
              (keypress)="allowOnlynumber($event)"
            />

            <small
              *ngIf="errormessage"
              class="form-text text-muted errorCode font-semibold text-xs"
            >
              {{ errormessage | translate }}
            </small>
            <div
              *ngIf="isSent"
              class="w-full mt-6 flex items-center space-x-4 text-xs font-semibold"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="ng-star-inserted"
              >
                <path
                  _ngcontent-saj-c306=""
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.72 7.28996L8.42998 11.59L6.77998 9.93997C6.53559 9.65459 6.15187 9.53028 5.78656 9.61815C5.42126 9.70603 5.13605 9.99125 5.04817 10.3565C4.9603 10.7218 5.08461 11.1056 5.36998 11.35L7.71998 13.71C7.9087 13.8972 8.16418 14.0015 8.42998 14C8.69232 13.9989 8.94372 13.8947 9.12998 13.71L14.13 8.70996C14.3193 8.5222 14.4258 8.2666 14.4258 7.99996C14.4258 7.73333 14.3193 7.47773 14.13 7.28996C13.7399 6.90224 13.11 6.90224 12.72 7.28996ZM10 0.5C4.47715 0.5 0 4.97715 0 10.5C0 16.0228 4.47715 20.5 10 20.5C15.5228 20.5 20 16.0228 20 10.5C20 7.84783 18.9464 5.3043 17.0711 3.42893C15.1957 1.55357 12.6522 0.5 10 0.5Z"
                  fill="#68C97F"
                ></path>
              </svg>
              <span
                class="text-box-dark dark:text-box-light whitespace-pre-wrap font-semibold"
                >{{ 'rewards.weHaveResentYourCode' | translate }}</span
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="isResent"
          (click)="
            phoneNumberVerification ? resentPhoneVerificationOtp() : resentOtp()
          "
          class="text-blue-dark font-semibold text-sm cursor-pointer mb-6"
        >
          {{ 'rewards.resendCode' | translate }}
          {{ isResentLoading ? '...' : '' }}
        </div>
        <div class="flex justify-center items-baseline mt-4 max-w-59 m-auto">
          <button
            type="button"
            data-track="member-redeem-redeem-btn"
            class="bg-blue-dark relative text-white w-44 h-12 text-base font-bold rounded-full"
            (click)="
              phoneNumberVerification
                ? submitPhoneVerificationOtp()
                : submitOtp()
            "
            [disabled]="isClaimLoading"
          >
            {{ 'rewards.submitCode' | translate }}

            <div class="absolute left-5 button-spinner top-6">
              <suzy-spinner
                *ngIf="isClaimLoading"
                size="small"
                button="true"
                [round]="6"
                [color]="'#ffffff'"
                [overlay]="false"
              >
              </suzy-spinner>
            </div>
          </button>
        </div>
        <div
          class="font-semibold text-box-dark dark:text-box-light text-xs mt-6"
        >
          {{ 'rewards.needHelpContact' | translate }}
          <a
            href="https://support.crowdtap.com/hc/en-us"
            target="_blank"
            class="text-blue-dark"
            >{{ 'rewards.customerSupport' | translate }}</a
          >
        </div>
      </div>
      <ng-template #notCode>
        <div
          class="check-container p-2 max-w-88 m-auto mb-2"
          [ngClass]="{ 'legal-error': checkedLegal ? '' : showLegalError }"
        >
          <div class="check-row">
            <input
              type="checkbox"
              class="styled-checkbox"
              name=""
              id="check-1"
              [(ngModel)]="checkedLegal"
            />
            <label class="dark:text-body-light before:border-b" for="check-1">
              <span>
                {{ 'rewards.rewardTerms' | translate }}
                <a routerLink="/terms-of-service" target="_blank">{{
                  'rewards.termsOfService' | translate
                }}</a>
              </span>
            </label>
          </div>
          <div class="text-error dark:text-error text-xs font-semibold error">
            {{ 'general.required' | translate }}
          </div>
        </div>
        <div class="flex justify-center items-baseline mt-4 max-w-59 m-auto">
          <button
            type="button"
            data-track="member-redeem-redeem-btn"
            class="bg-blue-dark relative text-white w-44 h-12 text-base font-bold rounded-full"
            (click)="claimGiftCard()"
            [disabled]="isClaimLoading"
          >
            {{ 'rewards.claim' | translate }} ${{ goalInfo?.reward_value }}

            <div class="absolute left-5 button-spinner top-6">
              <suzy-spinner
                *ngIf="isClaimLoading"
                size="small"
                button="true"
                [round]="6"
                [color]="'#ffffff'"
                [overlay]="false"
              >
              </suzy-spinner>
            </div>
          </button>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #giftCardClaimSuccess let-c="close" let-d="dismiss">
    <div class="detail-modal success-modal relative">
      <div>
        <button
          type="button"
          class="close-btn absolute -top-6 right-0"
          aria-label="Close"
          (click)="d('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4>{{ 'rewards.yourCardHasBeen' | translate }}</h4>
      </div>
      <div class="image-container-modal m-auto">
        <img
          class="my-3 m-auto rounded-lg object-cover border dark:border-white-line border-black-line"
          [src]="goalInfo?.photo?.thumbs?.admin?.url"
          alt="Reward logo"
        />
        <div
          class="text-xs my-6 text-box-dark dark:text-box-light font-semibold"
        >
          ${{ goalInfo?.reward_value }} {{ goalInfo?.reward_name }}
        </div>
      </div>

      <div class="text-sm font-medium mb-6 text-box-dark dark:text-box-light">
        {{
          'rewards.yourGiftCardWillEmailed'
            | translate
              : {
                  email: userData?.email,
                  fromEmail:
                    selectedRewardValue?.external_system === 'tango-card'
                      ? 'noreply@tangocard.com '
                      : 'rewards@reward.tremendous.com'
                }
        }}
      </div>
      <div>
        <button
          type="button"
          class="bg-blue-dark rounded-full text-sm w-26 h-10 block text-white font-bold m-auto"
          (click)="onCloseSuccessModal()"
        >
          {{ 'general.ok' | translate }}
        </button>
        <button
          class="link-btn block m-auto mt-6 text-blue-dark font-semibold text-sm"
          (click)="redirectToStatus()"
        >
          {{ 'rewards.viewRewardStatus' | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #giftCardClaimFailed let-c="close" let-d="dismiss">
    <div class="detail-modal failed-modal">
      <div class="modal-header">
        <button
          type="button"
          class="close-btn"
          aria-label="Close"
          (click)="d('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <span
        class="crowdtap-exclamation-circle text-5xl md:text-7xl text-active-link-dark dark:text-body-light"
      ></span>

      <h4 class="text-lg md:text-xl font-bold mt-2">
        {{ 'rewards.somethingWentWrong' | translate }}
      </h4>
      <p class="text-xs font-semibold">
        {{ 'rewards.encounteredMessage' | translate }}
        <a
          href="https://support.crowdtap.com/hc/en-us"
          target="_blank"
          class="link"
          >contact support</a
        >.
      </p>
      <div class="actions">
        <button
          type="button"
          class="bg-blue-dark text-white rounded-full w-26 h-10"
          aria-label="Close"
          (click)="d('Cross click')"
        >
          {{ 'general.gotIt' | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #reachedGoal let-c="close" let-d="dismiss">
    <div class="detail-modal overflow-hidden relative p-8">
      <div class="modal-header relative">
        <button
          type="button"
          aria-label="Close"
          (click)="d('Cross click')"
          class="absolute -top-6 -right-4 sm:-right-3 sm:-top-5 z-20 cursor-pointer"
        >
          <span
            class="crowdtap-multiply text-body-dark dark:text-body-light text-lg font-bold"
          ></span>
        </button>
      </div>
      <div *ngIf="confetti">
        <ng-lottie
          (animationCreated)="animationCreated($event)"
          [options]="options"
          class="inset-0 w-full h-full absolute"
        >
        </ng-lottie>
      </div>
      <div [@ease]>
        <h1 class="text-box-dark dark:text-box-light text-2xl font-bold">
          {{ 'general.youReached' | translate }}
        </h1>
        <div
          class="mt-1 mb-5 text-sm text-box-dark dark:text-box-light font-medium"
        >
          {{
            'general.youJustReached'
              | translate
                : { value: goalInfo?.reward_value, name: goalInfo.reward_name }
          }}
        </div>
      </div>
      <div [@easeInOut]>
        <div class="image-container-modal">
          <img
            [src]="goalInfo?.photo?.thumbs?.admin?.url"
            alt="Reward logo"
            class="object-cover border dark:border-white-line border-black-line"
          />
        </div>
        <div class="text-xs text-box-dark dark:text-box-light font-semibold">
          {{ goalInfo?.reward_name }}
        </div>

        <div class="flex space-x-6 rounded-lg my-4 w-72 m-auto">
          <div
            class="w-full bg-light-gray mt-2 rounded-full h-2.5 relative mb-5"
          >
            <ul
              [ngClass]="goalInfo?.reward_value > 5 ? 'visible' : 'invisible'"
              class="flex justify-evenly w-full relative z-10 last-circle"
            >
              <li class="step"></li>
              <li class="step"></li>
              <li class="step"></li>
              <li class="step"></li>
              <li class="step"></li>
            </ul>

            <div
              class="bg-progress-2 absolute top-0 h-1.5 m-0.5 rounded-l-full"
              [ngClass]="{
                'rounded-full': goalProgressBarValue === 98
              }"
              [ngStyle]="{ width: goalProgressBarValue + '%' }"
            ></div>
            <div
              [@ease]
              class="text-box-dark dark:text-box-light text-center text-xs font-semibold mt-2"
            >
              {{ 'rewards.readyYourRedeem' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="actions mt-6" [@outIn]>
        <button
          type="button"
          aria-label="Close"
          data-track="member-redeem-redeem-btn"
          class="font-bold relative text-white text-sm rounded-full w-24 h-10 mt-2 bg-blue-dark"
          (click)="openClaimModal()"
        >
          {{ 'rewards.claim' | translate }} ${{ goalInfo?.reward_value }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #challengeStart let-c="close" let-d="dismiss">
    <div
      class="detail-modal relative"
      [ngClass]="{ 'sm:px-10': availableChallengeInfo.length > 1 }"
    >
      <div>
        <button
          type="button"
          class="close-btn available-challenge-close-button absolute -top-4 right-0"
          [ngClass]="{ '-top-6 right-4': availableChallengeInfo.length === 1 }"
          aria-label="Close"
          (click)="d('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div
        *ngIf="availableChallengeInfo.length === 1"
        class="image-container-challenge-modal m-auto"
      >
        <img
          *ngIf="
            availableChallengeInfo[0].challenge_type === challengeType.mission
          "
          src="/assets/img/challenge/challenge-mission-badge.svg"
          class="mx-auto"
        />
        <img
          *ngIf="
            availableChallengeInfo[0].challenge_type === challengeType.streak
          "
          src="/assets/img/challenge/challenge-streak-badge.svg"
          class="mx-auto"
        />
        <div
          class="text-box-dark dark:text-box-light text-xl sm:text-2xl font-bold mt-4"
        >
          <h4>
            {{
              'challenge.earnBonusPointsToday'
                | translate
                  : {
                      points: availableChallengeInfo[0].points
                    }
            }}
          </h4>
        </div>
        <div class="text-xs my-4 text-box-dark font-medium dark:text-white">
          <ng-container
            *ngIf="
              availableChallengeInfo[0].challenge_type ===
                challengeType.mission && !availableChallengeInfo[0].special_tile
            "
          >
            {{
              'challenge.completeSurveysGetPoints'
                | translate
                  : {
                      surveys: availableChallengeInfo[0]?.required,
                      points: availableChallengeInfo[0]?.points
                    }
            }}
          </ng-container>
          <ng-container
            *ngIf="
              availableChallengeInfo[0].challenge_type ===
                challengeType.mission && availableChallengeInfo[0].special_tile
            "
          >
            {{
              'challenge.completeSpecialTileSurveysGetPoints'
                | translate
                  : {
                      surveys: availableChallengeInfo[0]?.required,
                      points: availableChallengeInfo[0]?.points
                    }
            }}
          </ng-container>
          <ng-container
            *ngIf="
              availableChallengeInfo[0].challenge_type === challengeType.streak
            "
          >
            {{
              'challenge.answerSurveysGetPoints'
                | translate
                  : {
                      days: availableChallengeInfo[0]?.required,
                      points: availableChallengeInfo[0]?.points
                    }
            }}
          </ng-container>
        </div>
      </div>

      <ng-container *ngIf="availableChallengeInfo.length > 1">
        <div class="header mb-5">
          <span class="text-xl sm:text-2xl font-semibold dark:text-white">{{
            'challenge.moreWaysToEarnPoints' | translate
          }}</span>
        </div>
        <div
          class="available-challenges-container overflow-x-auto"
          [class.scrollbar]="availableChallengeInfo.length > 2"
        >
          <div
            *ngFor="let challengeStarting of availableChallengeInfo"
            class="image-container-challenge-modal flex p-4 gap-4 mb-2 rounded-lg text-left bg-box-light dark:bg-box-dark"
          >
            <ng-container
              *ngIf="challengeStarting.challenge_type === challengeType.mission"
            >
              <img
                src="/assets/img/challenge/challenge-mission-badge.svg"
                class="mobile-badge"
              />
              <div>
                <div
                  class="text-sm sm:text-lg font-semibold mt-2 dark:text-white"
                  *ngIf="!challengeStarting.special_tile"
                >
                  {{
                    'challenge.completeSurveys'
                      | translate
                        : {
                            surveys: challengeStarting?.required
                          }
                  }}
                </div>
                <div
                  class="text-sm sm:text-lg font-semibold mt-2 dark:text-white"
                  *ngIf="challengeStarting.special_tile"
                >
                  {{
                    'challenge.completeSpecialTileSurveys'
                      | translate
                        : {
                            surveys: challengeStarting?.required
                          }
                  }}
                </div>
                <div class="text-sm mt-2 font-medium dark:text-white">
                  {{
                    'challenge.bonusPoints'
                      | translate
                        : {
                            points: challengeStarting?.points
                          }
                  }}
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="challengeStarting.challenge_type === challengeType.streak"
            >
              <img
                src="/assets/img/challenge/challenge-streak-badge.svg"
                class="mobile-badge"
              />
              <div>
                <div
                  class="text-sm sm:text-lg font-semibold mt-2 dark:text-white"
                >
                  {{
                    'challenge.answerASurveyDays'
                      | translate
                        : {
                            days: challengeStarting?.required
                          }
                  }}
                </div>
                <div class="text-sm mt-2 font-medium dark:text-white">
                  {{
                    'challenge.bonusPoints'
                      | translate
                        : {
                            points: challengeStarting?.points
                          }
                  }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <button
        type="button"
        class="font-bold relative text-white text-sm rounded-full w-32 sm:w-35 h-12 sm:h-14 mt-4 bg-blue-dark"
        [ngClass]="{
            'w-32' : availableChallengeInfo.length > 1,
            'w-35' : availableChallengeInfo.length === 1,
          }"
        (click)="startChallenge(true)"
      >
        {{ 'challenge.letsGo' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #giftCardClaimVerifyPhone let-c="close" let-d="dismiss">
    <div class="detail-modal relative">
      <div>
        <button
          type="button"
          class="close-btn absolute -top-6 right-0"
          aria-label="Close"
          (click)="d('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4>
          {{ 'redeemMobileVerificationDialog.claimAndVerify' | translate }}
        </h4>
      </div>

      <div class="image-container-modal m-auto">
        <img
          class="my-3 m-auto rounded-lg object-cover border dark:border-white-line border-black-line"
          [src]="goalInfo?.photo?.thumbs?.admin?.url"
          alt="Reward logo"
        />
        <div
          class="text-xs my-6 text-box-dark dark:text-box-light font-semibold"
        >
          ${{ goalInfo?.reward_value }} {{ goalInfo?.reward_name }}
        </div>
      </div>

      <div class="mt-4">
        <p
          class="font-weight-bold modal-sub-title text-box-dark dark:text-box-light mt-5 text-center"
        >
          {{ 'redeemMobileVerificationDialog.askForNumber' | translate }}
        </p>
      </div>

      <form [formGroup]="sendPhoneForm">
        <div class="inline-block w-full mb-6 px-2">
          <div class="form-group text-left">
            <label
              class="text-light-black dark:text-white font-semibold text-xs"
            >
              {{ 'redeemMobileVerificationDialog.mobileNumber' | translate }}
            </label>
            <div class="us-phone-number">
              <input
                appPhoneMask
                type="tel"
                formControlName="phone"
                class="block w-full p-2 mt-2 border rounded-md text-light-black dark:text-white bg-input dark:bg-black-light border-active-link dark:border-active-link-dark"
              />

              <span
                class="us-phone-country-prefix text-black dark:text-white"
              ></span>
            </div>
            <div
              class="flex justify-between"
              *ngIf="this.sendPhoneForm.controls['phone'].touched"
            >
              <small class="form-text text-muted danger">
                <span
                  *ngIf="this.sendPhoneForm.controls['phone'].errors?.server"
                  class="server-error"
                >
                  {{ this.sendPhoneForm.controls['phone'].errors?.server }}
                </span>
                <span
                  *ngIf="
                    this.sendPhoneForm.controls['phone'].errors?.invalidPhone
                  "
                  class="invalid-input"
                >
                  *{{ 'auth.pleaseEnterAValidUSMobileNumber' | translate }}
                </span>
              </small>
            </div>
          </div>
        </div>

        <div
          class="text-center font-weight-bold modal-sub-title text-box-dark dark:text-box-light mt-5 text-tiny"
        >
          {{ 'redeemMobileVerificationDialog.numberNotShared' | translate }}
        </div>

        <div class="flex justify-center items-baseline mt-4 max-w-59 m-auto">
          <button
            type="button"
            data-track="member-redeem-redeem-btn"
            class="bg-blue-dark relative text-white w-44 h-12 text-base font-bold rounded-full"
            (click)="submitPhoneNumber()"
          >
            {{ 'redeemMobileVerificationDialog.getCode' | translate }}

            <div class="absolute left-5 button-spinner top-6">
              <suzy-spinner
                *ngIf="phoneNumberSubmitInProgress"
                size="small"
                button="true"
                [round]="6"
                [color]="'#ffffff'"
                [overlay]="false"
              >
              </suzy-spinner>
            </div>
          </button>
        </div>
        <div
          class="font-semibold text-box-dark dark:text-box-light text-xs mt-6"
        >
          {{ 'rewards.needHelpContact' | translate }}
          <a
            href="https://support.crowdtap.com/hc/en-us"
            target="_blank"
            class="text-blue-dark"
            >{{ 'rewards.customerSupport' | translate }}</a
          >
        </div>
      </form>
    </div>
  </ng-template>
</header>
