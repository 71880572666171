<!-- Action Modal Header Starts -->
<div
  [ngClass]="{
    'bg-body-light dark:bg-body-dark justify-between p-6 sm:px-20 md:px-24 md:pt-8 md:pb-4':
      !isSpecialMission,
    'pt-2 px-2 ': isSpecialMission,
    'legal-header': isLegalAction
  }"
  class="flex justify-end dark:text-white"
>
  <div class="flex-1" *ngIf="!isSpecialMission">
    <img
      *ngIf="
        mission?.mission_kind === missionKind.external_link &&
        mission.mission_logo?.thumbs?.admin?.url
      "
      [src]="mission.mission_logo?.thumbs?.admin?.url"
      [ngClass]="{ 'legal-logo': isLegalAction }"
      [alt]="mission?.mission_logo_alt_text"
      class="external-logo"
    />

    <span class="flex items-center tile">
      <span
        *ngIf="
          !isPreview &&
          !isExternal &&
          mission?.specialType !== SpecialTileType.double_points_tile
        "
        class="bg-card-{{ activeColor }} inline-block h-6 mr-2 rounded-md w-6 "
      ></span>
      <div
        *ngIf="
          title &&
          !isLegalAction &&
          mission?.specialType === SpecialTileType.double_points_tile
        "
        class="double-points-card double-points-card-{{
          activeColor
        }} inline-block h-5 mr-2.5 w-5"
      ></div>
      <ng-container
        *ngIf="
          mission?.tutorial_map_kind &&
            mission.tutorial_map_kind !== TutorialMapKind.no_map;
          else missionTitle
        "
      >
        <span
          class="text-xs font-semibold main-title"
          [attr.data-track]="
            'member-' + title?.toLowerCase().split(' ').join('-') + '-header'
          "
        >
          {{ 'profileQuestion.title' | translate }}
        </span>
      </ng-container>
      <ng-template #missionTitle>
        <span
          *ngIf="
            title &&
            !isLegalAction &&
            mission?.specialType !== SpecialTileType.double_points_tile
          "
          class="text-xs font-semibold main-title"
          [innerHTML]="title | translate | markdown2html : { secure: true }"
          [attr.data-track]="
            'member-' + title?.toLowerCase().split(' ').join('-') + '-header'
          "
        ></span>
        <span
          *ngIf="
            title &&
            !isLegalAction &&
            mission?.specialType === SpecialTileType.double_points_tile
          "
          class="text-xs font-semibold main-title"
          [ngClass]="{ 'double-points-copy': isDarkMode }"
          [innerHTML]="
            ('specialTile.doublePoint' | translate) + ' ' + (title | translate)
              | markdown2html : { secure: true }
          "
          [attr.data-track]="
            'member-' + title?.toLowerCase().split(' ').join('-') + '-header'
          "
        ></span>
        <span
          *ngIf="isLegalAction"
          class="font-semibold sm:text-base md:text-lg"
          [attr.data-track]="
            'member-' + title?.toLowerCase().split(' ').join('-') + '-header'
          "
        >
          {{ 'externalLink.readAndAgree' | translate }}</span
        >
      </ng-template>
    </span>

    <p
      *ngIf="
        mission?.mission_caption?.length &&
        !mission?.first_action?.maxdiff &&
        !isLegalAction
      "
      class="mt-8 text-sm font-semibold opacity-75 action-modal-caption"
      [innerHTML]="mission.mission_caption | convertMarkdownToHtml"
    ></p>
  </div>

  <button
    class="h-8 w-8 closeBtn ring-{{ typeIndicatorColor }}"
    type="button"
    (click)="onFlagClick()"
    [attr.data-track]="
      'member-' + title?.toLowerCase().split(' ').join('-') + '-flag-mission'
    "
    *ngIf="showFlagIcon"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1318 5.12598C13.2126 5.12598 11.4871 3.87598 8.80707 3.87598C7.58437 3.87598 6.52961 4.12918 5.65391 4.46848C5.7407 4.20797 5.76926 3.93158 5.73758 3.65883C5.63543 2.76563 4.89715 2.06324 4.00004 2.00504C2.90789 1.93418 2 2.79906 2 3.87598C2 4.56988 2.37723 5.17528 2.9375 5.49949V21.376C2.9375 21.7212 3.2173 22.001 3.5625 22.001H4.1875C4.5327 22.001 4.8125 21.7212 4.8125 21.376V18.1165C6.29176 17.4416 7.59605 17.001 9.8057 17.001C11.7249 17.001 13.4504 18.251 16.1304 18.251C18.4148 18.251 20.1137 17.3675 21.1518 16.6892C21.6815 16.3432 22 15.753 22 15.1203V5.74852C22 4.40246 20.6225 3.49196 19.3866 4.0252C17.9841 4.63024 16.5172 5.12598 15.1318 5.12598ZM20.125 15.126C19.2741 15.728 17.7491 16.376 16.1304 16.376C13.7888 16.376 12.146 15.126 9.8057 15.126C8.11191 15.126 6.0409 15.4933 4.8125 16.0635V7.00098C5.66344 6.39895 7.18844 5.75098 8.80707 5.75098C11.1487 5.75098 12.7915 7.00098 15.1318 7.00098C16.8221 7.00098 18.8943 6.32262 20.125 5.75098V15.126Z"
        fill="white"
        class="text-black fill-current dark:text-white"
      />
    </svg>
  </button>

  <button
    class="h-8 w-8 closeBtn ring-{{ typeIndicatorColor }}"
    type="button"
    aria-label="Close"
    (click)="closeModal()"
    [attr.data-track]="
      'member-' + title?.toLowerCase().split(' ').join('-') + '-close'
    "
    *ngIf="showCloseIcon"
  >
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4099 12.5002L19.7099 6.21019C20.1021 5.81806 20.1021 5.18231 19.7099 4.79019C19.3178 4.39806 18.6821 4.39806 18.2899 4.79019L11.9999 11.0902L5.70994 4.79019C5.31782 4.39806 4.68206 4.39806 4.28994 4.79019C3.89782 5.18231 3.89782 5.81806 4.28994 6.21019L10.5899 12.5002L4.28994 18.7902C4.10063 18.978 3.99414 19.2335 3.99414 19.5002C3.99414 19.7668 4.10063 20.0224 4.28994 20.2102C4.47771 20.3995 4.7333 20.506 4.99994 20.506C5.26658 20.506 5.52217 20.3995 5.70994 20.2102L11.9999 13.9102L18.2899 20.2102C18.4777 20.3995 18.7333 20.506 18.9999 20.506C19.2666 20.506 19.5222 20.3995 19.7099 20.2102C19.8993 20.0224 20.0057 19.7668 20.0057 19.5002C20.0057 19.2335 19.8993 18.978 19.7099 18.7902L13.4099 12.5002Z"
        fill="white"
        class="text-black fill-current dark:text-white"
      />
    </svg>
  </button>
</div>
<!-- Action Modal Header Ends -->
