import { Component, OnInit } from '@angular/core';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { MissionService } from '@suzy/shared/tools/mission';
import { Subject } from 'rxjs';
import { AppAlertService } from '../../../core/services/app-alert/app-alert.service';
import { SpecialTileService } from '@suzy/shared/special-tile';

@Component({
  selector: 'app-accept',
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss']
})
export class AcceptComponent implements OnInit {
  action: any = {};
  mission: any = {};
  subject: Subject<any>;
  response = false;
  isSubmitting = false;
  allowSkipping = false;
  disableSkip: boolean;
  specialTileName: string;

  private trackActionName = true;

  constructor(
    private sdk: SuzySdkService,
    private missionService: MissionService,
    private alerts: AppAlertService,
    private segmentService: SegmentService,
    private launchDarklyService: LaunchDarklyService,
    private specialTileService: SpecialTileService
  ) {
    this.subject = new Subject<any>();
  }

  ngOnInit(): void {
    this.trackActionName = this.launchDarklyService.getGLB723Flag();
    if (this.mission.first_action && this.mission.first_action.prevent_skip) {
      this.disableSkip = this.mission.first_action.prevent_skip;
    }

    this.specialTileName = this.specialTileService.getSpecialTileName(
      this.mission.external_json
    );

    this.segmentService.trackActionStarted({
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName
        ? this.action.accept.question
        : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      preroll_kind: this.action.preroll_kind,
      image_answers: false,
      passive_preroll_enabled: this.action.passive_preroll_enabled,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      points_available:
        this.mission.action_count === 1
          ? this.mission.points
          : this.action.points_extra,
      tag: this.specialTileName
    });
  }

  init(): void {
    // Do NOT remove
    // ActionLoaderComponent requires this
  }

  onSubmit(): void {
    const input = {
      action_id: this.action.action_id,
      answer_id: this.action.accept.answer_id,
      response_value: this.response,
      correlation_data: this.action.correlation_data
        ? this.action.correlation_data
        : undefined
    };

    this.isSubmitting = true;

    this.segmentService.trackAction('Action Completed', {
      brand_id: this.mission.brand_id,
      action_id: this.action.action_id,
      action_name: this.trackActionName
        ? this.action.accept.question
        : undefined,
      mission_id: this.mission.mission_id,
      action_kind: this.action.action_kind,
      monadic_variant_id:
        this.action.has_monadic && this.action.monadic_variants.length === 1
          ? this.action.monadic_variants[0].monadic_variant_id
          : undefined,
      tag: this.specialTileName
    });

    this.sdk.ProtocolRespond.respond(
      this.mission.brand_id,
      this.mission.mission_id,
      input
    ).subscribe(
      data => {
        if (data.success) {
          this.missionService.onMissionAnswered(
            this.mission.mission_id,
            data.meta
          );
          this.subject.next(data);
        } else {
          let message = data.message;
          if (data.errors) {
            for (const error of Object.keys(data.errors)) {
              message += `\n - ${data.errors[error]}`;
            }
          }
          this.alerts.notify({ title: 'Error', message });
        }
      },
      error => {
        this.alerts.notify({ title: 'Error', message: error });
      },
      () => {
        this.isSubmitting = false;
      }
    );
  }

  skip(evt: any): void {
    // skipping not implemented
  }
}
