<!-- Action Modal Starts -->
<div class="flex flex-col h-full action-modal-container">
  <app-warning-info
    *ngIf="action.is_prerequisite && isPreview"
    [message]="'surveyPreview.prerequisiteWarning' | translate"
  >
  </app-warning-info>
  <div class="head-container">
    <app-action-modal-header
      [mission]="mission"
      [metaData]="metaData"
      [disableButton]="mission?.isGlobal"
      [typeIndicatorColor]="'card-' + mission?.color"
      [canFlagAction]="canFlagAction"
    >
    </app-action-modal-header>

    <!-- Action Modal Question Starts -->
    <div
      [ngClass]="{ 'border-b-2': !showSurveyProgress }"
      class="p-6 bg-body-light dark:bg-body-dark pt-0 sm:px-20 md:px-24 md:py-8 md:pt-0 dark:text-white border-card-{{
        mission?.color
      }}"
    >
      <span
        class="block mb-4 text-base font-medium md:text-lg md:font-semibold lg:text-xl lg:font-medium"
        [innerHtml]="question | markdown2html : { secure: true }"
        data-track="member-turf-question-text"
      ></span>

      <div>
        <p class="mb-4 text-xs font-semibold" *ngIf="chooseAtLeastXChoices()">
          {{ 'turf.chooseAtLeastX' | translate : { min: turf.minChoices } }}
        </p>

        <p class="mb-4 text-xs font-semibold" *ngIf="chooseExactlyXChoices()">
          {{ 'turf.chooseExactlyX' | translate : { max: turf.maxChoices } }}
        </p>

        <p class="mb-4 text-xs font-semibold" *ngIf="chooseUpToXChoices()">
          <ng-container
            *ngIf="turf.maxChoices === turf.options.length; else notEqual"
          >
            {{ 'turf.selectAllThatApply' | translate }}
          </ng-container>
          <ng-template #notEqual>
            {{ 'turf.selectUpTo' | translate : { max: turf.maxChoices } }}
          </ng-template>
        </p>

        <p
          class="mb-4 text-xs font-semibold"
          *ngIf="chooseBetweenXandYChoices()"
        >
          {{
            'turf.chooseBetweenX'
              | translate
                : {
                    min: turf.minChoices,
                    max: turf.maxChoices
                  }
          }}
        </p>

        <ng-container *ngIf="action.preroll_inline_enabled">
          <div>
            <section style="display: flex; justify-content: right">
              <suzy-preroll-secondary-trigger
                *ngIf="hasPreroll"
                [prerollKind]="preRollKind"
                [prerollUrl]="preRollUrl"
                [isInline]="true"
                (imagePrerollClick)="onViewPrerollImage()"
                (linkPrerollClick)="onViewPrerollLink()"
              >
              </suzy-preroll-secondary-trigger>
            </section>
            <section style="height: 35vh">
              <img
                style="height: inherit; margin: 0 auto"
                [src]="action.preroll.thumbs.full.url"
                *ngIf="preroll_kind === preRollKindImage"
                alt="Preview image"
                onContextMenu="return false;"
              />
            </section>
          </div>
        </ng-container>
      </div>

      <suzy-preroll-secondary-trigger
        *ngIf="hasPreroll && prerollViewed && !action.preroll_inline_enabled"
        [prerollKind]="preRollKind"
        [prerollUrl]="preRollUrl"
        (imagePrerollClick)="onViewPrerollImage()"
        (linkPrerollClick)="onViewPrerollLink()"
      >
      </suzy-preroll-secondary-trigger>
    </div>
    <div
      class="w-full h-0.5 bg-body-light dark:bg-body-dark"
      *ngIf="showSurveyProgress"
    >
      <div
        class="h-0.5 bg-card-{{ mission?.color }}"
        [ngStyle]="{ width: calculateCompletion }"
      ></div>
    </div>
    <!-- Action Modal Question Ends -->
  </div>

  <!-- Action Modal Body Starts -->
  <div
    class="flex-1 p-6 sm:px-20 md:px-24 md:py-8 dark:text-white action-body-container"
  >
    <suzy-preroll
      (prerollViewedEvent)="onPrerollViewedEvent()"
      *ngIf="
        hasPreroll &&
        !prerollViewed &&
        showPreroll &&
        !action.preroll_inline_enabled
      "
      [preRollCaption]="preRollCaption"
      [preRollKind]="preRollKind"
      [preRollUrl]="preRollUrl"
      [mission]="mission"
      [action]="action"
    >
    </suzy-preroll>
    <suzy-action-forms-turf
      [isLoading]="isLoading"
      [isSuccess]="isSuccess"
      [action]="action"
      [turf]="turf"
      [isTrapQuestion]="isTrapQuestion"
      [disableSkip]="disableSkip"
      [errorMessage]="errorMessage"
      [isSubmitting]="isSubmitting"
      [isBannedOrDisabled]="isBannedOrDisabled"
      [selectedRadio]="selectedRadio"
      (checkboxChange)="onCheckBoxChange($event)"
      (skip)="onSkip($event)"
      (mouseEvent)="onMouseMoveEvent($event)"
      (turfFormSubmit)="onSubmit($event)"
      (turfFormSubmitPrerequisite)="onSubmitPrerequisite($event)"
      (viewOptionImageEvent)="viewOptionImage($event)"
      *ngIf="
        !hasPreroll ||
        (hasPreroll && prerollViewed) ||
        action.preroll_inline_enabled
      "
      [disableButton]="mission?.isGlobal === 'true' ? false : true"
      [isSkipping]="isSkipping"
    >
    </suzy-action-forms-turf>
  </div>
</div>
<!-- Action Modal Ends -->

<!-- Image Modal Begins -->
<div class="image-modal-container" *ngIf="imageModalIsActive">
  <button
    type="button"
    class="close"
    tabindex="-1"
    (click)="imageModalIsActive = false"
  >
    <span class="sr-only" aria-hidden="true">&times;</span>
  </button>
  <div class="image-wrapper">
    <span class="left"
      ><img
        src="/assets/img/svg/icons/ic-chevron-white-right.svg"
        alt="Previous Image Arrow"
        (click)="previousImage(currentChoiceIndex)"
    /></span>
    <img
      class="option-image"
      [src]="activeImageUrl"
      [alt]="activeImageAltText"
    />
    <span class="right"
      ><img
        src="/assets/img/svg/icons/ic-chevron-white-right.svg"
        alt="Next Image Arrow"
        (click)="nextImage(currentChoiceIndex)"
    /></span>
  </div>
  <div class="answer-choice-text-wrapper">
    <p>{{ activeAnswerChoiceText }}</p>
  </div>
  <div class="slider-navigation-wrapper">
    <ng-container *ngFor="let options of mcOptionsExcludingNone">
      <span
        (click)="viewOptionImage({ option: options })"
        [ngClass]="{ active: options.answer_text === activeAnswerChoiceText }"
      ></span>
    </ng-container>
  </div>
</div>
