import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize, map, startWith, takeUntil } from 'rxjs/operators';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import {
  AuthenticationService,
  UserVerificationStatus
} from '@suzy/crowdtap/data-access/user';
import moment from 'moment/moment';
import { Subject, timer } from 'rxjs';

const EMAIL_EXPIRATION_TIME = 600;

@Component({
  selector: 'link-expired',
  templateUrl: './link-expired.component.html',
  styleUrls: ['./link-expired.component.scss']
})
export class LinkExpiredComponent implements OnInit, OnDestroy {
  isMobile$ = this.route.data.pipe(
    startWith({ mobile: false }),
    map(data => data.mobile)
  );
  endUser!: any;
  isError = false;
  errorMsg = '';
  hideResend: boolean;
  isLoading = true;
  timeLeft: number;
  timer$: any;
  unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private sdk: SuzySdkService,
    private router: Router,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    this.auth
      .ensureUser()
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(endUser => {
        this.isLoading = false;
        this.endUser = endUser;
        this.checkIfUserHasEmailSent(endUser);
      });
  }

  checkIfUserHasEmailSent(endUser: any) {
    const userVerificationStatus = this.auth.getUserVerificationStatus(endUser);
    if (userVerificationStatus === UserVerificationStatus.EMAIL_CONFIRMED) {
      const timeElapsed = this.calculateTimeElapsed(endUser);
      if (timeElapsed > 0 && timeElapsed < EMAIL_EXPIRATION_TIME) {
        this.timeLeft = EMAIL_EXPIRATION_TIME - timeElapsed;
        this.hideResend = true;
        this.startCountdown();
      } else {
        this.hideResend = false;
        this.timeLeft = 0;
      }
    } else if (
      userVerificationStatus === UserVerificationStatus.PHONE_NOT_VERIFIED
    ) {
      this.router.navigate(['auth', 'get-verified']);
    } else {
      this.router.navigate(['/']);
    }
  }

  calculateTimeElapsed(endUser: any): number {
    const emailConfirmationTime = moment(endUser.email_confirm_utc);
    return moment().diff(emailConfirmationTime, 'seconds');
  }

  startCountdown() {
    this.timer$ = timer(0, 1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.timer$.unsubscribe();
        }
      });
  }

  minutesLeft(): string {
    if (this.timeLeft === 0) {
      return '0 minutes ';
    }
    const minutes = Math.ceil(this.timeLeft / 60);
    return `${minutes} minute${minutes > 1 ? 's ' : ' '}`;
  }

  onResendEmail(): void {
    this.hideResend = true;
    this.isLoading = true;
    this.sdk.ProtocolRegister.verifyEmailStart({
      email: this.endUser.email
    })
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((res: any) => {
        if (res.success) {
          this.timeLeft = EMAIL_EXPIRATION_TIME;
          this.startCountdown();
        } else {
          this.isError = true;
          this.errorMsg = res.message;
          this.hideResend = false;
          this.timeLeft = 0;
        }
      });
  }

  ngOnDestroy() {
    this.timer$.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
