import { Injectable } from '@angular/core';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { SpecialTile } from './special-tile.modal';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SpecialTileService {
  specialTileNameCache = new Map<string, SpecialTile>();

  constructor(private sdk: SuzySdkService) {}

  findSpecialTile(specialTileId: string): Observable<SpecialTile> {
    const specialTile = this.specialTileNameCache.get(specialTileId);
    if (specialTile) {
      return of(specialTile);
    } else {
      return this.sdk.SpecialTile.getSpecialTile(specialTileId).pipe(
        map((data: { success: boolean; item: SpecialTile }) => data?.item),
        tap((specialTile: SpecialTile) => {
          this.specialTileNameCache.set(specialTileId, specialTile); // store special tile in cache
        })
      );
    }
  }

  getSpecialTileName(json: string | undefined): string | undefined {
    const specialTileId = JSON.parse(json || '{}')?.special_tile_id;
    if (specialTileId) {
      const specialTile = this.specialTileNameCache.get(specialTileId);
      return specialTile?.name;
    }
    return undefined;
  }
}
