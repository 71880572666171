export const ContactInfo = [
  {
    title:
      'Account Creation Information. This includes your name, phone number, password, and email address. It might also include your Facebook profile information, if you select to create your account using Facebook.',
    whoseData: 'Members',
    use: `To register for a Crowdtap account, we need to collect this information from you to ensure compliance with our terms of service and for security purposes. For example, we need your date of birth to ensure you are old enough to use Crowdtap.`,
    law: 'Performance of a contract'
  },
  {
    title:
      'Account Settings. You can set various preferences and personal details on pages like your account settings page. These may include, for example, your default language, time zone, and communication preferences (e.g., opting in or out of receiving marketing communications from us).',
    whoseData: 'Members',
    use: `We use this information to run your account and honor your service preferences. Depending on your company's underlying contract, we sometimes co-manage this information in conjunction with your organization.`,
    law: 'Performance of a contract; Necessary for our legitimate interests'
  },
  {
    title:
      'Basic Profile Information. This includes your date of birth, gender preference, and address (including city, state, and zip code), and other criteria you have voluntarily shared with us. You also have the option of providing a profile picture.',
    whoseData: 'Members',
    use: `We use your profile information to target relevant Actions to you and send you emails inviting you to take those surveys. For example, if the profile information you provide shows you are a 30-year-old female living in New York, we may send you an Action where a Brand Client is interested in the opinions of any person living in New York aged between 25-35 years. We also use profile information to provide you with a personalized service experience, to conduct spam moderation, as well as to make product, feature, and service recommendations to you so you can optimize the use of the services we offer. In some cases, we may require you to verify certain profile information you have provided to us, such as by presenting proof of address, in order to use certain features of the Site. Once you've completed your profile, the information is locked and can't be changed for 3 months. This is to help prevent fraud and abuse. Some information, like your age and date of birth, can't be changed per our terms of service. This is to protect the data of minors.`,
    law: 'Performance of a contract; Necessary for our legitimate interests'
  },
  {
    title: 'Brand Panel Referral Information.',
    whoseData: 'Members',
    use: `If you were referred to join Crowdtap from another company you're a customer of (for example, StateFarm), we tag you in our systems to reflect that referral. That way, StateFarm can push Actions to you, a customer of theirs, and we can prioritize those Actions for you in your tiles.`,
    law: 'Performance of a contract'
  },
  {
    title: 'Contact Information. (Your name, email, address, phone number)',
    whoseData: 'Members; Visitors',
    use: `We may send you marketing or other commercial emails or SMS/text messages from time to time. We use third party providers who process your contact information on our behalf. You may unsubscribe by following the instructions included in the email or message.`,
    law: 'Performance of a contract (to inform you of any changes to our terms and conditions); Necessary to comply with a legal obligation (to inform you of any changes to our privacy policy)'
  },
  {
    title: 'Contact Information. (your mobile information)',
    whoseData: 'Members',
    use: `When you participate in a Suzy Live interview or focus group, we may need to communicate with you via Zoom SMS from time to time to coordinate scheduling and troubleshooting. This means that your phone number is shared with / used in Zoom’s SMS functionality. Zoom Video Communications, Inc. is a U.S. company. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.`,
    law: 'Performance of a contract'
  },
  {
    title: 'Contact Information. (Your name, email, address, phone number)',
    whoseData: 'Members',
    use: `We use it when we need to contact Members about products and services (unless you unsubscribe) or to provide Members with account and transactional information and updates. (Although Members cannot unsubscribe from the latter, we try to contact you only when necessary). We use this information to communicate with Members about the products and services we offer, including to notify Members about new missions, rewards, and changes in our reward offerings. We may send you non-marketing communications from time to time (such as announcements when you become eligible for rewards, notices regarding changes in our terms of service, etc.). We may also contact you to correct account errors or to supply important information we deem relevant. We may use third party providers to process your contact information on our behalf. You are not able to unsubscribe from these announcements because they may contain very important information regarding their accounts. We will also respond back to Members if you contact us (for example, if you submit a contact form to our customer support teams).`,
    law: 'Performance of a contract; Necessary to comply with a legal obligation'
  },
  {
    title: 'Contact Information. (Your name, email, address, phone number)',
    whoseData: 'Visitors',
    use: `We will respond to Visitors if you contact or provide information to our customer support or sales teams and for our own audience development. For example, we may contact Visitors in response to requests, inquiries, comments, or suggestions, or other information you send about Crowdtap.`,
    law: 'Necessary for our legitimate interests (for running our marketing outreach services)'
  },
  {
    title:
      'Cookies. For example, data we get from first and third party cookies, page tags, pixels and similar technology placed on your device.',
    whoseData: 'Members; Visitors',
    use: `Read our Cookie Policy for full details. We, our service providers, and third-party partners, may collect information about the use of our Sites by automated means, including via cookies, web beacons, and other technologies. This information may include information about the devices you use to access our Sites (such as IP address and browser and operating system type), dates and times of visits to, and use of our Sites, information about how our Sites are used (such as pages viewed, date and time of access, information about how users navigate on or between our webpages, or the features of our mobile applications that are used and how users navigate between screens on our mobile applications), the URLs that refer visitors to our Sites, and the search terms used to reach our Sites.`,
    law: 'Necessary for our legitimate interests (to define types of Members for our Sites, to keep our Sites updated and relevant, to develop our business, and to inform our marketing strategy)'
  },
  {
    title:
      'Data Quality Information. For example, IP addresses, email domains, phone numbers, survey responses, behavioral information, etc.',
    whoseData: 'Members',
    use: `We use this information to moderate for bots, to verify your identity, to prevent fraud and abuse, and to gauge whether you are a high quality respondent. This helps us preserve the security of our Sites and helps us provide quality assurance controls for our Clients. For example, we employ IP and email domain blocking to prevent fraud and abuse. We also employ various onboarding verification techniques to ensure new users are humans. We also use patented technology that identifies behavior patterns indicative of bots and progressively serves those accounts challenge questions that only a human could answer. We utilize third-party software and services to accomplish these goals.`,
    law: 'Necessary for our legitimate interests (to prevent fraud and abuse, ensure data quality, and help improve network security)'
  },
  {
    title:
      'Device and Browser Data. For example, IP address, device type, MAC ID, browser type.',
    whoseData: 'Members; Visitors',
    use: `We use this data for service optimization and troubleshooting for your specific device/browser of preference (in other words, we want you to see the best possible view of our Sites pages on your specific device). We also infer your location from your IP address.`,
    law: 'Necessary for our legitimate interests (to study usage of our products/services, to develop them, to grow our business and to inform our marketing strategy)'
  },
  {
    title:
      'Device and Browser Data. For example, IP address, device type, MAC ID, browser type.',
    whoseData: 'Members',
    use: `We receive data from the device you use to access the Sites, such as your operating system version, device manufacturer and model, carrier (i.e., mobile provider), system locale. We may also store any information collected by the core SDK, like device name (can be personalized by device owner) and user agent string. We also collect data through the use of cookies. Read our Cookie Policy for more information.`,
    law: 'Necessary for our legitimate interests (to study usage of our products/services, to develop them, to grow our business and to inform our marketing strategy)'
  },
  {
    title:
      'Device and Browser Data. For example, IP address, device type, MAC ID, browser type.',
    whoseData: 'Visitors',
    use: `We receive data from the device you use to access the Sites, such as your IP address and your browser type. We may also infer your geographic location based on your IP address.`,
    law: 'Necessary for our legitimate interests (to study usage of our products/services, to develop them, to grow our business and to inform our marketing strategy)'
  },
  {
    title:
      'Enhanced Profile Information. This may include things like the industry in which you work, ethnicity, religion, income level, education level, country of residence, political views, home ownership status, marital status, number of children, interests (for example, what activities you participate in and how often you participate), preferences (for example, what sort of food or drinks you like), and opinions.',
    whoseData: 'Members',
    use: `We build enhanced profiles to allow our Clients to target Members with specific characteristics while initiating Actions. We do this in part by using your answers to background / demographics questions.`,
    law: 'Performance of a contract; Necessary for our legitimate interests'
  },
  {
    title: 'Inferred Data.',
    whoseData: 'Members',
    use: `We may infer information about you (e.g., your shopping preferences and habits) from all of the above categories of information which we collect about you. We may share this information with our Clients.`,
    law: 'Performance of a contract'
  },
  {
    title: 'Inferred Data.',
    whoseData: 'Visitors',
    use: `We may infer information about you (e.g., your social media preferences and habits) from all of the above categories of information which we collect about you.`,
    law: 'Necessary for our legitimate interests (to study usage of our Sites, to develop them, to grow our business, and to inform our marketing strategy)'
  },
  {
    title:
      'Information from Third Parties and integration Partners. This includes your name and email address or IP address where you have given permission to those third parties to share that information with us or where that information is publicly available either online or through your device/browser data.',
    whoseData: 'Members',
    use: `To ensure you can sign-up to our service from a third party integration like Facebook, LinkedIn, Microsoft, Google, SSO; to personalize our Sites for you; to ensure you can use our Sites in conjunction with other services; and to facilitate sending Actions to you`,
    law: 'Performance of a contract; Necessary for our legitimate interests (provision of, and to improve, our services)'
  },
  {
    title:
      'Leaderboard Information. This may include your user name, points, profile photo, badges, and levels earned.',
    whoseData: 'Members',
    use: `This information may be shared with other Site users, including without limitation, publication in a live feed of ongoing Crowdtap activities and leaderboards. You may also see such information related to other Members when logged into the Crowdtap platform.`,
    law: 'Necessary for our legitimate interests (to improve our services, to facilitate user experience)'
  },
  {
    title: 'Location Data and Sensor Data.',
    whoseData: 'Visitors',
    use: `If you have enabled location tracking on your mobile device, we may collect certain location and sensor data (geographic data like latitude and longitude and other sensor data such as speed, bearing, list of installed apps, accelerometer, and vertical accuracy) from your device. We would use that data to determine information about your device and to provide us information mapping your device to specific venues and locations, dates, times, and durations. We would use that information to send you location-specific Actions, such as in-store product sampling or to receive coupons from us to use in stores.`,
    law: 'Performance of a contract; Necessary for our legitimate interests (to deliver relevant Actions)'
  },
  {
    title:
      'Log Data or Log Files that record data each time a device accesses a server. This contains data about the nature of access, for example, originating IP addresses, Internet service providers, the files viewed on our site (like HTML pages, graphics, etc.), operating system versions, device type, and timestamps.',
    whoseData: 'Members; Visitors',
    use: `Log data can be used for a lot of different things but predominantly we use it for: monitoring abuse and troubleshooting site and security issues, improving the product functionality and creating new features, tracking behavior for content and services at an aggregate level (for example, to monitor service requests or service denial on our site overtime to ensure our site remains stable) and fixing bugs or functionality issues. We will also use log data to help us make recommendations to you or track your visits to our sites.`,
    law: 'Necessary for our legitimate interests (to prevent fraud and abuse and ensure network security)'
  },
  {
    title: 'Matched Data.',
    whoseData: 'Members',
    use: `We utilize matching services (i.e., third parties who are specialized in data management, such as consumer behavior insights) to acquire additional information about you from public and private data sources (such as social networks, retailers, and content subscription services with whom you have an account) or to use your Personal Data as an aid to develop additional or new types of de-identified data sets (i.e., we compile your aggregate data with data from other consumers to create a new lifestyle segment). The matching service provider holds the Personal Data we share for a short time, uses it to assemble the additional information, and then return the combined information to us. Partners are contractually bound to delete the data we share with them and are not authorized to use it in any way other than for this specific purpose. From time to time, we may use third-party software for email list management and email distribution or may use third-party list management providers to match our Members to members of other lists or panels or to validate the accuracy of Personal Data you provide. These companies are authorized to use your personal data only as necessary to provide these services to us, pursuant to written instructions.`,
    law: 'Performance of a contract'
  },
  {
    title: 'Member Profile Data.',
    whoseData: 'Members',
    use: `We use things like your Basic Profile Information, Enhanced Profile Information, Standard Attributes, Responses to Actions, Matched Data, and Inferred Data (defined later in this chart) to build a profile on you. We use that profile to target relevant Actions to you. We also use this information to provide Clients with a way to reach the target audiences they want. For example, you might provide us with your marital status, gender, state, and household income level. If a Client wanted to target married women from the Midwest region with a household income level between $100,000-$150,000, we or our Clients would use the information you provided to initiate Actions toward that target audience.`,
    law: 'Performance of a contract; Necessary for our legitimate interests'
  },
  {
    title: 'Metadata.',
    whoseData: 'Members; Visitors',
    use: `We receive data from the device you use to access surveys, such as your operating system version, device manufacturer and model, carrier (i.e., mobile provider), system locale. We may also store any information collected by the core SDK, like device name (can be personalized by device owner) and user agent string.`,
    law: 'Necessary for our legitimate interests'
  },
  {
    title:
      'Referral Data. This is information about the place where you were before you came to our Sites – for example, if you were on social media before clicking on a link to a Site, we record information about the source that referred you to us.',
    whoseData: 'Members; Visitors',
    use: `We use this data to track the success of our integrations and referral processes and to plan further referrals.`,
    law: 'Necessary for our legitimate interests (to study how customers use our Sites, to develop them, to grow our business, and to inform our marketing strategy)'
  },
  {
    title:
      'Response Data. This is responses to Actions, which may include things like your occupation field, interests, opinions, or other information you voluntarily provide to us when you participate in an Action.',
    whoseData: 'Members',
    use: `We collect and receive the responses you provide to Actions, which may directly identify you or which, when used with other information which we hold about you, may indirectly identify you. We use the information for our brand research and insights. We also share your responses to Actions with the Client(s) who purchased license(s) with us and who initiated the Action(s). The Client(s) use your responses to Actions for their brand research and insights, marketing, and promotions.`,
    law: 'Performance of a contract; Necessary for our legitimate interests'
  },
  {
    title:
      'Response Rate Information. For example, page view data, response rates, response types, and survey type',
    whoseData: 'Members',
    use: `We use and analyze this information: To improve the user interface; To maintain a consistent and reliable Member experience; and To improve our Client services by looking at what questions Clients are asking and the quality of their responses and response rates so that we can enhance our existing features and build new ones to optimize question/answer rates for Clients.`,
    law: 'Performance of a contract; Necessary for our legitimate interests (to improve our Sites, to develop them, to grow our business, and to inform our product development and marketing strategy)'
  },
  {
    title:
      'Reward Redemption Information. (Contact information, profile information)',
    whoseData: 'Members',
    use: `We use this information to fulfill orders and prize redemptions, issue reward payouts and other payments, and/or to verify your identity in connection with redeeming points or obtaining a reward. We utilize third party providers, such as Tango Card, to facilitate the provision of gift cards, digital subscriptions, charity, etc. to you. You can learn about Tango Card’s privacy practices here.`,
    law: 'Performance of a contract'
  },
  {
    title:
      'Sensitive Personal Data. See the section in this Policy titled "What is Personal Data" for details on what is considered Sensitive Personal Data.',
    whoseData: 'Members',
    use: `You may be asked to provide Sensitive Personal Data as part of your profile data or in response to Crowdtap Actions. If we intend to disclose such information to a third party or use it for a purpose other than as described in this Policy, we will obtain your specific permission to do so prior to any such use or disclosure.`,
    law: 'Performance of a contract'
  },
  {
    title:
      'Standard Attributes. (for example, gender, age, state and/or region, ethnicity, household size, employment status, income level, parenting status, relationship status, education level)',
    whoseData: 'Members',
    use: `When you respond to Actions, we automatically share this information with Clients, if you have provided the information. The asterisk items reflect information you were required to provide us as part of your Basic Profile Information. The non-asterisk items reflect Enhanced Profile Information that you have the option of providing.`,
    law: 'Provision of a contract; Necessary for our legitimate interests (to improve our Sites, to develop them, to grow our business, and to inform our product development and marketing strategy)'
  },
  {
    title: 'Tax Information. For example, your social security number.',
    whoseData: 'Members',
    use: `If you earn over a certain amount of money in gift cards, we must collect this information from you for tax recording and reporting purposes.`,
    law: 'Necessary for our legal obligations'
  },
  {
    title:
      'Usage Information. (how you use the services, what pages you click on, etc.)',
    whoseData: 'Members',
    use: `We receive usage data about you whenever you interact with the Sites through third party analytics provider(s). This may include which webpages you visit, what you click on, and which surveys you complete. Additionally, like most websites today, our web servers keep log files that record data each time a device accesses those servers. The log files may include Internet service provider (ISP), referring/exit pages, the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and/or clickstream data to analyze trends in the aggregate and administer the site.`,
    law: 'Necessary for our legitimate interests (to help us improve the user experience when answering surveys (so that questions are easier to answer), for machine learning purposes (we use the data to keep training our models and to build new ones), and to understand industry trends in and help improve the completion rates on, surveys/forms)'
  },
  {
    title:
      'Usage Information. (how you use the services, what pages you click on, etc.)',
    whoseData: 'Members; Visitors',
    use: `We collect usage information about you whenever you interact with our websites and services. This includes which webpages you visit, what you click on, when you perform those actions, what language preference you have, what you buy and so on. We use information about how you use our services to improve our services for you and all users. Examples: We collect information about the types of questions you answer via Aggregate Data, so we can examine patterns across survey respondents. We collect and use all this data for our legitimate interests like helping us improve the experience for you (so that questions are easier to answer), for training purposes, and to understand industry trends in and to help improve the completion rates on surveys/forms. We also will market to you (unless you unsubscribe or decline those cookie preferences).`,
    law: 'Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)'
  },
  {
    title: 'All of the above categories - Aggregate Data.',
    whoseData: 'Members; Visitors',
    use: `We may aggregate information you provide in a manner which does not identify any one person (“Aggregate Data”). We may collect and use data about access to and use of our Sites that we automatically collect as a form of Aggregate Data to determine how much time visitors spend on each page of our Site, how visitors navigate throughout the Site, and how we may tailor our web pages to better meet the needs of visitors. We may use your Aggregate Data for our own legitimate business purposes, including operating and enhancing our Site, for performing statistical analysis business planning, and for market research purposes.`,
    law: 'Performance of a contract; Necessary for our legitimate interests'
  },
  {
    title:
      'All of the above categories - Artificial Intelligence / Machine Learning.',
    whoseData: 'Members; Visitors',
    use: `We use techniques like machine learning or artificial intelligence on Personal Data for ensuring compliance with legal and regulatory requirements, detecting quality to maintain reliable survey respondents, and to provide automated market research services to Clients like heatmapping, dynamic segmentation, data explorer, AI summaries, and the like. Clients have some controls over how we use Response Data and may have turned off our ability to apply machine learning to responses where it is linked to a specific product feature. We also use automated processes and machine learning to: analyze Response Data, which in turn helps us to identify trends, build product features that optimize responses, make product recommendations, and provide guidance on which products and services work best in different scenarios; extract and analyze usage patterns, which in turn helps us to improve our services and ease of use (for example, we might identify when respondents prefer multiple choice versus open text questions and make predictive response suggestions when certain question types are selected); undertake personalization for Clients and you (for example by customizing the page on our website which you see at the end of a survey); improve user experience (for example, by collecting and using device and browser information to improve how our service operates on those devices and in those browsers); identify insightful data trends (via Aggregate Data); to build features, improve our services, for fraud detection, and to develop Aggregate Data products`,
    law: ''
  },
  {
    title: 'All of the above categories - Legal and Security.',
    whoseData: 'Members; Visitors',
    use: `For legal and security purposes such as enforcing our agreements, preventing unlawful or abusive activity, responding to legal inquiries and lawful requests, and preventing fraud. For example, we sometimes inspect and use techniques like machine learning on responses to Crowdtap Actions to ensure compliance with our Terms of Service.`,
    law: 'Necessary to comply with a legal obligation; Necessary for our legitimate interests'
  },
  {
    title: 'All of the above categories - Marketing.',
    whoseData: 'Members; Visitors',
    use: `To help improve our marketing by, for example, building user profiles to ensure our marketing materials are relevant to you and optimize our campaigns using machine learning.`,
    law: 'Necessary for our legitimate interests'
  },
  {
    title: 'All of the above categories - Matching.',
    whoseData: 'Members',
    use: `Data we collect may be matched with data from other companies that recognize their own users or that collect data about consumers generally, such as social media companies, ecommerce companies, or companies that specialize in consumer behavior insights. These other companies provide us with aggregated and/or de-identified audience statistics (such as age and gender make-up of an audience). Data from these other sources is also used to enhance or calibrate the data collected from our software tools, and to enable more robust research and analyses.`,
    law: 'Performance of a contract; Necessary for our legitimate interests'
  },
  {
    title: 'All of the above categories - Other.',
    whoseData: 'Members; Visitors',
    use: `We internally use the data we receive to improve the quality of our Sites. For example, we use and analyze usage data (such as page view data and response rates) to improve the Crowdtap user interface, to maintain a consistent and reliable user experience, to improve the quality of our Crowdtap panel, and to evaluate and improve the products and services we offer our Clients (including to research and develop new products and services).`,
    law: 'Necessary for our legitimate interests'
  }
];
