import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SmsPromptModalComponent } from '../components/sms-promt-modal/sms-prompt-modal.component';
import { AuthenticationService } from '@suzy/crowdtap/data-access/user';
import { SMS_PROMPT_MODAL_SEEN_DETAILS } from '../components/sms-promt-modal/sms-prompt-modal-keys';
import * as moment from 'moment/moment';
import { catchError, map, tap } from 'rxjs/operators';
import { LaunchDarklyService } from '@suzy/shared/data-access/feature-flag';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SignupService } from '../../views/anonymous/signup/signup.service';

@Injectable({
  providedIn: 'root'
})
export class SmsPromptService {
  isOptIn = false;
  currentUserId: string;
  smsOptInLegalId = environment.legalId;

  constructor(
    private ngbModal: NgbModal,
    private auth: AuthenticationService,
    private launchDarkly: LaunchDarklyService,
    private signupService: SignupService
  ) {
    this.auth.ensureUser(true).subscribe((response: any) => {
      this.currentUserId = response.user_id;
    });
  }

  saveModalSeenDetails(): void {
    const modalData = {
      seen_time: moment().valueOf(),
      user_id: this.currentUserId
    };
    localStorage.setItem(
      SMS_PROMPT_MODAL_SEEN_DETAILS,
      JSON.stringify(modalData)
    );
  }

  openModal(): NgbModalRef {
    this.saveModalSeenDetails();
    return this.ngbModal.open(SmsPromptModalComponent, {
      centered: true,
      windowClass: 'streak-modal'
    });
  }

  isGetNotifiedModalSeen(): Observable<boolean> {
    return this.signupService.checkAvailableOpt().pipe(
      tap((response: any) => {
        this.isOptIn = !response.items.length;
      }),
      map((response: any) => {
        const dayInMilliseconds = 24 * 60 * 60 * 1000;
        const isFlagOn = this.launchDarkly.getRW80And104ShowSmsPromptsToUsers();

        if (!isFlagOn || (isFlagOn && this.isOptIn)) {
          return true; // Modal should NOT be shown
        }

        const modalSeenDetails = localStorage.getItem(
          SMS_PROMPT_MODAL_SEEN_DETAILS
        );
        if (modalSeenDetails) {
          const { user_id, seen_time } = JSON.parse(modalSeenDetails);
          return !(
            this.currentUserId !== user_id ||
            (this.currentUserId === user_id &&
              moment().valueOf() - seen_time > dayInMilliseconds)
          );
        }

        return false; // Modal should be shown
      }),
      catchError(error => {
        console.error('Error fetching opt-in status:', error);
        return of(false); // Default to showing the modal in case of an error
      })
    );
  }

  optIn(): Observable<boolean> {
    return this.signupService.verifyChecked(this.smsOptInLegalId);
  }
}
