import { Component, Input, OnInit } from '@angular/core';
import {
  AuthenticationService,
  VerificationService
} from '@suzy/crowdtap/data-access/user';
import { SuzySdkService } from '@suzy/shared/data-access/suzy-sdk';
import { TrackRewardSort } from '@asksuzy/typescript-sdk';
import { Subject } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SegmentService } from '@suzy/shared/data-access/tracking';
import { GoalService } from 'apps/crowdtap/src/app/core/services/goal.service';
import { Router } from '@angular/router';
import { SmsPromptService } from '../../../../../shared/services/sms-prompt.service';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class GeneralRewardsComponent implements OnInit {
  @Input() isSignup = false;

  trackRewards: Array<any>;
  currentUser: any = {};
  userVerificationStatus: number;
  isOnboardUser: boolean;
  rewards: Array<any>;
  delaySearch: Subject<string> = new Subject<string>();
  emitSearch: any;
  rewardSort: any;
  rewardKeyword: string;
  search: string;
  cardNotSelected = false;
  isLoading = true;
  isNext = false;
  trackRewardSort = TrackRewardSort;
  unsubscribe$ = new Subject<void>();
  selectedRewardValue: any;

  constructor(
    private auth: AuthenticationService,
    private verificationService: VerificationService,
    private sdk: SuzySdkService,
    private activeModal: NgbActiveModal,
    private segmentService: SegmentService,
    private goalService: GoalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.auth.ensureUser(true).subscribe(user => {
      this.currentUser = user;
      this.segmentService.trackRewardGoalNamesViewed(
        this.currentUser.total_points
      );
      this.setCards();
      this.checkVerificationStatus();
    });

    this.rewards = [];
    this.emitSearch = this.delaySearch
      .asObservable()
      .pipe(debounceTime(350), distinctUntilChanged());

    this.emitSearch.pipe(takeUntil(this.unsubscribe$)).subscribe(term => {
      this.rewardKeyword = term;
      this.setCards(true);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkVerificationStatus(): void {
    this.isOnboardUser = this.verificationService.isOnboardUser(
      this.currentUser
    );
    this.userVerificationStatus = this.verificationService.getUserVerification(
      this.isOnboardUser,
      this.currentUser
    );
  }

  setCards(reset = false): void {
    if (reset) {
      this.rewards = [];
    }
    this.isLoading = true;
    this.sdk.ProtocolRedeem.getCompositeRewardsAsync(
      undefined,
      this.rewardSort,
      this.rewardKeyword,
      this.isSignup ? 1000 : this.currentUser.total_points,
      this.isSignup ? 'preventGreaterThan' : 'preventLessThanOrEqualTo'
    ).subscribe(data => {
      if (data.success) {
        data.items.forEach(item => {
          if (item.rewards.length) {
            if (this.isSignup) {
              // getCompositeRewards doesn't support equalTo, so filter here instead
              item.rewards.forEach(reward => {
                const trackRewardsFiltered = reward.track_rewards.find(
                  track_reward => {
                    return track_reward.points_required === 1000;
                  }
                );
                if (trackRewardsFiltered) {
                  reward.track_rewards = [trackRewardsFiltered];
                  reward.max_points = 1000;
                  reward.min_points = 1000;
                } else {
                  reward.track_rewards = [];
                }
              });
              item.rewards = item.rewards.filter(reward => {
                return reward.track_rewards.length > 0;
              });

              if (item.rewards.length) {
                this.rewards.push(...item.rewards);
              }
            } else {
              this.rewards.push(...item.rewards);
            }
          }
        });
        this.isLoading = false;
      }
    });
  }

  searchFilter(value): void {
    this.cardNotSelected = false;
    const val = value?.toLowerCase();
    this.delaySearch.next(val);
  }

  next(): void {
    if (this.isSignup) {
      // forward to SMS verification
    } else {
      this.isNext = true;
      setTimeout(() => {
        this.isNext = false;
        this.cardNotSelected = true;
      }, 10);
    }
  }

  closeRewardModal(): void {
    if (this.isSignup) {
      this.navigateToSms();
    } else {
      this.activeModal.close();
    }
  }

  selectCard(card: any): void {
    this.cardNotSelected = false;
    this.selectedRewardValue = card.track_rewards[0];
  }

  saveGoal(): void {
    if (this.selectedRewardValue) {
      this.isLoading = true;
      this.sdk.ProtocolRedeem.createGoal({
        track_reward_id: this.selectedRewardValue.track_reward_id
      }).subscribe(
        response => {
          this.isLoading = false;
          if (response.success) {
            this.segmentService.trackRewardGoalConfirmationViewed({
              reward_category: this.selectedRewardValue.reward_category,
              reward_goal_points_cost: response.meta?.points_required,
              reward_id: response.meta?.track_reward_id,
              reward_name: response.meta?.reward_name,
              reward_photo: response.meta?.photo.thumbs?.member?.url,
              reward_value: response.meta?.reward_value,
              total_points: this.currentUser?.total_points,
              track_reward_id: response.meta?.track_reward_id,
              user_track_reward_goal_id:
                response.item?.user_track_reward_goal_id
            });
            response.isSetGoal = true;
            this.goalService.setGoalInfo(response);
          }
        },
        error => {
          this.isLoading = false;
          this.navigateToSms();
        }
      );
    } else {
      this.cardNotSelected = true;
    }
  }

  navigateToSms(): void {
    this.router.navigate(['auth', 'get-verified']);
  }
}
