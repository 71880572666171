import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ViewChild
} from '@angular/core';
import { TutorialMapKind } from '@asksuzy/typescript-sdk';
import { DatePipe } from '@angular/common';
import { Datepicker, initFlowbite } from 'flowbite';

@Component({
  selector: 'suzy-action-forms-open-ended',
  templateUrl: './open-ended.component.html',
  styleUrls: ['./open-ended.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenEndedComponent implements OnInit, AfterViewInit {
  @Input() isErrorMessage: string;
  @Input() action;
  @Input() isSuccess: boolean;
  @Input() isLoading: boolean;
  @Input() isSpecialMission: boolean;
  @Input() isValid;
  @Input() disableSkip;
  @Input() disableButton: Boolean = false;
  @Input() isSkipping: boolean;
  @Input() isSubmitting: boolean;
  @Input() isBannedOrDisabled: boolean;
  @Input() tutorialMapKind: TutorialMapKind;
  @Input() states = [];

  @Output() responseKeyup = new EventEmitter();
  @Output() submitClick = new EventEmitter();
  @Output() formMouseMove = new EventEmitter();
  @Output() formSkip = new EventEmitter();
  @Output() formSubmit = new EventEmitter();
  @ViewChild('birthdayDatepicker') birthdayDatepicker: any;
  readonly TutorialMapKind = TutorialMapKind;
  datepickerInstance!: any;

  response = '';
  zipCodeMaxLength = 5;
  selectedDate = '';

  constructor() {}

  ngOnInit(): void {
    this.response = this.isSpecialMission ? 'special mission' : '';
    initFlowbite();
  }

  ngAfterViewInit() {
    const datepickerEl = this.birthdayDatepicker?.nativeElement;
    if (datepickerEl) {
      this.datepickerInstance = new Datepicker(datepickerEl, {
        autohide: true,
        format: 'mm/dd/yyyy'
      });
    }

    const calendarPopup = document.querySelector('.datepicker') as HTMLElement;
    if (calendarPopup) {
      calendarPopup.classList.add('dark:bg-black'); // Add your custom class
      calendarPopup.style.zIndex = '10000';
    }

    datepickerEl.addEventListener('changeDate', () => {
      this.response = new DatePipe('en-US').transform(
        this.datepickerInstance.getDate(),
        'MM/dd/yyyy'
      );
    });

    datepickerEl.addEventListener('input', () => {
      this.datepickerInstance.hide();
    });
  }

  zipCodeValidator(event: any) {
    if (!/^(?:\d|-|Backspace|Delete|ArrowLeft|ArrowRight)$/.test(event.key)) {
      event.preventDefault();
    } else {
      this.responseKeyup.emit();
    }
  }

  onResponseKeyup(): void {
    this.responseKeyup.emit();
  }

  onMouseMove(event): void {
    this.formMouseMove.emit(event);
  }

  onSubmitClick(): void {
    this.submitClick.emit(this.response);
  }

  onFormSkip(event): void {
    this.formSkip.emit(event);
  }

  onFormSubmit(event: any): void {
    this.formSubmit.emit(event);
  }

  preventInvalidKeys(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
      'Delete'
    ];
    const isNumber = /[0-9]/.test(event.key);
    const isSlash = event.key === '/';

    if (!isNumber && !isSlash && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }

    // Auto-add slashes at appropriate positions
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (
      (value.length === 2 || value.length === 5) &&
      isNumber &&
      !event.ctrlKey
    ) {
      input.value = value + '/';
    }
  }

  // Restrict input to valid date format on change
  restrictToDate(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Regex to enforce MM/DD/YYYY format
    const datePattern = /^(\d{0,2})(\/\d{0,2})?(\/\d{0,4})?$/;
    if (!datePattern.test(value)) {
      // Revert to previous valid value
      input.value = this.selectedDate;
    } else {
      this.selectedDate = value;

      // Validate date when complete (e.g., 03/26/2025)
      if (value.length === 10) {
        const [month, day, year] = value.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        if (
          date.getFullYear() !== year ||
          date.getMonth() + 1 !== month ||
          date.getDate() !== day
        ) {
          input.value = ''; // Invalid date, clear it
          this.selectedDate = '';
        }
      }
    }
  }
}
